import React, { useEffect, useMemo, useState } from 'react';
import SuccessToast from '../Components/SuccessToast';
import { SupplierModel } from '../Models/SupplierModel';
import SuppliersService from '../Services/SuppliersService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const Suppliers = () => {

    const { instance } = useMsal();

    const supplierService = useMemo(() => new SuppliersService(instance), [instance]);

    const [suppliers, setSuppliers] = useState<SupplierModel[]>([]);
    const [formTouchedOrDirty, setFormTouchedOrDirty] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    useEffect(() => {
        async function loadData() {
            try {
                const suppliers = await supplierService.getAll();

                const emptyRecord: SupplierModel = { code: null, added: true };
                suppliers.push(emptyRecord);

                setSuppliers(suppliers);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [supplierService]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        let suppliersL = [...suppliers];
        suppliersL.forEach((supplier) => {
            let target = event.target;
            let code = target.getAttribute("data-code");
            if (supplier.code === code || (!code && supplier.added)) {
                switch (target.name) {
                    case "code":
                        supplier.code = target.value;
                        break;
                    case "name":
                        supplier.name = target.value;
                        break;
                    case "groupName":
                        supplier.groupName = target.value;
                        break;
                    case "disabled":
                        supplier.disabled = target.checked;
                        break;
                    case "email":
                        supplier.email = target.value;
                        break;
                    case "address":
                        supplier.address = target.value;
                        break;
                    case "vatNr":
                        supplier.vatNr = target.value;
                        break;
                    case "parentSupplierCode":
                        supplier.parentSupplierCode = target.value;
                        break;
                }
            }
        })
        setSuppliers(suppliersL);
        setFormTouchedOrDirty(true);
    }

    function handleChangeParentSupplierCodeSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        let suppliersL = [...suppliers];
        suppliersL.forEach((supplier) => {
            let target = event.target;
            let code = target.getAttribute("data-code");
            if (supplier.code === code || (!code && supplier.added)) {
                supplier.parentSupplierCode = event.target.value;
            }
        })
        setSuppliers(suppliersL);
        setFormTouchedOrDirty(true);
    }

    function validateCode(event: any, record: SupplierModel) {

        if (!record.added === true)
            return;

        let code = event.target.value;
        if (suppliers.some(s => s.code === code && s.added !== true)) {
            toast.error('Deze code is al in gebruik.');
            event.preventDefault();
            let target = event.currentTarget;
            setTimeout(
                function () {
                    target.focus();
                },
                5
            );
        }
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            if (formTouchedOrDirty) {

                let addedCode = suppliers.find(s => s.added === true)?.code;
                if (addedCode && addedCode !== null && addedCode?.length > 0 && suppliers.some(s => s.code === addedCode && s.added !== true)) {
                    toast.error('Deze code is al in gebruik.');
                    return;
                }

                let newSuppliers = await supplierService.upsert(suppliers);

                const emptyRecord: SupplierModel = { code: null, added: true };
                newSuppliers.push(emptyRecord);

                setSuppliers(newSuppliers);
                setShowSuccessMessage(true);
            }
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                    <button className="btn btn-primary" type="submit">Bewaar wijzigingen</button>
                </div>
                <table className="table table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th className="text-center">Code</th>
                            <th className="text-center">Naam</th>
                            <th className="text-center">Groep</th>
                            <th className="text-center">Email</th>
                            <th className="text-center">Adres</th>
                            <th className="text-center">BTW nr</th>
                            <th className="text-center">Onderaannemer van</th>
                            <th className="text-center">Niet tonen in planning</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            suppliers.map((record: SupplierModel) => {
                                return (
                                    <tr key={record.added === true ? "" : record.code} data-code={record.added === true ? "" : record.code}>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" placeholder='Code' className="form-control form-control-sm"
                                                data-code={record.added === true ? "" : record.code} name="code" value={record.code ?? ""}
                                                readOnly={record.added !== true}
                                                onBlur={(e) => validateCode(e, record)}
                                            ></input>
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-code={record.added === true ? "" : record.code}
                                                placeholder='Naam' name="name"
                                                value={record.name ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-code={record.added === true ? "" : record.code}
                                                placeholder='Groep' name="groupName"
                                                value={record.groupName ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-code={record.added === true ? "" : record.code}
                                                placeholder='Email' name="email"
                                                value={record.email ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-code={record.added === true ? "" : record.code}
                                                placeholder='Adres' name="address"
                                                value={record.address ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-code={record.added === true ? "" : record.code}
                                                placeholder='BTW nr' name="vatNr"
                                                value={record.vatNr ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <select className="form-control" id="parentSupplierCodeSelect"
                                                defaultValue={record.parentSupplierCode ?? ""}
                                                onChange={handleChangeParentSupplierCodeSelect}>
                                                <option value="">- Geen -</option>
                                                {
                                                    suppliers.filter(r => (r.code ?? "").length > 0).map((supplierRec) => {
                                                        return (
                                                            <option value={supplierRec.code ?? ""}>{supplierRec.name}</option>
                                                        );
                                                    })
                                                }
                                            </select>

                                            {/* <input onChange={handleChange} type="text" data-code={record.added === true ? "" : record.code}
                                                placeholder='Onderaannemer van' name="parentSupplierCode"
                                                value={record.parentSupplierCode ?? ""} className="form-control form-control-sm" /> */}
                                        </td>
                                        <td className="text-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={handleChange}
                                                    data-code={record.added === true ? "" : record.code}
                                                    name="disabled" placeholder='Niet actief' checked={record.disabled} />
                                                <label className="form-check-label" ></label>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <div className="row m-4">
                    &nbsp;
                </div>
            </form>
            <SuccessToast show={showSuccessMessage} toggle={() => setShowSuccessMessage(!showSuccessMessage)} />
        </div>
    )

}
export default Suppliers;