import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import GraphService from '../Services/GraphService';
import ProjectsService from '../Services/ProjectsService';
import PeopleService from '../Services/PeopleService';
import Checkbox from "../Components/Checkbox";
import ProjectCheckedModal from "../Components/ProjectCheckedModal";
import ProjectPreparedModal from "../Components/ProjectPreparedModal";
import DeletePlanningModal from "../Components/DeletePlanningModal";
import RemindersModal from "../Components/RemindersModal";
import ProjectModel from '../Models/ProjectModel';
import PersonModel from '../Models/PersonModel';
import ProjectPlanningModal from '../Components/ProjectPlanningModal';
import ProjectCustomerNotifiedModal from '../Components/ProjectCustomerNotifiedModal';
import ProjectCiawRequestedModal from '../Components/ProjectCiawRequestedModal';
import ConfirmationModal from '../Components/ConfirmationModal';
import { format, parseJSON } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { User } from '@microsoft/microsoft-graph-types';
import { toast } from 'react-toastify';
import InfoDocumentModal from '../Components/InfoDocumentModal';
import ProjectForemanModal from '../Components/ProjectForemanModal';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import DataTable, { renderDate, renderDays, renderDecimal, renderEllipsis } from '../Components/DataTable';
import SupplierService from '../Services/SuppliersService';
import { SupplierModel } from '../Models/SupplierModel';
import RequestCiawModal from '../Components/RequestCiawModal';
import ProjectSubcontractorsReportedModal from '../Components/ProjectSubcontractorsReportedModal';
import ReportSubcontractorsModal from '../Components/ReportSubcontractorsModal';

interface StatusFilterValueState {
    filterValue: string;
    checked: boolean;
}

const columnHelper = createColumnHelper<ProjectModel>();

const Projects = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);
    const peopleService = useMemo(() => new PeopleService(instance), [instance]);
    const supplierService = useMemo(() => new SupplierService(instance), [instance]);

    const showMkAdsolutProjectsL = localStorage.getItem("PTProjAdsolutProjectMK");
    const showMkAdsolutProjectsParsed = showMkAdsolutProjectsL !== null ? JSON.parse(showMkAdsolutProjectsL) : null;

    const showMpAdsolutProjectsL = localStorage.getItem("PTProjAdsolutProjectMP");
    const showMpAdsolutProjectsParsed = showMpAdsolutProjectsL !== null ? JSON.parse(showMpAdsolutProjectsL) : null;

    const checkedFilterL = localStorage.getItem("PTProjCheckedFilter");
    const checkedFilterParsed = checkedFilterL !== null ? JSON.parse(checkedFilterL) : null;

    const preparedFilterL = localStorage.getItem("PTProjPreparedFilter");
    const preparedFilterParsed = preparedFilterL !== null ? JSON.parse(preparedFilterL) : null;

    const statusFilter = localStorage.getItem("PTProjStatusFilter");
    const statusFilterParsed: StatusFilterValueState[] = statusFilter !== null ? JSON.parse(statusFilter) : null;

    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [statusFilterValues, setStatusFilterValues] = useState<StatusFilterValueState[]>(statusFilterParsed ?? []);
    const [people, setPeople] = useState<User[]>([]);
    const [foremen, setForemen] = useState<PersonModel[]>([]);
    const [owners, setOwners] = useState<PersonModel[]>([]);
    const [selectedForemanId, setSelectedForemanId] = useState<string | null>(null);
    const [totalOrderedSuppliersPercentageModalVisible, setTotalOrderedSuppliersPercentageModalVisible] = useState<boolean>(false);
    const [selectedProjectCode, setSelectedProjectCode] = useState<string | null>(null);
    const [totalOrderedSuppliersPercentageValue, setTotalOrderedSuppliersPercentageValue] = useState<number | null>(null);
    const [ownerId, setOwnerId] = useState<string | null>(null);
    const [customForemanIdOrCode, setCustomForemanIdOrCode] = useState<string | null>(null);
    const [selectedView, setSelectedView] = useState<string>(localStorage.getItem("PTProjSelectedView") ?? "planning");
    const [showMpAdsolutProjects, setShowMpAdsolutProjects] = useState<boolean>(showMpAdsolutProjectsParsed ?? false);
    const [showMkAdsolutProjects, setShowMkAdsolutProjects] = useState<boolean>(showMkAdsolutProjectsParsed ?? false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);
    const [checkedFilter, setCheckedFilter] = useState<boolean | null>(checkedFilterParsed);
    const [preparedFilter, setPreparedFilter] = useState<boolean | null>(preparedFilterParsed);
    const [hasRoleProjectController, setHasRoleProjectController] = useState<boolean>(false);
    const [showProjectCheckedModal, setShowProjectCheckedModal] = useState<boolean>(false);
    const [projectCheckedValue, setProjectCheckedValue] = useState<boolean>(false);
    const [projectCheckedCommentsValue, setProjectCheckedCommentsValue] = useState<string>("");
    const [showDeletePlanningModal, setShowDeletePlanningModal] = useState<boolean>(false);
    const [showProjectPlanningModal, setShowProjectPlanningModal] = useState<boolean>(false);
    const [showRequestCiawModal, setShowRequestCiawModal] = useState<boolean>(false);
    const [showReportSubcontractorsModal, setShowReportSubcontractorsModal] = useState<boolean>(false);
    const [showProjectOwnerModal, setShowProjectOwnerModal] = useState<boolean>(false);
    const [showProjectCustomForemanModal, setShowProjectCustomForemanModal] = useState<boolean>(false);
    const [showProjectCustomerNotifiedModal, setShowProjectCustomerNotifiedModal] = useState<boolean>(false);
    const [projectCustomerNotifiedValue, setProjectCustomerNotifiedValue] = useState<boolean>(false);
    const [projectCustomerNotifiedOnValue, setProjectCustomerNotifiedOnValue] = useState<Date | null>(null);
    const [projectCustomerNotifiedByValue, setProjectCustomerNotifiedByValue] = useState<string | null>(null);
    const [projectCustomerNotifiedMethodValue, setProjectCustomerNotifiedMethodValue] = useState<string | null>(null);
    const [projectDoNotEditPlanningValue, setProjectDoNotEditPlanningValue] = useState<boolean>(false);
    const [showProjectPreparedModal, setShowProjectPreparedModal] = useState<boolean>(false);
    const [projectPreparedValue, setProjectPreparedValue] = useState<boolean>(false);
    const [showRemindersModal, setShowRemindersModal] = useState<boolean>(false);
    const [showInfoDocumentModal, setShowInfoDocumentModal] = useState<boolean>(false);
    const [customers, setCustomers] = useState<string[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
    const [fromDateFilter, setFromDateFilter] = useState<Date | null>(null);
    const [untilDateFilter, setUntilDateFilter] = useState<Date | null>(null);
    const [reloadProjects, setReloadProjects] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showProjectCiawRequestedModal, setShowProjectCiawRequestedModal] = useState<boolean>(false);
    const [projectCiawRequestedValue, setProjectCiawRequestedValue] = useState<boolean>(false);
    const [projectCiawRequestedOnValue, setProjectCiawRequestedOnValue] = useState<Date | null>(null);
    const [projectCiawRequestedByValue, setProjectCiawRequestedByValue] = useState<string | null>(null);
    const [projectCiawRequestedMethodValue, setProjectCiawRequestedMethodValue] = useState<string | null>(null);
    const [showProjectSubcontractorsReportedModal, setShowProjectSubcontractorsReportedModal] = useState<boolean>(false);
    const [projectSubcontractorsReportedValue, setProjectSubcontractorsReportedValue] = useState<boolean>(false);
    const [projectSubcontractorsReportedOnValue, setProjectSubcontractorsReportedOnValue] = useState<Date | null>(null);
    const [projectSubcontractorsReportedByValue, setProjectSubcontractorsReportedByValue] = useState<string | null>(null);
    const [projectSubcontractorsReportedMethodValue, setProjectSubcontractorsReportedMethodValue] = useState<string | null>(null);
    const [ciawEmails, setCiawEmails] = useState<string | null>(null);
    const [selectedProject, setSelectedProject] = useState<ProjectModel>();
    const [suppliers, setSuppliers] = useState<SupplierModel[]>([]);

    useEffect(() => {
        async function loadData() {
            try {
                let people = await graphService.getAllGraphUsers();

                let foremen = await peopleService.getForemen();
                let owners = await peopleService.getOwners();
                let customers = await projectsService.getCustomers();
                let suppliers = await supplierService.getAll();

                let hasRole = await graphService.hasRole('project.controller');

                for (let foreman of foremen) {
                    let person = people.find((person) => person.id === foreman.id);
                    foreman.displayName = person?.displayName ?? "";
                }

                const statusFilterValuesL = await projectsService.getStatusFilterValues();

                const statusFilterValuesForState = statusFilterValuesL.map((value) => {
                    return {
                        filterValue: value,
                        checked: statusFilterParsed?.find(r => r.filterValue === value)?.checked ?? false
                    }
                });

                setPeople(people);
                setForemen(foremen);
                setOwners(owners);
                setHasRoleProjectController(hasRole);
                setCustomers(customers);
                setSuppliers(suppliers);
                setStatusFilterValues(statusFilterValuesForState);
            }
            catch (error) {
                console.log(error);
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [graphService, peopleService, projectsService, supplierService]);

    useEffect(() => {

        async function loadProjects() {
            try {
                let selectedFilterValues = statusFilterValues
                    .filter(statusFilterValue => statusFilterValue.checked)
                    .map(statusFilterValue => statusFilterValue.filterValue ?? "null");

                let projects = await projectsService.getProjects(selectedFilterValues, selectedForemanId,
                    showMkAdsolutProjects, showMpAdsolutProjects, checkedFilter, preparedFilter, selectedCustomer, fromDateFilter, untilDateFilter);

                for (let project of projects) {
                    if (project.foremanName === null) {
                        project.foremanName = "";
                        if (project.foremanId && project.foremanId != null) {
                            let person = people.find((person) => person.id === project.foremanId);
                            if (person)
                                project.foremanName = person?.displayName ?? "";
                        }
                    }

                    if (project.personName === null) {
                        project.personName = "";
                        if (project.personId && project.personId != null) {
                            let person = people.find((person) => person.id === project.personId);
                            if (person)
                                project.personName = person?.displayName ?? "";
                        }
                    }
                }

                setProjects(projects);
                setShowLoadingSpinner(false);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadProjects();
    }, [projectsService, checkedFilter, fromDateFilter, preparedFilter,
        selectedCustomer, selectedForemanId, showMpAdsolutProjects, showMkAdsolutProjects,
        statusFilterValues, untilDateFilter, people, reloadProjects]);


    async function handleStatusFilter(event: React.ChangeEvent<HTMLInputElement>) {
        let newstatusFilterValues = [...statusFilterValues];

        newstatusFilterValues.forEach(statusFilterValue => {
            if ((statusFilterValue.filterValue ?? "null") === event.target.value)
                statusFilterValue.checked = event.target.checked;
        });

        localStorage.setItem("PTProjStatusFilter", JSON.stringify(newstatusFilterValues));

        setStatusFilterValues(newstatusFilterValues);
        setShowLoadingSpinner(true);
    }

    async function handleFromDateFilterChange(value: Date | null) {
        setFromDateFilter(value);
        setShowLoadingSpinner(true);
    }

    async function handleUntilDateFilterChange(value: Date | null) {
        setUntilDateFilter(value);
        setShowLoadingSpinner(true);
    }

    async function handleAdsolutProjectsFilter(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "MK") {
            localStorage.setItem("PTProjAdsolutProjectMK", JSON.stringify(event.target.checked));

            setShowMkAdsolutProjects(event.target.checked);
            setShowLoadingSpinner(true);
        } else if (event.target.value === "MP") {
            localStorage.setItem("PTProjAdsolutProjectMP", JSON.stringify(event.target.checked));

            setShowMpAdsolutProjects(event.target.checked);
            setShowLoadingSpinner(true);
        }
    }

    async function handleCheckedFilter(event: React.ChangeEvent<HTMLInputElement>) {
        let checkedFilterString = event.target.value;
        let checkedFilter = checkedFilterString === "true" ? true : checkedFilterString === "false" ? false : null;

        localStorage.setItem("PTProjCheckedFilter", JSON.stringify(checkedFilter));

        setCheckedFilter(checkedFilter);
        setShowLoadingSpinner(true);
    }

    async function handlePreparedFilter(event: React.ChangeEvent<HTMLInputElement>) {
        let preparedFilterString = event.target.value;
        let preparedFilter = preparedFilterString === "true" ? true : preparedFilterString === "false" ? false : null;

        localStorage.setItem("PTProjPreparedFilter", JSON.stringify(preparedFilter));

        setPreparedFilter(preparedFilter);
        setShowLoadingSpinner(true);
    }

    async function handleForemanFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        let selectedForeman = event.target.value;

        setSelectedForemanId(selectedForeman);
        setShowLoadingSpinner(true);
    }

    async function handleCustomerFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        let selectedCustomer = event.target.value;

        setSelectedCustomer(selectedCustomer);
        setShowLoadingSpinner(true);
    }

    async function handleViewSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedView = event.target.value;

        localStorage.setItem("PTProjSelectedView", selectedView);

        setSelectedView(selectedView);
    }

    function handleTotalOrderedSuppliersPercentageChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = null;

        if (!Number.isNaN(parseFloat(event.target.value)))
            value = parseFloat(event.target.value);

        setTotalOrderedSuppliersPercentageValue(value);
    }

    function handleOwnerChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let value = event.target.value;

        setOwnerId(value);
    }

    function totalOrderedSuppliersPercentageModalToggle() {

        setTotalOrderedSuppliersPercentageModalVisible(!totalOrderedSuppliersPercentageModalVisible);
    }

    function projectOwnerModalToggle() {
        setShowProjectOwnerModal(!showProjectOwnerModal);
    }

    function projectCustomForemanModalToggle() {
        setShowProjectCustomForemanModal(!showProjectCustomForemanModal);
    }

    async function remindersModalAfterClose() {
        try {
            if (selectedProjectCode !== null) {
                setShowRemindersModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function infoDocumentModalAfterClose() {
        try {
            if (selectedProject !== undefined) {
                setShowInfoDocumentModal(false);
                setSelectedProject(undefined);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function projectCheckedModalAfterSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowProjectCheckedModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function projectCheckedModalAfterClose() {
        setShowProjectCheckedModal(false);
    }

    async function projectPreparedModalAfterSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowProjectPreparedModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function projectPreparedModalAfterClose() {
        setShowProjectPreparedModal(false);
    }

    async function projectCustomerNotifiedModalAfterSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowProjectCustomerNotifiedModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function projectCustomerNotifiedModalAfterClose() {
        setShowProjectCustomerNotifiedModal(false);
    }

    async function projectCiawRequestedModalAfterSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowProjectCiawRequestedModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function projectCiawRequestedModalAfterClose() {
        setShowProjectCiawRequestedModal(false);
    }

    async function projectSubcontractorsReportedModalAfterSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowProjectSubcontractorsReportedModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function projectSubcontractorsReportedModalAfterClose() {
        setShowProjectSubcontractorsReportedModal(false);
    }

    async function deletePlanningModalAfterSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowDeletePlanningModal(false);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function deletePlanningModalAfterClose() {
        setShowDeletePlanningModal(false);
    }

    function projectPlanningModalAfterClose() {
        setShowProjectPlanningModal(false);
    }

    async function totalOrderedSuppliersPercentageModalSave() {
        try {
            if (selectedProjectCode !== null) {
                await projectsService.updateProjectTotalOrderedSuppliersDeviantPercentage(selectedProjectCode, totalOrderedSuppliersPercentageValue);

                setTotalOrderedSuppliersPercentageModalVisible(false);
                setSelectedProjectCode(null);
                setTotalOrderedSuppliersPercentageValue(null);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function projectOwnerModalSave() {
        try {
            if (selectedProjectCode !== null) {
                await projectsService.updateProjectOwner(selectedProjectCode, ownerId);

                setShowProjectOwnerModal(false);
                setSelectedProjectCode(null);
                setOwnerId(null);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function projectCustomForemanModalSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowProjectCustomForemanModal(false);
                setSelectedProjectCode(null);
                setCustomForemanIdOrCode(null);

                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function requestCiawModalSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowRequestCiawModal(false);
                setSelectedProjectCode(null);
                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function requestCiawModalCancel() {
        try {
            setShowRequestCiawModal(false);
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function reportSubcontractorsModalSave() {
        try {
            if (selectedProjectCode !== null) {
                setShowReportSubcontractorsModal(false);
                setSelectedProjectCode(null);
                setReloadProjects(!reloadProjects);
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async function reportSubcontractorsModalCancel() {
        try {
            setShowReportSubcontractorsModal(false);
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function confirmationModalAfterClose() {
        setShowConfirmationModal(false);
    }

    async function confirmationModalAfterOk() {

        try {
            if (selectedProjectCode !== null) {
                const user = await graphService.getUserDetails();
                const success = await projectsService.sendCustomerEmail(selectedProjectCode, user.mail ?? user.userPrincipalName ?? "", user.id ?? "");

                if (!success) {
                    toast.error('Fout bij versturen van email.');
                }

                setSelectedProjectCode(null);
                setShowConfirmationModal(false);
            }
        }
        catch (err) {
            toast.error('Fout bij versturen van email.');
        }
    }

    const codeCol = columnHelper.accessor('code', {
        header: 'Projectnr',
        cell: props => {
            let color = 'text-warning';
            if (props.row.original.checkInAtWork === true) {
                color = 'text-success';
                return <div className={color} title={props.row.original.checkInAtWorkCode}>{props.getValue()}</div>;
            }
            else if (props.row.original.checkInAtWork === false)
                color = 'text-error';

            return <div className={color}>{props.getValue()}</div>;
        },
        footer: props => <>Totalen:</>,
        size: 140
    }) as ColumnDef<unknown, any>;

    const customerNameCol = columnHelper.accessor('customerName', {
        header: 'Klant',
        cell: props => props.getValue(),
        size: 250
    }) as ColumnDef<unknown, any>

    const nameCol = columnHelper.accessor('name', {
        header: 'Omschrijving',
        cell: props => props.getValue(),
    }) as ColumnDef<unknown, any>;

    const startDateCol = columnHelper.accessor('startDate', {
        header: 'Gevr. startdatum',
        cell: props => renderDate(props.getValue()),
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const actualStartDateCol = columnHelper.accessor('actualStartDate', {
        header: 'Eff. startdatum',
        cell: props => renderDate(props.getValue()),
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const checkInAtWorkCodeCol = columnHelper.accessor('checkInAtWorkCode', {
        header: 'CIAWnr',
        cell: props => props.getValue(),
    }) as ColumnDef<unknown, any>;

    const statusCol = columnHelper.accessor('status', {
        header: 'Status',
        cell: props => props.getValue(),
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalOrderedCol = columnHelper.accessor('totalOrdered', {
        header: 'Budget',
        cell: props => {
            if ((props.row.original.totalOrderedSubcontractors ?? 0) > 0
                || (props.row.original.totalOrderedSubcontractors ?? 0) < 0
                || (props.row.original.totalExtraCosts ?? 0) > 0
                || (props.row.original.totalExtraCosts ?? 0) < 0) {
                return <div className="text-right text-info" title={props.row.original.totalOrderedInfo}>{renderDecimal(props.getValue())}</div>
            }
            return <div className="text-right" title={props.row.original.totalOrderedInfo}>{renderDecimal(props.getValue())}</div>;
        },
        footer: props => {
            const total = props.table.options.data.map(r => r.totalOrdered).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0);

            return <div className='text-right'>{renderDecimal(total ?? 0)}</div>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalOrderedDaysCol = columnHelper.accessor('totalOrderedDays', {
        header: 'Dagen budget',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        footer: props => {
            const total = props.table.options.data.map(r => r.totalOrderedDays).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0);

            return <div className='text-right pr-3'>{renderDays(total ?? 0)}</div>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalDaysPlannedCol = columnHelper.accessor('totalDaysPlanned', {
        header: 'Dagen plan',
        cell: props => <div className='text-right pr-3'>
            <button type='button' className="btn btn-link p-0 btn-in-table" title="Planning"
                onClick={() => {
                    setShowProjectPlanningModal(true);
                    setSelectedProjectCode(props.row.original.code);
                }}>
                {renderDays(props.getValue())}
            </button></div>,
        footer: props => {
            const total = props.table.options.data.map(r => r.totalDaysPlanned).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0);

            return <div className='text-right pr-3'>{renderDays(total ?? 0)}</div>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalDaysRestCol = columnHelper.accessor('totalDaysRest', {
        header: 'Rest. # dagen',
        cell: props => {
            let cellData = props.getValue();
            cellData = cellData * 10;
            cellData = Math.round(cellData / 5) * 5;
            cellData = cellData / 10;

            let classes = "";
            if (cellData < 0)
                classes = "bg-danger text-white";
            else if (cellData >= 0)
                classes = "bg-success text-white";
            else
                classes = "bg-warning text-white";

            return <div className={classes + " text-right pr-3"}>{renderDays(props.getValue())}</div>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalDaysPlannedThisWeekCol = columnHelper.accessor('totalDaysPlannedThisWeek', {
        header: () => totalDaysPlannedThisWeekTitle(),
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalDaysPlannedThisMonthCol = columnHelper.accessor('totalDaysPlannedThisMonth', {
        header: () => totalDaysPlannedThisMonthTitle(),
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalDaysPlannedUntilEndOfThisWeekCol = columnHelper.accessor('totalDaysPlannedUntilEndOfThisWeek', {
        header: () => totalDaysPlannedUntilEndOfThisWeekTitle(),
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalInvoicedCol = columnHelper.accessor('totalInvoiced', {
        header: 'Tot. verkoop',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalInvoicedPerDayCol = columnHelper.accessor('totalInvoicedPerDay', {
        header: 'Dagtotaal verkoop',
        cell: props => {
            let cellData = props.getValue();
            if (cellData !== null) {
                if (cellData < 475)
                    return <div className='text-right pr-3 bg-danger text-white'>{renderDecimal(props.getValue())}</div>
                else if (cellData >= 475)
                    return <div className='text-right pr-3 bg-success text-white'>{renderDecimal(props.getValue())}</div>
            }
            return <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>;
        },
        footer: props => {
            const totalInvoiced = props.table.options.data.map(r => r.totalInvoiced).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0) ?? 0;

            const daysPlanned = props.table.options.data.map(r => r.totalDaysPlanned).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0) ?? 0;

            const total = totalInvoiced / daysPlanned;

            return <div className='text-right pr-3'>{renderDecimal(total)}</div>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalOrderedPerDayCol = columnHelper.accessor('totalOrderedPerDay', {
        header: 'Dagtotaal budget',
        cell: props => {
            let cellData = props.getValue();
            if (cellData !== null) {
                if (cellData < 475)
                    return <div className='text-right pr-3 bg-danger text-white'>{renderDecimal(props.getValue())}</div>
                else if (cellData >= 475)
                    return <div className='text-right pr-3 bg-success text-white'>{renderDecimal(props.getValue())}</div>
            }
            return <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>;
        },
        footer: props => {
            const totalOrders = props.table.options.data.map(r => r.totalOrdered).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0) ?? 0;

            const daysPlanned = props.table.options.data.map(r => r.totalDaysPlanned).reduce(function (a, b) {
                return (a ?? 0) + (b ?? 0);
            }, 0) ?? 0;

            const total = totalOrders / daysPlanned;

            return <div className='text-right pr-3'>{renderDecimal(total)}</div>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalOrderedSuppliersCol = columnHelper.accessor('totalOrderedSuppliers', {
        header: 'Tot. best. lev.',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalOrderedSuppliersDeviantCol = columnHelper.accessor('totalOrderedSuppliersDeviant', {
        header: 'Afwijkend tot. best. lev.',
        cell: props => {
            let dataHtml = null;
            const value = props.getValue();

            if (value !== undefined && value !== null) {
                if (typeof value === 'number') {
                    dataHtml = <span>{renderDecimal(value)}</span>;
                }
                else {
                    dataHtml = <span>cellData</span>;
                }
            }
            else {
                dataHtml = <i className="fas fa-pen"></i>;
            }

            return <div className='text-right pr-3'><button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setTotalOrderedSuppliersPercentageModalVisible(true);
                    setSelectedProjectCode(props.row.original.code);
                    setTotalOrderedSuppliersPercentageValue(props.row.original.totalOrderedSuppliersDeviantPercentage ?? null)
                }}>
                {dataHtml}
            </button></div>;

        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalOrderedSubcontractorsCol = columnHelper.accessor('totalOrderedSubcontractors', {
        header: 'Tot. best. OA',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const totalWageCostsCol = columnHelper.accessor('totalWageCosts', {
        header: 'Tot. loonkost',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const foremanCol = columnHelper.accessor('foremanName', {
        header: 'Ploegleider',
        cell: props => {
            let dataHtml = null;

            const value = props.getValue();

            if (props.row.original.customForemanName !== undefined && props.row.original.customForemanName !== null) {
                dataHtml = <span>{props.row.original.customForemanName}</span>;
            }
            else if (value !== undefined && value !== null && value !== "") {
                dataHtml = <span>{value}</span>;
            }
            else {
                dataHtml = <i className="fas fa-pen"></i>;
            }

            return <button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setCustomForemanIdOrCode(props.row.original.customForemanKBContracting === true ? "kbContracting" : props.row.original.customForemanId ?? props.row.original.customForemanSupplierCode ?? null);
                    setShowProjectCustomForemanModal(true);
                }}>
                {dataHtml}
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const ownerCol = columnHelper.accessor('ownerName', {
        header: 'Opvolger',
        cell: props => {
            let dataHtml = null;
            const cellData = props.getValue();
            if (cellData !== undefined && cellData !== null) {
                dataHtml = <span>{cellData}</span>;
            }
            else {
                dataHtml = <i className="fas fa-pen"></i>;
            }

            return <button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setShowProjectOwnerModal(true);
                    setSelectedProjectCode(props.row.original.code);
                    setOwnerId(props.row.original.ownerId ?? null);
                }}>
                {dataHtml}
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const descriptionCol = columnHelper.accessor('description', {
        header: 'Opmerkingen',
        cell: props => renderEllipsis(props.getValue()),
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const preparedCol = columnHelper.accessor('prepared', {
        header: 'Voorbereid',
        cell: props => {
            let dataHtml = <span>Nee</span>;
            const cellData = props.getValue();
            if (cellData !== undefined && cellData !== null) {
                if (typeof cellData === 'boolean') {
                    dataHtml = <span>{cellData === true ? "Ja" : "Nee"}</span>;
                }
                else {
                    dataHtml = <span>{cellData}</span>;
                }
            }

            return <button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setShowProjectPreparedModal(true);
                    setSelectedProjectCode(props.row.original.code);
                    setProjectPreparedValue(props.row.original.prepared ?? false);
                }}>
                {dataHtml}
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const checkedCol = columnHelper.accessor('checked', {
        header: 'Gecontroleerd',
        cell: props => {
            let dataHtml = <span>Nee</span>;
            const cellData = props.getValue();
            if (cellData !== undefined && cellData !== null) {
                if (typeof cellData === 'boolean') {
                    dataHtml = <span>{cellData === true ? "Ja" : "Nee"}</span>;
                }
                else {
                    dataHtml = <span>cellData</span>;
                }
            }

            if (hasRoleProjectController) {
                return <button type='button' className="btn btn-link p-0 btn-in-table"
                    onClick={() => {
                        setShowProjectCheckedModal(true);
                        setSelectedProjectCode(props.row.original.code);
                        setProjectCheckedValue(props.row.original.checked ?? false);
                        setProjectCheckedCommentsValue(props.row.original.checkedComments ?? "");

                    }}>
                    {dataHtml}
                </button>;
            }
            else {
                return dataHtml;
            }
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const checkedCommentsCol = columnHelper.accessor('checkedComments', {
        header: 'Gecontroleerd Opmerkingen',
        cell: props => renderEllipsis(props.getValue()),
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const customerNotifiedCol = columnHelper.accessor('customerNotified', {
        header: 'Klant ingelicht',
        cell: props => {
            let dataHtml = <span>Nee</span>;
            const cellData = props.getValue();
            if (cellData !== undefined && cellData !== null) {
                if (typeof cellData === 'boolean') {
                    dataHtml = <span>{cellData === true ? "Ja" : "Nee"}</span>;
                    if (cellData === true && props.row.original.customerNotifiedOn !== undefined && props.row.original.customerNotifiedOn !== null) {
                        dataHtml = <span>{format(parseJSON(props.row.original.customerNotifiedOn), "dd/MM/yyyy")}</span>;
                    }
                }
                else {
                    dataHtml = <span>{cellData}</span>;
                }
            }

            return <button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setProjectCustomerNotifiedValue(props.row.original.customerNotified ?? false);
                    setProjectCustomerNotifiedOnValue(props.row.original.customerNotifiedOn === undefined || props.row.original.customerNotifiedOn === null ? null : parseJSON(props.row.original.customerNotifiedOn));
                    setProjectCustomerNotifiedByValue(props.row.original.customerNotifiedById ?? null);
                    setProjectCustomerNotifiedMethodValue(props.row.original.customerNotifiedMethod ?? null);
                    setProjectDoNotEditPlanningValue(props.row.original.doNotEditPlanning ?? false);
                    setShowProjectCustomerNotifiedModal(true);
                }}>
                {dataHtml}
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const notifyCustomerCol = columnHelper.display({
        id: "notifyCustomer",
        header: 'Klant inlichten',
        cell: props => {
            return <button type='button' className="btn btn-link p-0 btn-in-table" title="Verstuur email naar klant"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setShowConfirmationModal(true);
                }}>
                <i className="fas fa-envelope"></i>
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const ciawRequestedCol = columnHelper.accessor('ciawCodeRequested', {
        header: 'CIAW nr aangevraagd',
        cell: props => {
            let dataHtml = <span>Nee</span>;
            const cellData = props.getValue();
            if (cellData !== undefined && cellData !== null) {
                if (typeof cellData === 'boolean') {
                    dataHtml = <span>{cellData === true ? "Ja" : "Nee"}</span>;
                    if (cellData === true && props.row.original.ciawCodeRequestedOn !== undefined && props.row.original.ciawCodeRequestedOn !== null) {
                        dataHtml = <span>{format(parseJSON(props.row.original.ciawCodeRequestedOn), "dd/MM/yyyy")}</span>;
                    }
                }
                else {
                    dataHtml = <span>{cellData}</span>;
                }
            }

            return <button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setProjectCiawRequestedValue(props.row.original.ciawCodeRequested ?? false);
                    setProjectCiawRequestedOnValue(props.row.original.ciawCodeRequestedOn === undefined || props.row.original.ciawCodeRequestedOn === null ? null : parseJSON(props.row.original.ciawCodeRequestedOn));
                    setProjectCiawRequestedByValue(props.row.original.ciawCodeRequestedById ?? null);
                    setProjectCiawRequestedMethodValue(props.row.original.ciawCodeRequestedMethod ?? null);
                    setShowProjectCiawRequestedModal(true);
                }}>
                {dataHtml}
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const requestCiawCodeCol = columnHelper.display({
        id: "requestCiaw",
        header: 'CIAWnr vragen',
        cell: props => {
            if (props.row.original.checkInAtWorkCode && props.row.original.checkInAtWorkCode.length > 0)
                return <></>;

            return <button type='button' className="btn btn-link p-0 btn-in-table" title="Verstuur email naar klant voor CIAWnr"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setCiawEmails(props.row.original.ciawEmails ?? null);
                    setShowRequestCiawModal(true);
                }}>
                <i className="fas fa-envelope"></i>
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const subcontractorsReportedCol = columnHelper.accessor('subcontractorsReported', {
        header: 'OA gemeld',
        cell: props => {
            let dataHtml = <span>Nee</span>;
            const cellData = props.getValue();
            if (cellData !== undefined && cellData !== null) {
                if (typeof cellData === 'boolean') {
                    dataHtml = <span>{cellData === true ? "Ja" : "Nee"}</span>;
                    if (cellData === true && props.row.original.subcontractorsReportedOn !== undefined && props.row.original.subcontractorsReportedOn !== null) {
                        dataHtml = <span>{format(parseJSON(props.row.original.subcontractorsReportedOn), "dd/MM/yyyy")}</span>;
                    }
                }
                else {
                    dataHtml = <span>{cellData}</span>;
                }
            }

            return <button type='button' className="btn btn-link p-0 btn-in-table"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setProjectSubcontractorsReportedValue(props.row.original.subcontractorsReported ?? false);
                    setProjectSubcontractorsReportedOnValue(props.row.original.subcontractorsReportedOn === undefined || props.row.original.subcontractorsReportedOn === null ? null : parseJSON(props.row.original.subcontractorsReportedOn));
                    setProjectSubcontractorsReportedByValue(props.row.original.subcontractorsReportedById ?? null);
                    setProjectSubcontractorsReportedMethodValue(props.row.original.subcontractorsReportedMethod ?? null);
                    setShowProjectSubcontractorsReportedModal(true);
                }}>
                {dataHtml}
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const reportSubcontractorsCol = columnHelper.display({
        id: "reportSubcontractors",
        header: 'OA melden',
        cell: props => {
            return <button type='button' className="btn btn-link p-0 btn-in-table" title="Verstuur email naar klant voor melden OA"
                onClick={() => {
                    setSelectedProjectCode(props.row.original.code);
                    setCiawEmails(props.row.original.ciawEmails ?? null);
                    setShowReportSubcontractorsModal(true);
                }}>
                <i className="fas fa-envelope"></i>
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const deletePlanningItemsCol = columnHelper.display({
        id: "deletePlanningItems",
        header: 'Verwijder planning',
        cell: props => {
            return <button type='button' className="btn btn-link p-0 btn-in-table" title="Verwijder planning"
                onClick={() => {
                    setShowDeletePlanningModal(true);
                    setSelectedProjectCode(props.row.original.code);
                }}>
                <i className="fas fa-trash-alt"></i>
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const remindersCountCol = columnHelper.accessor('remindersCount', {
        header: 'Reminders',
        cell: props => {
            const cellData = props.getValue();

            return <button type='button' className="btn btn-link p-0 btn-in-table" title="Reminders"
                onClick={() => {
                    setShowRemindersModal(true);
                    setSelectedProjectCode(props.row.original.code);
                }}>
                <span>{cellData}</span>
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const infoFicheCol = columnHelper.display({
        id: 'Infofiche',
        header: 'Infofiche',
        cell: props => {

            return <button type='button' className="btn btn-link p-0 btn-in-table" title="Infofiche"
                onClick={() => {
                    setShowInfoDocumentModal(true);
                    setSelectedProject(props.row.original);
                }}>
                <i className="fas fa-file"></i>
            </button>;
        },
        enableGlobalFilter: false
    }) as ColumnDef<unknown, any>;

    const allColumns = [
        codeCol,
        customerNameCol,
        nameCol,
        startDateCol,
        actualStartDateCol,
        statusCol,
        totalOrderedCol,
        totalOrderedDaysCol,
        totalDaysPlannedCol,
        totalDaysRestCol,
        totalDaysPlannedThisWeekCol,
        totalDaysPlannedThisMonthCol,
        totalDaysPlannedUntilEndOfThisWeekCol,
        totalInvoicedCol,
        totalInvoicedPerDayCol,
        totalOrderedPerDayCol,
        totalOrderedSuppliersCol,
        totalOrderedSuppliersDeviantCol,
        totalOrderedSubcontractorsCol,
        totalWageCostsCol,
        foremanCol,
        ownerCol,
        descriptionCol,
        preparedCol,
        checkedCol,
        checkedCommentsCol,
        customerNotifiedCol,
        notifyCustomerCol,
        deletePlanningItemsCol,
        remindersCountCol,
        infoFicheCol
    ];

    const planningColumns = [
        codeCol,
        customerNameCol,
        nameCol,
        actualStartDateCol,
        statusCol,
        totalOrderedCol,
        totalOrderedDaysCol,
        totalDaysPlannedCol,
        totalDaysRestCol,
        foremanCol,
        descriptionCol,
        preparedCol,
        customerNotifiedCol,
        notifyCustomerCol,
        deletePlanningItemsCol,
        infoFicheCol
    ];

    const statusColumns = [
        codeCol,
        customerNameCol,
        nameCol,
        statusCol,
        totalOrderedCol,
        totalOrderedDaysCol,
        totalDaysPlannedCol,
        totalDaysRestCol,
        totalDaysPlannedThisWeekCol,
        totalDaysPlannedThisMonthCol,
        totalDaysPlannedUntilEndOfThisWeekCol,
        foremanCol,
        ownerCol,
        descriptionCol,
        preparedCol,
        customerNotifiedCol,
        notifyCustomerCol,
        remindersCountCol
    ];

    const recalculationColumns = [
        codeCol,
        customerNameCol,
        nameCol,
        statusCol,
        totalOrderedCol,
        totalOrderedDaysCol,
        totalDaysPlannedCol,
        totalDaysRestCol,
        totalDaysPlannedThisWeekCol,
        totalDaysPlannedThisMonthCol,
        totalDaysPlannedUntilEndOfThisWeekCol,
        totalInvoicedCol,
        totalInvoicedPerDayCol,
        totalOrderedPerDayCol,
        foremanCol,
        ownerCol,
        descriptionCol,
        preparedCol,
        checkedCol,
        checkedCommentsCol,
        customerNotifiedCol,
        deletePlanningItemsCol
    ];

    const ciawColumns = [
        codeCol,
        checkInAtWorkCodeCol,
        customerNameCol,
        nameCol,
        startDateCol,
        actualStartDateCol,
        ciawRequestedCol,
        requestCiawCodeCol,
        subcontractorsReportedCol,
        reportSubcontractorsCol
    ];

    function totalDaysPlannedThisWeekTitle() {
        let today = new Date();
        let day = today.getDay();
        let diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday        
        let monday = new Date(today);
        monday.setDate(diff);
        let sunday = new Date(today);
        sunday.setDate(diff + 6);

        return "Week " +
            ("" + monday.getDate()).padStart(2, "0") + "/" + ("" + (monday.getMonth() + 1)).padStart(2, "0") +
            " - " +
            ("" + sunday.getDate()).padStart(2, "0") + "/" + ("" + (sunday.getMonth() + 1)).padStart(2, "0");
    }

    function totalDaysPlannedUntilEndOfThisWeekTitle() {
        let today = new Date();
        let day = today.getDay();
        let diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday    
        let sunday = new Date(today);
        sunday.setDate(diff + 6);

        return "T.e.m. " +
            ("" + sunday.getDate()).padStart(2, "0") + "/" + ("" + (sunday.getMonth() + 1)).padStart(2, "0");
    }

    function totalDaysPlannedThisMonthTitle() {
        let today = new Date();
        let from = new Date(today.getFullYear(), today.getMonth(), 1);
        let until = new Date(from);
        until = new Date(new Date(until.setMonth(until.getMonth() + 1)));
        until.setDate(until.getDate() - 1);

        return "Maand " + ("" + (today.getMonth() + 1)).padStart(2, "0");
    }

    let views =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-1 col-form-label" htmlFor="viewSelect">Weergave</label>
                        <div className="col-2">
                            <select className="form-control" id="viewSelect" onChange={handleViewSelect} defaultValue={selectedView}>
                                <option value="planning" >Planning</option>
                                <option value="status" >Status</option>
                                <option value="recalculation" >Nacalculatie</option>
                                <option value="ciaw" >CIAW</option>
                                <option value="all" >Alle kolommen</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-1 col-form-label">Adsolut dossier</label>
                        <div className="col-2 form-inline">
                            <Checkbox className="mr-4" label="MP" value="MP" checked={showMpAdsolutProjects} onChange={(event: any) => handleAdsolutProjectsFilter(event)} />
                            <Checkbox className="mr-4" label="MK" value="MK" checked={showMkAdsolutProjects} onChange={(event: any) => handleAdsolutProjectsFilter(event)} />
                        </div>
                        <label className="col-1 col-form-label">In planning</label>
                        <label className="col-1 col-form-label">van</label>
                        <div className="col-2 form-inline">
                            <DatePicker locale="nlBE" selected={fromDateFilter} onChange={handleFromDateFilterChange}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                        title="Van"
                                    />
                                }
                            />
                        </div>
                        <label className="col-1 col-form-label">tot</label>
                        <div className="col-2 form-inline">
                            <DatePicker locale="nlBE" selected={untilDateFilter} onChange={handleUntilDateFilterChange}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                        title="Tot"
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className=" row">
                        <label className="col-1 col-form-label">Gecontroleerd</label>
                        <div className="col-5 form-inline">
                            <Checkbox className="mr-4" label="Gecontroleerd" value="true" checked={checkedFilter === true} onChange={handleCheckedFilter} />
                            <Checkbox className="mr-4" label="Niet gecontroleerd" value="false" checked={checkedFilter === false} onChange={handleCheckedFilter} />
                            <Checkbox className="mr-4" label="Beide" value="null" checked={checkedFilter === null} onChange={handleCheckedFilter} />
                        </div>
                    </div>
                    <div className=" row">
                        <label className="col-1 col-form-label">Voorbereid</label>
                        <div className="col-5 form-inline">
                            <Checkbox className="mr-4" label="Voorbereid" value="true" checked={preparedFilter === true} onChange={handlePreparedFilter} />
                            <Checkbox className="mr-4" label="Niet voorbereid" value="false" checked={preparedFilter === false} onChange={handlePreparedFilter} />
                            <Checkbox className="mr-4" label="Beide" value="null" checked={preparedFilter === null} onChange={handlePreparedFilter} />
                        </div>
                    </div>
                    <div className=" row">
                        <label className="col-1 col-form-label">Status</label>
                        <div className="col-11 form-inline">
                            {
                                statusFilterValues.sort((r1, r2) => (r1.filterValue ?? "").localeCompare(r2.filterValue ?? "")).map((status) => {
                                    return (
                                        <Checkbox className="mr-4" label={status.filterValue} value={status.filterValue} checked={status.checked} onChange={(event: any) => handleStatusFilter(event)} key={status.filterValue} />
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-1 col-form-label" htmlFor="foremenSelect">Ploegleider</label>
                        <div className="col-2">
                            <select className="form-control" id="foremenSelect" onChange={handleForemanFilter}>
                                <option value="null">- Niet gefilterd -</option>
                                {
                                    foremen.map((foreman) => {
                                        return (
                                            <option value={foreman.id} key={foreman.id}>{foreman.name}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <label className="col-1 col-form-label" htmlFor="customerSelect">Klant</label>
                        <div className="col-2">
                            <select className="form-control" id="customerSelect" onChange={handleCustomerFilter}>
                                <option value="null">- Niet gefilterd -</option>
                                {
                                    customers.map((customer) => {
                                        return (
                                            <option value={customer} key={customer}>{customer}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    if (projects) {
        let table = null;
        let projectCheckedModal = null;
        let projectPreparedModal = null;
        let deletePlanningModal = null;
        let projectPlanningModal = null;
        let projectCustomerNotifiedModal = null;
        let projectCiawRequestedModal = null;
        let remindersModal = null;
        let confirmationModal = null;
        let infoDocumentModal = null;
        let projectForemanModal = null;
        let requestCiawModal = null;
        let subcontractorsReportedModal = null;
        let reportSubcontractorsModal = null;

        if (showLoadingSpinner) {
            table = <div className="d-flex justify-content-center"><Spinner type="grow" color="secondary" className="m-5" /></div>
        }
        else {
            table = <DataTable data={projects} columns={allColumns} height="40vh" />
            if (selectedView === "status")
                table = <DataTable data={projects} columns={statusColumns} height="40vh" />
            else if (selectedView === "planning")
                table = <DataTable data={projects} columns={planningColumns} height="40vh" />
            else if (selectedView === "recalculation")
                table = <DataTable data={projects} columns={recalculationColumns} height="40vh" />
            else if (selectedView === "ciaw")
                table = <DataTable data={projects} columns={ciawColumns} height="40vh" />
        }

        if (showProjectCheckedModal && selectedProjectCode !== null) {
            projectCheckedModal =
                <ProjectCheckedModal
                    code={selectedProjectCode}
                    checked={projectCheckedValue}
                    comments={projectCheckedCommentsValue}
                    afterSave={projectCheckedModalAfterSave}
                    afterCancel={projectCheckedModalAfterClose} />
        }

        if (showProjectPreparedModal && selectedProjectCode !== null) {
            projectPreparedModal =
                <ProjectPreparedModal
                    code={selectedProjectCode}
                    prepared={projectPreparedValue}
                    afterSave={projectPreparedModalAfterSave}
                    afterCancel={projectPreparedModalAfterClose} />
        }

        if (showDeletePlanningModal && selectedProjectCode !== null) {
            deletePlanningModal =
                <DeletePlanningModal
                    code={selectedProjectCode}
                    afterSave={deletePlanningModalAfterSave}
                    afterCancel={deletePlanningModalAfterClose} />
        }

        if (showProjectPlanningModal && selectedProjectCode !== null) {
            projectPlanningModal = <ProjectPlanningModal
                code={selectedProjectCode}
                afterCancel={projectPlanningModalAfterClose} />
        }

        if (showProjectCustomerNotifiedModal && selectedProjectCode !== null) {
            projectCustomerNotifiedModal =
                <ProjectCustomerNotifiedModal
                    code={selectedProjectCode}
                    notified={projectCustomerNotifiedValue}
                    notifiedOn={projectCustomerNotifiedOnValue}
                    notifiedBy={projectCustomerNotifiedByValue}
                    notifiedMethod={projectCustomerNotifiedMethodValue}
                    doNotEditPlanning={projectDoNotEditPlanningValue}
                    owners={owners}
                    afterSave={projectCustomerNotifiedModalAfterSave}
                    afterCancel={projectCustomerNotifiedModalAfterClose} />
        }

        if (showProjectCiawRequestedModal && selectedProjectCode !== null) {
            projectCiawRequestedModal =
                <ProjectCiawRequestedModal
                    code={selectedProjectCode}
                    requested={projectCiawRequestedValue}
                    requestedOn={projectCiawRequestedOnValue}
                    requestedBy={projectCiawRequestedByValue}
                    requestedMethod={projectCiawRequestedMethodValue}
                    owners={owners}
                    afterSave={projectCiawRequestedModalAfterSave}
                    afterCancel={projectCiawRequestedModalAfterClose} />
        }

        if (showRemindersModal && selectedProjectCode !== null) {
            remindersModal =
                <RemindersModal
                    code={selectedProjectCode}
                    afterClose={remindersModalAfterClose} />
        }

        if (showInfoDocumentModal && selectedProject !== undefined) {
            infoDocumentModal =
                <InfoDocumentModal
                    project={selectedProject}
                    afterClose={infoDocumentModalAfterClose} />
        }

        if (showProjectCustomForemanModal && selectedProjectCode !== null) {
            projectForemanModal =
                <ProjectForemanModal
                    code={selectedProjectCode}
                    customForemanIdOrCode={customForemanIdOrCode}
                    foremen={foremen}
                    suppliers={suppliers}
                    afterSave={projectCustomForemanModalSave}
                    afterCancel={projectCustomForemanModalToggle} />
        }

        if (showRequestCiawModal && selectedProjectCode !== null) {
            requestCiawModal = <RequestCiawModal
                code={selectedProjectCode}
                emails={ciawEmails ?? ""}
                afterSave={requestCiawModalSave}
                afterCancel={requestCiawModalCancel}
            />
        }

        if (showConfirmationModal) {
            confirmationModal = <ConfirmationModal
                text="Email naar klant versturen?"
                afterCancel={confirmationModalAfterClose}
                afterOk={confirmationModalAfterOk} />
        }

        if (showProjectSubcontractorsReportedModal && selectedProjectCode !== null) {
            subcontractorsReportedModal =
                <ProjectSubcontractorsReportedModal
                    code={selectedProjectCode}
                    reported={projectSubcontractorsReportedValue}
                    reportedOn={projectSubcontractorsReportedOnValue}
                    reportedBy={projectSubcontractorsReportedByValue}
                    reportedMethod={projectSubcontractorsReportedMethodValue}
                    owners={owners}
                    afterSave={projectSubcontractorsReportedModalAfterSave}
                    afterCancel={projectSubcontractorsReportedModalAfterClose} />
        }

        if (showReportSubcontractorsModal && selectedProjectCode !== null) {
            requestCiawModal = <ReportSubcontractorsModal
                code={selectedProjectCode}
                emails={ciawEmails ?? ""}
                afterSave={reportSubcontractorsModalSave}
                afterCancel={reportSubcontractorsModalCancel}
            />
        }

        return (
            <div className="container-fluid">
                {views}
                {filters}
                {table}
                <Modal isOpen={totalOrderedSuppliersPercentageModalVisible} toggle={totalOrderedSuppliersPercentageModalToggle}>
                    <ModalHeader toggle={totalOrderedSuppliersPercentageModalToggle}>{selectedProjectCode} - Afwijkend tot. best. lev.</ModalHeader>
                    <ModalBody>
                        <form className="form-inline">
                            <div className="form-group">
                                <label className="my-1 mr-2" htmlFor="totalOrderedSuppliersPercentageInput">% t.o.v. Tot. verkoop</label>
                                <input type="text" className="form-control my-1 mr-sm-2" id="totalOrderedSuppliersPercentageInput"
                                    onChange={handleTotalOrderedSuppliersPercentageChange}
                                    value={totalOrderedSuppliersPercentageValue ?? ""} />
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={totalOrderedSuppliersPercentageModalSave}>Bewaren</Button>{' '}
                        <Button color="secondary" onClick={totalOrderedSuppliersPercentageModalToggle}>Sluiten</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={showProjectOwnerModal} toggle={projectOwnerModalToggle}>
                    <ModalHeader toggle={projectOwnerModalToggle}>{selectedProjectCode} - Opvolger</ModalHeader>
                    <ModalBody>
                        <form className="form-inline">
                            <div className="form-group">
                                <label className="my-1 mr-2" htmlFor="projectOwnerInput">Opvolger</label>
                                <select className="form-control my-1 mr-sm-2" id="projectOwnerInput"
                                    onChange={handleOwnerChange}
                                    defaultValue={ownerId ?? ""} >
                                    <option value="null">- Geen opvolger -</option>
                                    {
                                        owners.map((owner) => {
                                            return (
                                                <option value={owner.id} key={owner.id}>{owner.name}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={projectOwnerModalSave}>Bewaren</Button>{' '}
                        <Button color="secondary" onClick={projectOwnerModalToggle}>Sluiten</Button>
                    </ModalFooter>
                </Modal>

                {projectForemanModal}
                {projectCheckedModal}
                {projectPreparedModal}
                {deletePlanningModal}
                {projectPlanningModal}
                {projectCustomerNotifiedModal}
                {remindersModal}
                {confirmationModal}
                {infoDocumentModal}
                {requestCiawModal}
                {projectCiawRequestedModal}
                {subcontractorsReportedModal}
                {reportSubcontractorsModal}
            </div>
        );
    }

    return (
        <div className="container-fluid">
        </div>
    );
}

export default Projects;