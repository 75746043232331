import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { ColorResult, SketchPicker } from 'react-color';

interface ColorPickerModalProps {
    color: string;
    onSave: Function;
    onClose: Function;
}

interface ColorPickerModalState {
    color: string;
    visible: boolean;
}

export default class ColorPickerModal extends React.Component<ColorPickerModalProps, ColorPickerModalState> {

    constructor(props: ColorPickerModalProps) {
        super(props);

        this.state = {
            color: props.color,
            visible: true
        };

        this.modalToggle = this.modalToggle.bind(this)
        this.modalSave = this.modalSave.bind(this);
        this.handleChangeComplete = this.handleChangeComplete.bind(this);
    }

    handleChangeComplete(color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ color: color.hex });
    }

    modalToggle() {
        this.setState({ visible: !this.state.visible });
        this.props.onClose();
    }

    modalSave() {
        this.setState({ visible: false });
        this.props.onSave(this.state.color);
    }

    render() {
        return (
            <Modal isOpen={this.state.visible} toggle={this.modalToggle}>
                <ModalBody>
                    <SketchPicker
                        color={this.state.color}
                        onChangeComplete={this.handleChangeComplete} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.modalSave}>Bewaren</Button>{' '}
                    <Button color="secondary" onClick={this.modalToggle}>Sluiten</Button>
                </ModalFooter>
            </Modal>
        );
    }
}