import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Checkbox from "../Components/Checkbox";
import ProjectsService from '../Services/ProjectsService';
import DatePicker, { registerLocale } from "react-datepicker";
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import PersonModel from '../Models/PersonModel';

interface ProjectCustomerNotifiedModalProps {
    code: string;
    notified: boolean;
    notifiedOn: Date | null;
    notifiedBy: string | null;
    notifiedMethod: string | null;
    doNotEditPlanning: boolean;
    owners: PersonModel[];
    afterSave: Function;
    afterCancel: Function;
}

const ProjectCustomerNotifiedModal = (props: ProjectCustomerNotifiedModalProps) => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [notified, setNotified] = useState<boolean>(props.notified);
    const [notifiedOn, setNotifiedOn] = useState<Date | null>(props.notifiedOn);
    const [notifiedBy, setNotifiedBy] = useState<string | null>(props.notifiedBy);
    const [notifiedMethod, setNotifiedMethod] = useState<string | null>(props.notifiedMethod);
    const [doNotEditPlanning, setDoNotEditPlanning] = useState<boolean>(props.doNotEditPlanning);
    const [visible, setVisible] = useState<boolean>(true);

    registerLocale('nlBE', nlBE);

    function handleProjectCustomerNotifiedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNotified(event.target.checked);
    }

    function handleProjectDoNotEditPlanningChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDoNotEditPlanning(event.target.checked);
    }

    function handleProjectCustomerNotifiedOnChange(date: Date) {
        setNotifiedOn(date);
    }

    function projectCustomerNotifiedModalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function projectCustomerNotifiedModalSave() {
        try {
            if (props.code !== null) {

                await projectsService.updateCustomerNotified(props.code, notified, notifiedOn, notifiedBy, notifiedMethod, doNotEditPlanning);

                setVisible(false);

                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }


    return (
        <Modal isOpen={visible} toggle={projectCustomerNotifiedModalToggle} className="mw-100 w-75">
            <ModalHeader toggle={projectCustomerNotifiedModalToggle}>{props.code} - Klant op de hoogte van afspraak?</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <Checkbox className="my-1 mr-sm-2" label="Klant op de hoogte van afspraak?" value="true" checked={notified}
                            onChange={handleProjectCustomerNotifiedChange} />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">Klant op de hoogte gebracht op</label>
                        <DatePicker locale="nlBE" selected={notifiedOn} onChange={handleProjectCustomerNotifiedOnChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">Klant op de hoogte gebracht door</label>
                        <select className="form-control my-1 mr-sm-2" onChange={(e) => setNotifiedBy(e.target.value)}
                            defaultValue={notifiedBy ?? ""} >
                            <option value="null">- Geen opvolger -</option>
                            {
                                props.owners.map((owner) => {
                                    return (
                                        <option value={owner.id} key={owner.id}>{owner.name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">Klant op de hoogte gebracht met</label>
                        <select className="form-control my-1 mr-sm-2" onChange={(e) => setNotifiedMethod(e.target.value)}
                            defaultValue={notifiedMethod ?? ""} >
                            <option value="null">- Geen waarde -</option>
                            <option value="Email">Email</option>
                            <option value="Telefoon">Telefoon</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <Checkbox className="my-1 mr-sm-2" label="Niet meer verplaatsen" value="true" checked={doNotEditPlanning}
                            onChange={handleProjectDoNotEditPlanningChange} />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={projectCustomerNotifiedModalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={projectCustomerNotifiedModalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ProjectCustomerNotifiedModal;