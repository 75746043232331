import { useEffect, useMemo, useState } from 'react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';
import {
    Button,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import RemindersService from '../Services/ReminderService';
import '@fortawesome/fontawesome-free/css/all.css';
import GraphService from '../Services/GraphService';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { User } from '@microsoft/microsoft-graph-types';
import TasksService from '../Services/TasksService';
import TaskModel from '../Models/TaskModel';


const AuthNavItem = () => {

    const [user, setUser] = useState<User>();

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);

    useEffect(() => {
        async function getUser() {
            let user = await graphService.getUserDetails();
            setUser(user);
        }

        getUser();
    }, [graphService]);

    if (isAuthenticated) {
        return (
            <UncontrolledDropdown>
                <DropdownToggle nav caret>
                    <i className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
                        style={{ width: '32px' }}></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <h5 className="dropdown-item-text mb-0">{user?.displayName}</h5>
                    <p className="dropdown-item-text text-muted mb-0">{user?.mail}</p>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => instance.logoutRedirect()}>Afmelden</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    // Not authenticated, return a sign in link
    return (
        <NavItem>
            <Button
                onClick={() => instance.loginRedirect()}
                className="btn-link nav-link border-0"
                color="link">Aanmelden</Button>
        </NavItem>
    );
}

const RemindersBadge = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const remindersService = useMemo(() => new RemindersService(instance), [instance]);

    const [reminderCount, setReminderCount] = useState<number>(0);

    const location = useLocation();

    useEffect(() => {

        const fetchData = async () => {
            const user = await graphService.getUserDetails();

            if (user.id !== undefined) {
                const reminders = await remindersService.getOpen(user.id, false);

                setReminderCount(reminders.length);
            }
        }

        fetchData();
    }, [location, graphService, remindersService])

    if (reminderCount > 0)
        return (
            <span className="badge badge-pill badge-warning">{reminderCount}</span>
        );
    else
        return (
            <span className="badge badge-pill badge-success">0</span>
        );
}

const TasksBadge = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const tasksService = useMemo(() => new TasksService(instance), [instance]);

    const [tasks, setTasks] = useState<TaskModel[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const location = useLocation();

    useEffect(() => {

        const fetchData = async () => {
            const user = await graphService.getUserDetails();

            if (user.id !== undefined) {
                const tasks = await tasksService.getOpen(user.id);

                setTasks(tasks);
            }
        }

        fetchData();
    }, [location, graphService, tasksService])

    let badge = <span className="badge badge-pill badge-success">0</span>;
    if (tasks.length > 0)
        badge = <span className="badge badge-pill badge-warning">{tasks.length}</span>;

    return (
        <Dropdown title='Taken' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle nav caret>
                {badge}
            </DropdownToggle>
            <DropdownMenu right>
                {tasks.map(task => <DropdownItem key={task.id}>{task.description}</DropdownItem>)}
            </DropdownMenu>
        </Dropdown>
    );
}

const AuthenticatedNavBar = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);

    const [roles, setRoles] = useState<string[]>([]);
    const [settingsDropdownOpen, setSettingsDropdownOpen] = useState<boolean>(false);
    const [leaveDropdownOpen, setLeaveDropdownOpen] = useState<boolean>(false);
    const [planningDropdownOpen, setPlanningDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        async function getUser() {
            let roles = await graphService.getRoles();
            setRoles(roles);
        }

        getUser();
    }, [graphService]);

    let projectsLink = null;
    let checkinatworkLink = null;
    let planningLink = null;
    let statusLink = null;
    let peopleLeaveDaysDropDown = null;
    let settingDropdown = null;
    let remindersLink = null;
    let tasksLink = null;
    let wiuLink = null;

    let leaveLink = (
        <NavItem>
            <RouterNavLink to="/leave" className="nav-link" >Verlof</RouterNavLink>
        </NavItem>
    );

    if (roles.includes("planner") || roles.includes("project.controller")) {
        projectsLink = (
            <NavItem>
                <RouterNavLink to="/projects" className="nav-link" >Projecten</RouterNavLink>
            </NavItem>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller")) {
        checkinatworkLink = (
            <NavItem>
                <RouterNavLink to="/checkinatwork" className="nav-link" >CheckInAtWork</RouterNavLink>
            </NavItem>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller") || roles.includes("planner.slo")) {
        planningLink = (
            <>
                <NavItem>
                    <RouterNavLink to="/planning" className="nav-link" >Planning</RouterNavLink>
                </NavItem>
                {
                    (roles.includes("planner") || roles.includes("project.controller")) &&
                    <Dropdown isOpen={planningDropdownOpen} toggle={() => setPlanningDropdownOpen(!planningDropdownOpen)}>
                        <DropdownToggle nav caret>
                            <i className="fas fa-bars"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <RouterNavLink to="/planningoverview" className="dropdown-item" onClick={() => setPlanningDropdownOpen(!planningDropdownOpen)}>Overzicht planning</RouterNavLink>
                            <RouterNavLink to="/planningoverviewoa" className="dropdown-item" onClick={() => setPlanningDropdownOpen(!planningDropdownOpen)}>Overzicht planning OA</RouterNavLink>
                        </DropdownMenu>
                    </Dropdown>
                }
            </>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller")) {
        peopleLeaveDaysDropDown = (
            <Dropdown isOpen={leaveDropdownOpen} toggle={() => setLeaveDropdownOpen(!leaveDropdownOpen)}>
                <DropdownToggle nav caret>
                    <i className="fas fa-bars"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <RouterNavLink to="/peopleLeaveDaysOverview" className="dropdown-item" onClick={() => setLeaveDropdownOpen(!leaveDropdownOpen)}>Verloftellers overzicht</RouterNavLink>
                    <RouterNavLink to="/peopleLeaveDays" className="dropdown-item" onClick={() => setLeaveDropdownOpen(!leaveDropdownOpen)}>Verloftellers</RouterNavLink>
                    <RouterNavLink to="/leaverequests" className="dropdown-item" onClick={() => setLeaveDropdownOpen(!leaveDropdownOpen)}>Verlofaanvragen</RouterNavLink>
                </DropdownMenu>
            </Dropdown>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller")) {
        statusLink = (
            <NavItem>
                <RouterNavLink to="/status" className="nav-link" >Status</RouterNavLink>
            </NavItem>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller")) {
        settingDropdown = (
            <Dropdown isOpen={settingsDropdownOpen} toggle={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>
                <DropdownToggle nav caret>
                    Instellingen
                </DropdownToggle>
                <DropdownMenu right>
                    <RouterNavLink to="/people" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Personeel</RouterNavLink>
                    <RouterNavLink to="/mandatoryworkingweeks" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Verplichte werkweken</RouterNavLink>
                    <RouterNavLink to="/suppliers" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Onderaannemers</RouterNavLink>
                    <RouterNavLink to="/planningitemtypesconfiguration" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Verloftypes</RouterNavLink>
                    <RouterNavLink to="/collectiveplanningitems" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Collectieve planning</RouterNavLink>
                    <RouterNavLink to="/projectcolors" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Projectkleuren</RouterNavLink>
                    <RouterNavLink to="/projecttypes" className="dropdown-item" onClick={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>Projecttypes</RouterNavLink>
                </DropdownMenu>
            </Dropdown>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller")) {
        remindersLink = (
            <NavItem>
                <RouterNavLink to="/reminders" className="nav-link" >Reminders <RemindersBadge /></RouterNavLink>
            </NavItem>
        );
    }
    if (roles.includes("project.controller")) {
        wiuLink = (
            <NavItem>
                <RouterNavLink to="/wiu" className="nav-link" >WIU</RouterNavLink>
            </NavItem>
        );
    }
    if (roles.includes("planner") || roles.includes("project.controller")) {
        tasksLink = (
            <>
                <NavItem>
                    <RouterNavLink to="/tasks" className="nav-link" >Taken</RouterNavLink>
                </NavItem>
                <TasksBadge />
            </>
        );
    }

    return (
        <>
            {planningLink}
            {projectsLink}
            {checkinatworkLink}
            {wiuLink}
            {leaveLink}
            {peopleLeaveDaysDropDown}
            {settingDropdown}
            {statusLink}
            {remindersLink}
            {/* {tasksLink} */}
        </>
    );
}

export default function NavBar() {

    const isAuthenticated = useIsAuthenticated();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Navbar color="dark" dark expand="lg" fixed="top">
            <NavbarBrand href="/">M-Painting - Planningstool</NavbarBrand>
            <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    <NavItem>
                        <RouterNavLink to="/" className="nav-link" >Home</RouterNavLink>
                    </NavItem>
                    {isAuthenticated && <AuthenticatedNavBar />}
                </Nav>
                <Nav className="justify-content-end" navbar>
                    <AuthNavItem />
                </Nav>
            </Collapse>
        </Navbar>
    );
}
