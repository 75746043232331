import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ProjectsService from '../Services/ProjectsService';
import ProjectPlanningModel from '../Models/ProjectPlanningModel';
import { format, parseJSON } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

interface ProjectPlanningModalProps {
    code: string;
    afterCancel: Function;
}

const ProjectPlanningModal = (props: ProjectPlanningModalProps) => {
    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [planning, setPlannig] = useState<ProjectPlanningModel[]>([]);
    const [visible, setVisible] = useState<boolean>(true);

    useEffect(() => {
        async function loadData() {
            try {
                const data = await projectsService.getProjectPlanning(props.code);
                setPlannig(data);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [props.code, projectsService]);



    function modalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }


    return (
        <Modal isOpen={visible} toggle={modalToggle} size="lg" scrollable={true} >
            <ModalHeader toggle={modalToggle}>{props.code} - Planning</ModalHeader>
            <ModalBody>
                <table className="table table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Naam</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            planning.map((record, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{format(parseJSON(record.start), "dd/MM/yyyy")} {record.start.endsWith("12:00:00") ? "NM" : "VM"}</td>
                                        <td>{record.name}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ProjectPlanningModal;