import React, { useEffect, useMemo, useState } from "react";
import PeopleService from '../Services/PeopleService';
import PlanningItemTypesService from '../Services/PlanningItemTypesService';
import PlanningService from '../Services/PlanningService';
import PersonModel from "../Models/PersonModel";
import PersonLeaveModel from "../Models/PersonLeaveModel";
import { PlanningItemTypeModel } from "../Models/PlanningItemTypeModel";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import {
    ColumnDef,
    createColumnHelper,
} from '@tanstack/react-table';
import DataTable, { renderDateTime } from "../Components/DataTable";

const columnHelper = createColumnHelper<PersonLeaveModel>();

const columns = [
    columnHelper.accessor('start', {
        header: 'Van',
        cell: props => renderDateTime(props.getValue()),
        size: 200
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('end', {
        header: 'Tot',
        cell: props => renderDateTime(props.getValue()),
    }) as ColumnDef<unknown, any>
];

const PeopleLeaveDays = () => {

    const { instance } = useMsal();

    const peopleService = useMemo(() => new PeopleService(instance), [instance]);
    const planningItemTypesService = useMemo(() => new PlanningItemTypesService(instance), [instance]);
    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    const [people, setPeople] = useState<PersonModel[]>([]);
    const [types, setTypes] = useState<PlanningItemTypeModel[]>([]);
    const [year, setYear] = useState<number>((new Date()).getFullYear());
    const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
    const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
    const [tableData, setTableData] = useState<PersonLeaveModel[]>([]);

    useEffect(() => {
        async function loadMetaData() {
            try {
                let people = await peopleService.getAllFuncUsers();
                let pit = await planningItemTypesService.getAllPlanningItemTypes();

                people = people.sort((r1: PersonModel, r2: PersonModel) => {
                    if (r1.groupName === r2.groupName)
                        return (r1.name ?? "").localeCompare(r2.name ?? "");
                    return (r1.groupName ?? "").localeCompare(r2.groupName ?? "")
                });

                setPeople(people);
                setTypes(pit);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadMetaData();
    }, [peopleService, planningItemTypesService]);

    useEffect(() => {
        async function loadData() {
            try {

                let data = await planningService.getPeopleLeaveDaysDetails(year, selectedPersonId, selectedTypeId);

                setTableData(data);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }
        loadData();
    }, [year, selectedPersonId, selectedTypeId, planningService]);

    async function handlePersonFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedPersonId(event.target.value);
    }

    async function handleTypeFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedTypeId(event.target.value === null ? null : parseInt(event.target.value));
    }

    async function handleYearChange(event: React.ChangeEvent<HTMLInputElement>) {
        setYear(parseInt(event.target.value));
    }

    let groupNameList = people.map(r => r.groupName)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((value) => value !== undefined && value !== null && value !== "");
    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className="row">
                        <label className="col-1 col-form-label" htmlFor="personSelect">Persoon</label>
                        <div className="col-2">
                            <select className="form-control" id="personSelect" onChange={handlePersonFilter}>
                                <option value="null">- Kies een persoon -</option>
                                {
                                    groupNameList.map((groupName) => {
                                        let filterePeople = people.filter((value) => value.groupName === groupName);
                                        return (
                                            <optgroup label={groupName} key={groupName}>
                                                {
                                                    filterePeople.map((person) => {
                                                        return (
                                                            <option value={person.id} key={person.id}>{person.name}</option>
                                                        );
                                                    })
                                                }
                                            </optgroup>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <label className="col-1 col-form-label" htmlFor="typeSelect">Verlof type</label>
                        <div className="col-2">
                            <select className="form-control" id="typeSelect" onChange={handleTypeFilter}>
                                <option value="null">- Kies een type -</option>
                                {
                                    types.map((type) => {
                                        return (
                                            <option value={type.id} key={type.id}>{type.description} ({type.code})</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <label className="col-1 col-form-label" htmlFor="yearInput">Jaar</label>
                        <div className="col-2">
                            <input type="number" className="form-control" id="yearInput" onChange={handleYearChange} value={year}></input>
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    if (people.length > 0 && types.length > 0) {
        return (
            <div className="container-fluid" >
                {filters}
                <DataTable
                    data={tableData}
                    columns={columns}
                    height="65vh"
                />
            </div>
        );
    }

    return (
        <></>
    );



}

export default PeopleLeaveDays;