import { format } from "date-fns";
import ProjectModel from "../Models/ProjectModel";
import ProjectPlanningModel from "../Models/ProjectPlanningModel";
import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import { SubcontractorModel } from "../Models/SubcontractorModel";

export default class ProjectsService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getProjects(statuses: string[], foremanId: string | null,
        mkProjects: boolean, mpProjects: boolean, checkedFilter: boolean | null, preparedFilter: boolean | null,
        customer: string | null, from: Date | null, until: Date | null): Promise<ProjectModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let adsolutDossiers: string[] = [];
        if (mpProjects) {
            adsolutDossiers.push('000072');
        }
        if (mkProjects) {
            adsolutDossiers.push('000073');
        }

        let projects = await fetch(baseUrl + '/api/Projects?statuses=' + statuses + '&foreman=' + foremanId +
            '&adsolutDossiers=' + adsolutDossiers + '&checked=' + checkedFilter + '&prepared=' + preparedFilter +
            '&customer=' + customer + '&from=' + (from === null ? "null" : format(from, "yyyy-MM-dd")) + '&until=' + (until === null ? "null" : format(until, "yyyy-MM-dd")), {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return projects;
    }

    public async getWiuProjects(statuses: string[], mkProjects: boolean, mpProjects: boolean, year: string, month: string): Promise<ProjectModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        let adsolutDossiers: string[] = [];
        if (mpProjects) {
            adsolutDossiers.push('000072');
        }
        if (mkProjects) {
            adsolutDossiers.push('000073');
        }

        let projects = await fetch(baseUrl + '/api/WiuProjects?statuses=' + statuses +
            '&adsolutDossiers=' + adsolutDossiers +
            '&year=' + year +
            '&month=' + month, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return projects;
    }

    public async getStatusFilterValues(): Promise<string[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let values = await fetch(baseUrl + '/api/ProjectStatusFilterValues', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return values;
    }

    public async updateProjectTotalOrderedSuppliersDeviantPercentage(code: string, totalOrderedSuppliersDeviantPercentage: number | null) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectTotalOrderedSuppliersDeviantPercentage?code=' + code + '&totalOrderedSuppliersDeviantPercentage=' + totalOrderedSuppliersDeviantPercentage, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async updateChecked(code: string, checked: boolean, checkedComments: string) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectChecked?code=' + code + '&checked=' + checked + '&checkedComments=' + encodeURIComponent(checkedComments), {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async updatePrepared(code: string, prepared: boolean) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectPrepared?code=' + code + '&prepared=' + prepared, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async updateCustomerNotified(code: string, notified: boolean, notifiedOn: Date | null,
        personid: string | null, method: string | null, doNotEditPlanning: boolean) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectCustomerNotified?code=' + code + '&notified=' + notified +
            '&notifiedon=' + encodeURIComponent(notifiedOn === null ? "null" : format(notifiedOn, "yyyy-MM-dd")) +
            '&by=' + personid + '&method=' + method + '&doNotEditPlanning=' + doNotEditPlanning, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async updateProjectOwner(code: string, ownerId: string | null) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectOwner?code=' + code + '&ownerid=' + ownerId, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async updateProjectCustomForeman(code: string, personid: string | null) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectCustomForeman?code=' + code + '&id=' + personid, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async getProjectColors(): Promise<ProjectModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let projects = await fetch(baseUrl + '/api/ProjectColor', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return projects;
    }

    public async updateColor(code: string, backgroundColor: string) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectColor?code=' + code + '&backgroundColor=' + encodeURIComponent(backgroundColor), {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async getProjectPlanning(code: string): Promise<ProjectPlanningModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let data = await fetch(baseUrl + '/api/ProjectPlanning?code=' + code, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    public async getCustomers(): Promise<string[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let projects = await fetch(baseUrl + '/api/Customers', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return projects;
    }

    public async sendCustomerEmail(code: string, cc: string, personid: string): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let success = await fetch(baseUrl + '/api/SendProjectCustomerEmail?code=' + code + '&cc=' + cc + '&person=' + personid, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return success;
    }

    public async requestCiawFunction(code: string, to: string, personid: string): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let success = await fetch(baseUrl + '/api/RequestCiaw?code=' + code + '&to=' + encodeURIComponent(to) + '&person=' + personid, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return success;
    }

    public async reportSubcontractorsFunction(code: string, to: string, personid: string, subcontractors: SubcontractorModel[]): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let success = await fetch(baseUrl + '/api/ReportSubcontractors?code=' + code + '&to=' + encodeURIComponent(to) + '&person=' + personid, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            }),
            body: JSON.stringify(subcontractors)
        }).then(function (response) {
            return response.json();
        });

        return success;
    }

    public async updateCiawRequested(code: string, requested: boolean, requestedon: Date | null,
        personid: string | null, method: string | null) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectCiawRequested?code=' + code + '&requested=' + requested +
            '&requestedon=' + encodeURIComponent(requestedon === null ? "null" : format(requestedon, "yyyy-MM-dd")) +
            '&by=' + personid + '&method=' + method, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async updateSubcontractorsReported(code: string, reported: boolean, reportedon: Date | null,
        personid: string | null, method: string | null) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/ProjectSubcontractorsReported?code=' + code + '&reported=' + reported +
            '&reportedon=' + encodeURIComponent(reportedon === null ? "null" : format(reportedon, "yyyy-MM-dd")) +
            '&by=' + personid + '&method=' + method, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}