import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PlanningService from '../Services/PlanningService';
import DatePicker, { registerLocale } from "react-datepicker";
import { format } from 'date-fns';
import nlBE from 'date-fns/locale/nl-BE';

import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

interface DeletePlanningModalProps {
    code: string;
    afterSave: Function;
    afterCancel: Function;
}

const DeletePlanningModal = (props: DeletePlanningModalProps) => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    const [start, setStart] = useState<Date>(new Date());
    const [visible, setVisible] = useState<boolean>(true);

    registerLocale('nlBE', nlBE);

    function handleStartChange(date: Date) {
        setStart(date);
    }

    function modalToggle() {
        setVisible(!visible);

        props.afterCancel();
    }

    async function modalSave() {
        try {
            if (props.code !== null) {
                await planningService.deletePlanningItemsForProject(format(start, "yyyy-MM-dd"), props.code);

                setVisible(false);
                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }


    return (
        <Modal isOpen={visible} toggle={modalToggle}>
            <ModalHeader toggle={modalToggle}>{props.code} - Verwijder planning</ModalHeader>
            <ModalBody>
                <form className="form-inline">
                    <div className="form-group">
                        <label className="my-1 mr-2">Alle planning items na</label>
                        <DatePicker locale="nlBE" selected={start} onChange={handleStartChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Verwijderen</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default DeletePlanningModal;