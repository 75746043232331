import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parseJSON } from 'date-fns';
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import CollectivePlanningItemModel from '../Models/CollectivePlanningItemModel';
import { PlanningItemTypeModel } from '../Models/PlanningItemTypeModel';
import PlanningService from '../Services/PlanningService';


interface CollectivePlanningItemModalProps {
    collectivePlanningItem: CollectivePlanningItemModel | null;
    planningItemTypes: PlanningItemTypeModel[];
    afterSave: Function;
    afterCancel: Function;
}

const CollectivePlanningItemModal = (props: CollectivePlanningItemModalProps) => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    const [date, setDate] = useState<Date>(props.collectivePlanningItem?.date !== undefined ? parseJSON(props.collectivePlanningItem?.date ?? "") : new Date());
    const [visible, setVisible] = useState<boolean>(true);
    const [selectedPlanningItemTypeId, setSelectedPlanningItemTypeId] = useState<number | null>(props.collectivePlanningItem?.planningItemTypeId ?? null);

    registerLocale('nlBE', nlBE);

    function handleDateChange(date: Date) {
        setDate(date);
    }

    function handlePlanningItemTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedPlanningItemTypeId(parseInt(event.target.value));
    }

    function modalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function modalSave() {
        try {
            await planningService.upsertCollectivePlanningItem({
                id: props.collectivePlanningItem?.id,
                date: format(date, "yyyy-MM-dd"),
                planningItemTypeId: selectedPlanningItemTypeId ?? -1
            });

            setVisible(false);
            props.afterSave();
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }


    return (
        <Modal isOpen={visible} toggle={modalToggle}>
            <ModalHeader toggle={modalToggle}>Collectieve planning</ModalHeader>
            <ModalBody>
                <form>
                    <div className="form-group">
                        <label className="" htmlFor="typeSelect">Type</label>
                        <select className="form-control" id="typeSelect" defaultValue={selectedPlanningItemTypeId ?? ""} onChange={handlePlanningItemTypeChange}>
                            {
                                props.planningItemTypes.map((type) => {
                                    return (
                                        <option value={type.id}>{type.code}</option>
                                    );
                                })
                            }
                        </select>

                    </div>
                    <div className="form-group">
                        <label className="my-1 mr-2">Datum</label>
                        <DatePicker locale="nlBE" selected={date} onChange={handleDateChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                    title="Van"
                                />
                            }
                        />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal >
    );

}

export default CollectivePlanningItemModal;