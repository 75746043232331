import React from 'react';
import PlanningService from '../Services/PlanningService';
import FullCalendar, { SlotLabelContentArg, EventMountArg } from '@fullcalendar/react'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import nlLocale from '@fullcalendar/core/locales/nl';
import { PlanningResourceModel } from '../Models/PlanningResourceModel';
import { PlanningItemModel } from '../Models/PlanningItemModel';
import { MsalContext } from '@azure/msal-react';
import { toast } from 'react-toastify';
import GraphService from '../Services/GraphService';
import { addWeeks, parseJSON } from 'date-fns';

interface PlanningCalendarProps {
    trashRef: React.RefObject<HTMLDivElement>;
    selectedProjectPlanningItemType: string | null;
    planningItemClick: Function;
    clearPlanningItemToDelete: Function;
    bcEventDropped: Function;
    descriptionEventDropped: Function;
    descriptionEventClicked: Function;
    groupsFilter: string[];
    resourcesFilter: string[];
}

interface PlanningCalendarState {
    resources: any[];
    filteredResources: any[];
    events: any[];
    startStr: string;
    endStr: string;
    planningSlo?: boolean;
    lastClickedEvent?: any;
}

export default class PlanningCalendar extends React.Component<PlanningCalendarProps, PlanningCalendarState> {

    calendarRef: React.RefObject<FullCalendar>;

    context!: React.ContextType<typeof MsalContext>;
    static contextType = MsalContext;

    constructor(props: any) {
        super(props);

        this.state = {
            resources: [],
            filteredResources: [],
            events: [],
            startStr: "",
            endStr: ""
        };

        this.calendarRef = React.createRef();

        this.handleEventRecieve = this.handleEventRecieve.bind(this);
        this.handleEventDrop = this.handleEventDrop.bind(this);
        this.handleEventResize = this.handleEventResize.bind(this);
        this.handleEventDragStop = this.handleEventDragStop.bind(this);
        this.handleDatesSet = this.handleDatesSet.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
    }

    async componentDidMount() {

        try {
            let planningResources = await new PlanningService(this.context.instance).getResources();
            let planningSlo = await new GraphService(this.context.instance).hasRole('planner.slo');

            if (planningSlo === true) {
                planningResources = planningResources.filter(r => r.groupName?.endsWith(" KB"));
            }

            for (let resource of planningResources) {
                resource.title = resource.name;
            }

            let resources = [];

            //add users without a team
            let usersWithoutTeam = planningResources.filter((value) => value.type === "Person" && (!value.teamName || value.teamName === ""));
            usersWithoutTeam = usersWithoutTeam.sort((r1, r2) => r1.title.localeCompare(r2.title));

            for (let resource of usersWithoutTeam) {
                resources.push({
                    id: resource.id,
                    order: ("000" + resources.length + 1).slice(-3),
                    title: resource.title + " (NT)",
                    planningResource: resource,
                    groupName: resource.groupName
                });
            }

            //add teams
            let teams = planningResources.map(resource => resource.teamName)
                .filter((value, index, self) => self.indexOf(value) === index)
                .filter((value) => value !== undefined && value !== null && value !== "");
            teams = teams.sort((r1, r2) => r1.localeCompare(r2));

            for (let team of teams) {
                let usersInTeam = planningResources.filter((value) => value.type === "Person" && value.teamName === team);
                usersInTeam = usersInTeam.sort((r1, r2) => ((r1.isForeman === r2.isForeman) ? 0 : r1.isForeman ? -1 : 1) || r1.title.localeCompare(r2.title));

                for (let resource of usersInTeam) {
                    resources.push({
                        id: resource.id,
                        order: ("000" + resources.length + 1).slice(-3),
                        title: resource.title + " (" + team + ")",
                        planningResource: resource,
                        groupName: resource.groupName
                    });
                }
            }

            //add suppliers
            let suppliers = planningResources.filter((value) => value.type === "Supplier");
            suppliers = suppliers.sort((r1, r2) => r1.title.localeCompare(r2.title));

            for (let resource of suppliers) {
                resources.push({
                    id: resource.code,
                    order: ("000" + resources.length + 1).slice(-3),
                    title: resource.title,
                    planningResource: resource,
                    groupName: resource.groupName
                });
            }

            this.setState({
                resources: resources,
                planningSlo: planningSlo
            });

            this.filterResources();

            let draggableEl = document.getElementById("external-events-projects");
            if (draggableEl !== null) {
                new Draggable(draggableEl, {
                    itemSelector: ".fc-event",
                    eventData: function (eventEl) {
                        let projectCode = eventEl.innerText;
                        let number = projectCode.slice(-2);
                        let backgroundColor = eventEl.getAttribute("data-backgroundcolor");
                        let textColor = eventEl.getAttribute("data-textcolor");
                        let classNames: any = [];
                        if (backgroundColor === null) {
                            classNames.push('project-' + number);
                        }
                        if (projectCode.startsWith("MKJ")) {
                            classNames.push("project-mkj");
                        }
                        return {
                            title: projectCode,
                            id: 'Project|' + projectCode,
                            duration: '12:00',
                            classNames: classNames,
                            backgroundColor: backgroundColor,
                            textColor: textColor
                        };
                    }
                });
            }

            draggableEl = document.getElementById("external-events-planningitemtypes");
            if (draggableEl !== null) {
                new Draggable(draggableEl, {
                    itemSelector: ".fc-event",
                    eventData: function (eventEl) {
                        let name = eventEl.innerText;
                        let id = eventEl.getAttribute("data-id");
                        let backgroundColor = eventEl.getAttribute("data-backgroundcolor");
                        let textColor = eventEl.getAttribute("data-textcolor");
                        let code = eventEl.getAttribute("data-code");
                        return {
                            title: name,
                            id: 'PlanningItemType|' + id,
                            duration: '12:00',
                            backgroundColor: backgroundColor,
                            textColor: textColor,
                            code: code
                        };
                    }
                });
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async handleDatesSet(dateInfo: any) {
        const selectedView = this.calendarRef.current?.getApi().view.type;
        if (selectedView !== undefined)
            localStorage.setItem("PTPlanSelectedView", selectedView);

        try {
            if (this.state.resources && this.state.resources.length > 0) {
                if (this.state.startStr !== dateInfo.startStr || this.state.endStr !== dateInfo.endStr) {
                    await this.loadEvents(dateInfo.startStr, dateInfo.endStr, this.props.selectedProjectPlanningItemType);
                }
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    async loadEvents(startStr: string, endStr: string, selectedProjectPlanningItemType: string | null) {

        let items = await new PlanningService(this.context.instance).getItems(startStr, endStr, selectedProjectPlanningItemType);

        let events = [];

        for (let item of items) {
            let projectCode = item.projectCode ?? "";
            let number = projectCode.slice(-2);

            let editable = true;
            if (this.state.planningSlo === true) {
                editable = false;
                if (item.displayName === "SLO") {
                    editable = true;
                    if (parseJSON(item.start) < addWeeks(new Date(), 3)) {
                        editable = false;
                    }
                }
            }

            let eventToAdd: any = {
                id: item.id,
                title: item.displayName,
                start: item.start,
                end: item.end,
                resourceId: item.personId ?? item.supplierCode,
                tooltip: item.tooltip,
                editable: editable,
                planningItem: item
            };

            eventToAdd.backgroundColor = item.backgroundColorCode;
            eventToAdd.textColor = item.textColorCode;

            if (item.projectCode && item.projectCode !== null && item.backgroundColorCode === null) {
                let classNames = [];
                classNames.push('project-' + number);
                if (item.projectCode.startsWith("MKJ")) {
                    classNames.push("project-mkj");
                }
                eventToAdd.classNames = classNames;
            }

            events.push(eventToAdd);
        }

        this.props.clearPlanningItemToDelete();

        this.setState({
            events: events,
            startStr: startStr,
            endStr: endStr
        });
    }

    async handleEventRecieve(info: any) {
        try {
            const id = info.event.id;
            const resources = info.event.getResources();
            if (resources.length === 0) {
                info.revert();
                return;
            }

            if (this.state.planningSlo === true) {
                if (info.event.start < addWeeks(new Date(), 3)) {
                    info.revert();
                    return;
                }
            }

            const resource: PlanningResourceModel = resources[0].extendedProps.planningResource;

            const newItem: PlanningItemModel = {
                id: -1,
                start: info.event.startStr,
                end: info.event.endStr
            };

            if (resource.type === "Person")
                newItem.personId = resource.id;
            else if (resource.type === "Supplier")
                newItem.supplierCode = resource.code;
            else {
                info.revert();
                return;
            }

            const idSplit = id.split('|');
            const type = idSplit[0];
            if (type === "Project")
                newItem.projectCode = idSplit[1];
            else if (type === "PlanningItemType") {
                newItem.planningItemTypeId = idSplit[1];
                const code = info.event.extendedProps.code;

                if (code === "BC") {
                    this.props.bcEventDropped(newItem);
                    info.event.remove();
                    return;
                }

                if (code === "VOORLOPIG") {
                    this.props.descriptionEventDropped(newItem);
                    info.event.remove();
                    return;
                }
            }

            const items = await new PlanningService(this.context.instance).upsertItem(newItem, this.state.planningSlo ?? false);

            info.event.remove();

            this.addItemsToCalendar(items);
        }
        catch (err: any) {
            toast.error('Fout bij het bewaren. ' + err.message);
            info.event.remove();
        }
    }

    async handleEventDrop(info: any) {
        try {
            let resources = info.event.getResources();
            if (resources.length === 0) {
                info.revert();
                return
            }

            if (this.state.planningSlo === true) {
                if (info.event.title !== "SLO" || info.event.start < addWeeks(new Date(), 3)) {
                    info.revert();
                    return
                }
            }

            let resource: PlanningResourceModel = resources[0].extendedProps.planningResource;

            let updateItem: PlanningItemModel = {
                id: info.event.id,
                start: info.event.startStr,
                end: info.event.endStr
            };

            if (resource.type === "Person") {
                updateItem.personId = resource.id;
                updateItem.supplierCode = null;
            }
            else if (resource.type === "Supplier") {
                updateItem.supplierCode = resource.code;
                updateItem.personId = null;
            }
            else {
                info.revert();
                return;
            }

            let items = await new PlanningService(this.context.instance).upsertItem(updateItem, this.state.planningSlo ?? false);

            this.addItemsToCalendar(items);
        }
        catch (err: any) {
            toast.error('Fout bij het bewaren. ' + err.message);
            info.revert();
        }
    }

    async handleEventResize(info: any) {
        try {
            let updateItem: PlanningItemModel = {
                id: info.event.id,
                start: info.event.startStr,
                end: info.event.endStr
            };


            let items = await new PlanningService(this.context.instance).upsertItem(updateItem, this.state.planningSlo ?? false);

            this.addItemsToCalendar(items);
        }
        catch (err: any) {
            toast.error('Fout bij het bewaren. ' + err.message);
            info.revert();
        }
    }

    async handleEventDragStop(info: any) {
        try {
            if (this.props.trashRef.current !== null) {

                let trashOffset = this.props.trashRef.current.getBoundingClientRect();

                let x1 = trashOffset.left;
                let x2 = trashOffset.left + trashOffset.width;
                let y1 = trashOffset.top;
                let y2 = trashOffset.top + trashOffset.height;

                if (info.jsEvent.pageX >= x1 && info.jsEvent.pageX <= x2 &&
                    info.jsEvent.pageY >= y1 && info.jsEvent.pageY <= y2) {

                    if (this.state.planningSlo === true) {
                        if (info.event.title !== "SLO" || info.event.start < addWeeks(new Date(), 3)) {
                            return;
                        }
                    }

                    info.event.remove();

                    await new PlanningService(this.context.instance).deleteItem(info.event.id, this.state.planningSlo ?? false);
                }
            }
        }
        catch (err) {
            toast.error('Fout bij het verwijderen.');
        }
    }

    handleEventClick(info: any) {
        info.jsEvent.preventDefault();

        if (this.state.planningSlo === true && info.event.title !== "SLO")
            return;

        if (info.event.extendedProps.planningItem.planningItemTypeCode === "VOORLOPIG") {
            this.props.descriptionEventClicked(info.event.extendedProps.planningItem);
            return;
        }

        let id = info.event.id;

        const shiftKey = info.jsEvent.shiftKey;

        if (shiftKey === true && (this.state.lastClickedEvent !== undefined || this.state.lastClickedEvent !== null)) {
            const currEventResources = info.event.getResources();
            const lastEventResources = this.state.lastClickedEvent.getResources();
            if (currEventResources.length !== 0 && lastEventResources.length !== 0) {
                if (currEventResources[0].id === lastEventResources[0].id) {
                    if (this.calendarRef.current !== null) {
                        const calendarApi = this.calendarRef.current.getApi();
                        let events = calendarApi.getEvents();
                        events = events.filter(e => e.getResources().find(r => r?.id === currEventResources[0].id));
                        const startDate = info.event.start < this.state.lastClickedEvent.start ? info.event.start : this.state.lastClickedEvent.start;
                        const endDate = info.event.end > this.state.lastClickedEvent.end ? info.event.end : this.state.lastClickedEvent.end;
                        events = events.filter(e => e.start !== null && e.end !== null && e.start >= startDate && e.end <= endDate);

                        events.forEach((e) => {
                            let added = this.props.planningItemClick(e.id);

                            if (!added) {
                                added = this.props.planningItemClick(e.id);
                            }

                            e.setProp("borderColor", "red");
                        });
                    }
                }
            }
        }
        else {
            let added = this.props.planningItemClick(id);

            if (added) {
                info.el.style.borderColor = 'red';
            }
            else {
                info.el.style.borderColor = '#3788d8';
            }
        }

        this.setState({ lastClickedEvent: info.event });
    }

    addItemsToCalendar(items: PlanningItemModel[]) {
        if (this.calendarRef.current !== null) {
            let calendarApi = this.calendarRef.current.getApi();
            items.forEach(item => {
                const event = calendarApi.getEventById('' + item.id);

                if (event) {
                    event.setDates(item.start, item.end);
                } else {
                    let projectCode = item.projectCode ?? "";
                    let number = projectCode.slice(-2);

                    const eventToAdd: any = {
                        id: '' + item.id,
                        title: item.displayName ?? '',
                        start: item.start,
                        end: item.end,
                        resourceId: item.personId ?? item.supplierCode ?? '',
                        tooltip: item.tooltip,
                        planningItem: item
                    };

                    eventToAdd.backgroundColor = item.backgroundColorCode;
                    eventToAdd.textColor = item.textColorCode;

                    if (item.projectCode && item.projectCode !== null && item.backgroundColorCode === null) {
                        let classNames = [];
                        classNames.push('project-' + number);
                        if (item.projectCode.startsWith("MKJ")) {
                            classNames.push("project-mkj");
                        }
                        eventToAdd.classNames = classNames;
                    }

                    calendarApi.addEvent(eventToAdd, true);
                }
            });
        }
    }

    removeEvents(planningItemsToDelete: number[]) {
        if (this.calendarRef.current !== null) {
            let calendarApi = this.calendarRef.current.getApi();
            planningItemsToDelete.forEach(itemId => {
                var event = calendarApi.getEventById('' + itemId);

                if (event) {
                    event.remove();
                }
            });
        }
    }

    async addEvent(item: PlanningItemModel, refreshAll: boolean) {
        const items = await new PlanningService(this.context.instance).upsertItem(item, this.state.planningSlo ?? false);

        if (refreshAll) {
            await this.loadEvents(this.state.startStr, this.state.endStr, this.props.selectedProjectPlanningItemType);
        }
        else {

            this.removeEvents([item.id]);
            this.addItemsToCalendar(items);
        }
    }

    shouldComponentUpdate(nextProps: PlanningCalendarProps, nextState: PlanningCalendarState) {
        if (nextState.resources.length !== this.state.resources.length)
            return true;
        if (nextState.filteredResources.length !== this.state.filteredResources.length)
            return true;
        if (nextState.events.length !== this.state.events.length)
            return true;
        if (nextState.endStr !== this.state.endStr)
            return true;
        if (nextState.startStr !== this.state.startStr)
            return true;
        if (nextProps.selectedProjectPlanningItemType !== this.props.selectedProjectPlanningItemType)
            return true;
        if (JSON.stringify(nextProps.groupsFilter) !== JSON.stringify(this.props.groupsFilter))
            return true;
        if (JSON.stringify(nextProps.resourcesFilter) !== JSON.stringify(this.props.resourcesFilter))
            return true;
        return false;
    }

    async componentDidUpdate(prevProps: PlanningCalendarProps, prevState: PlanningCalendarState) {
        if (prevProps.selectedProjectPlanningItemType !== this.props.selectedProjectPlanningItemType) {
            await this.loadEvents(this.state.startStr, this.state.endStr, this.props.selectedProjectPlanningItemType);
        }

        if (JSON.stringify(prevProps.groupsFilter) !== JSON.stringify(this.props.groupsFilter)) {
            this.filterResources();
        }

        if (JSON.stringify(prevProps.resourcesFilter) !== JSON.stringify(this.props.resourcesFilter)) {
            this.filterResources();
        }

        if (JSON.stringify(prevState.resources) !== JSON.stringify(this.state.resources)) {
            this.filterResources();
        }
    }

    filterResources() {
        let filteredResource = this.state.resources;

        if (this.props.groupsFilter.length > 0) {
            filteredResource = this.state.resources.filter(group => this.props.groupsFilter.indexOf(group.groupName) >= 0);
        }

        if (this.props.resourcesFilter.length > 0) {
            filteredResource = this.state.resources.filter(res => this.props.resourcesFilter.indexOf(res.id) >= 0);
        }

        this.setState({
            filteredResources: filteredResource,
        });
    }

    render() {
        const now = new Date();

        const initialView = localStorage.getItem("PTPlanSelectedView") ?? "resourceTimeline2Weeks";

        return (
            <FullCalendar
                ref={this.calendarRef}
                schedulerLicenseKey="0218345685-fcs-1610958856"
                plugins={[resourceTimelinePlugin, interactionPlugin, bootstrapPlugin]}
                editable={true}
                selectable={true}
                droppable={true}
                themeSystem="bootstrap"
                initialView={initialView}
                height='100%'
                locale={nlLocale}
                now={now}
                weekends={true}
                eventOverlap={false}
                filterResourcesWithEvents={this.props.selectedProjectPlanningItemType !== null}
                resourceOrder="groupName,order"
                resourceGroupField="groupName"
                headerToolbar={{
                    start: 'today prev,next',
                    center: 'title',
                    end: 'resourceTimeline2Weeks,resourceTimelineMonthCustom,resourceTimelineYear'
                }}
                resourceAreaColumns={[
                    {
                        field: 'title',
                        headerContent: ''
                    }
                ]}
                views={
                    {
                        resourceTimeline2Weeks: {
                            type: 'resourceTimeline',
                            buttonText: '2 Weken',
                            duration: { weeks: 2 },
                            dateIncrement: { weeks: 1 },
                            slotMinWidth: 70,
                            slotDuration: { hours: 12 },
                            resourceAreaWidth: '10%',
                            slotLabelFormat: [
                                { month: 'short' },
                                { week: 'short' },
                                { weekday: 'short', day: '2-digit' },
                                { hour12: false, hour: 'numeric', omitCommas: true }
                            ],
                            nowIndicator: true,
                            scrollTime: { years: 0, months: 0, days: 0 }
                        },
                        resourceTimelineMonthCustom: {
                            type: 'resourceTimeline',
                            buttonText: 'Maand',
                            duration: { months: 1 },
                            slotMinWidth: 70,
                            slotDuration: { hours: 12 },
                            resourceAreaWidth: '10%',
                            slotLabelFormat: [
                                { month: 'short' },
                                { week: 'short' },
                                { weekday: 'short', day: '2-digit' },
                                { hour12: false, hour: 'numeric', omitCommas: true }
                            ],
                            nowIndicator: true,
                            scrollTime: { years: 0, months: 0, days: now.getDate() - 1 }
                        },
                        resourceTimelineYear: {
                            type: 'resourceTimeline',
                            slotMinWidth: 70,
                            duration: { years: 1 },
                            slotDuration: { hours: 12 },
                            resourceAreaWidth: '10%',
                            slotLabelFormat: [
                                { month: 'short' },
                                { week: 'short' },
                                { weekday: 'short', day: '2-digit' },
                                { hour12: false, hour: 'numeric', omitCommas: true }
                            ],
                            nowIndicator: true,
                            scrollTime: { years: 0, months: now.getMonth(), days: now.getDate() - 1 }
                        }
                    }
                }
                resources={this.state.filteredResources}
                events={this.state.events}
                datesSet={this.handleDatesSet}
                eventReceive={this.handleEventRecieve}
                eventDrop={this.handleEventDrop}
                eventResize={this.handleEventResize}
                eventDragStop={this.handleEventDragStop}
                slotLabelContent={this.getSlotLabelContent}
                resourceLabelClassNames={this.getResourceLabelClassNames}
                eventDidMount={this.eventDidMount}
                eventClick={this.handleEventClick}
                dragRevertDuration={0}
            />
        );
    }

    getSlotLabelContent(args: SlotLabelContentArg) {
        if (args.level === 3) {
            args.text = args.text.replace('00', 'VM').replace('12', 'NM');
        }
    }

    getResourceLabelClassNames(args: ResourceLabelContentArg) {
        if (args.resource.extendedProps.planningResource.isForeman === true)
            return 'resource_foreman';

        if (args.resource.extendedProps.planningResource.type === "Supplier")
            return 'resource_supplier';

        return '';
    }

    eventDidMount(args: EventMountArg) {
        args.el.title = args.event.extendedProps.tooltip;
    }
}
