import React, { useEffect, useMemo, useState } from 'react';
import MandatoryWorkingWeekModel from '../Models/MandatoryWorkingWeekModel';
import MandatoryWorkingWeekModal from '../Components/MandatoryWorkingWeekModal';
import MandatoryWorkingWeeksService from '../Services/MandatoryWorkingWeeksService';
import PeopleService from '../Services/PeopleService';
import PersonModel from '../Models/PersonModel';
import { format, parseJSON } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const MandatoryWorkingWeeks = () => {

    const { instance } = useMsal();

    const peopleService = useMemo(() => new PeopleService(instance), [instance]);
    const mandatoryWorkingWeeksService = useMemo(() => new MandatoryWorkingWeeksService(instance), [instance]);

    const [mandatoryWorkingWeeks, setMandatoryWorkingWeeks] = useState<MandatoryWorkingWeekModel[]>([]);
    const [filteredMandatoryWorkingWeeks, setFilteredMandatoryWorkingWeeks] = useState<MandatoryWorkingWeekModel[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedMandatoryWorkingWeek, setSelectedMandatoryWorkingWeek] = useState<MandatoryWorkingWeekModel | null>(null);
    const [people, setPeople] = useState<PersonModel[]>([]);
    const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);

    useEffect(() => {
        async function loadData() {
            try {
                const data = await mandatoryWorkingWeeksService.getAllMandatoryWorkingWeeks();

                let people = await peopleService.getAllFuncUsers();

                people = people.filter(person => person.showInSchedule === true);

                people = people.sort((r1, r2) => {
                    return (r1.name ?? "").localeCompare(r2.name ?? "");
                });

                setPeople(people);
                setMandatoryWorkingWeeks(data);
                setFilteredMandatoryWorkingWeeks(data);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [mandatoryWorkingWeeksService, peopleService]);

    useEffect(() => {
        let mandatoryWorkingWeeksFiltered = [...mandatoryWorkingWeeks];
        if (selectedPersonId !== null && selectedPersonId !== "") {
            mandatoryWorkingWeeksFiltered = mandatoryWorkingWeeksFiltered.filter(r => r.personId === selectedPersonId);
        }
        setFilteredMandatoryWorkingWeeks(mandatoryWorkingWeeksFiltered);
    }, [selectedPersonId, mandatoryWorkingWeeks]);

    async function mandatoryWorkingWeekModalAfterSave() {
        try {
            setShowModal(false);

            const data = await mandatoryWorkingWeeksService.getAllMandatoryWorkingWeeks();
            setMandatoryWorkingWeeks(data);

            let mandatoryWorkingWeeksFiltered = [...data];
            if (selectedPersonId !== null && selectedPersonId !== "") {
                mandatoryWorkingWeeksFiltered = mandatoryWorkingWeeksFiltered.filter(r => r.personId === selectedPersonId);
            }
            setFilteredMandatoryWorkingWeeks(mandatoryWorkingWeeksFiltered);
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function mandatoryWorkingWeekModalAfterClose() {
        setShowModal(false);
    }

    let modal = null;
    if (showModal) {
        modal =
            <MandatoryWorkingWeekModal
                mandatoryWorkingWeek={selectedMandatoryWorkingWeek}
                people={people}
                afterSave={() => mandatoryWorkingWeekModalAfterSave()}
                afterCancel={() => mandatoryWorkingWeekModalAfterClose()}
            />
    }

    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-1 col-form-label" htmlFor="personSelect">Persoon</label>
                        <div className="col-2">
                            <select className="form-control" id="personSelect" onChange={(event) => setSelectedPersonId(event.target.value)}>
                                <option value="">- Kies een persoon -</option>
                                {
                                    people.map((person) => {
                                        return (
                                            <option value={person.id}>{person.name}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    return (
        <div className="container-fluid">
            {filters}
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Persoon</th>
                        <th>Van</th>
                        <th>Tot en met</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredMandatoryWorkingWeeks.map((record) => {
                            return (
                                <tr key={record.id}>
                                    <td>{record.personName}</td>
                                    <td>{format(parseJSON(record.start), "dd/MM/yyyy")}</td>
                                    <td>{format(parseJSON(record.end), "dd/MM/yyyy")}</td>
                                    <td><button type='button' className="btn btn-link p-0" title="Aanpassen"
                                        onClick={() => { setShowModal(true); setSelectedMandatoryWorkingWeek(record); }}>
                                        <i className="fas fa-pen"></i>
                                    </button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="row m-4">
                &nbsp;
            </div>
            <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                <button className="btn btn-primary" type="button"
                    onClick={() => { setShowModal(true); setSelectedMandatoryWorkingWeek(null); }}>Toevoegen</button>
            </div>
            {modal}
        </div >
    );


}

export default MandatoryWorkingWeeks;