import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Checkbox from "../Components/Checkbox";
import ProjectsService from '../Services/ProjectsService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

interface ProjectCheckedModalProps {
    code: string;
    checked: boolean;
    comments: string;
    afterSave: Function;
    afterCancel: Function;
}

const ProjectCheckedModal = (props: ProjectCheckedModalProps) => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [checked, setChecked] = useState<boolean>(props.checked);
    const [comments, setComments] = useState<string>(props.comments);
    const [visible, setVisible] = useState<boolean>(true);

    function handleProjectCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.checked;

        setChecked(value);
    }

    function handleProjectCheckedCommentsChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setComments(event.target.value);
    }

    function projectCheckedModalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function projectCheckedModalSave() {
        try {
            if (props.code !== null) {
                await projectsService.updateChecked(props.code, checked, comments);

                setVisible(false);
                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    return (
        <Modal isOpen={visible} toggle={projectCheckedModalToggle} className="mw-100 w-75">
            <ModalHeader toggle={projectCheckedModalToggle}>{props.code} - Controle</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">

                        <Checkbox className="my-1 mr-sm-2" label="Gecontroleerd" value="true" checked={checked}
                            onChange={handleProjectCheckedChange} />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">Opmerkingen</label>
                        <textarea className="form-control my-1 mr-sm-2" title="Opmerkingen" style={{ "height": "200px" }}
                            onChange={handleProjectCheckedCommentsChange}
                            value={comments} ></textarea>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={projectCheckedModalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={projectCheckedModalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ProjectCheckedModal;