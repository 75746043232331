import { useCallback, useEffect, useMemo, useState } from "react";
import PlanningService from "../Services/PlanningService";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import GraphService from "../Services/GraphService";
import { PersonLeaveDaysModel } from "../Models/PersonLeaveDaysModel";
import LeaveRequestService from "../Services/LeaveRequestService";
import LeaveRequestModel from "../Models/LeaveRequestModel";
import { Button } from "reactstrap";
import { format, parseJSON } from "date-fns";
import { User } from "@microsoft/microsoft-graph-types";
import LeaveRequestModal from "../Components/LeaveRequestModal";

const Leave = () => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);
    const leaveRequesService = useMemo(() => new LeaveRequestService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);

    const [leaveDays, setLeaveDays] = useState<PersonLeaveDaysModel[]>([]);
    const [leaveRequests, setLeaveRequests] = useState<LeaveRequestModel[]>([]);
    const [currentUser, setCurrentUser] = useState<User>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedLeaveRequest, setSelectedLeaveRequest] = useState<LeaveRequestModel>();

    const loadLeaveRequests = useCallback(
        async () => {
            if (currentUser?.id === undefined)
                return;

            const year = (new Date()).getFullYear();

            const leaveDays = await planningService.getLeaveDays(year, currentUser.id);
            const leaveRequests = await leaveRequesService.get(year, currentUser.id, null);

            setLeaveDays(leaveDays);
            setLeaveRequests(leaveRequests);
        },
        [planningService, leaveRequesService, currentUser]
    );


    useEffect(() => {
        async function loadData() {
            try {
                if (currentUser !== undefined)
                    return;

                const user = await graphService.getUserDetails();
                setCurrentUser(user);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [graphService, currentUser]);

    useEffect(() => {
        async function loadData() {
            try {
                await loadLeaveRequests();
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [loadLeaveRequests, currentUser]);

    async function afterSave() {
        try {
            setShowModal(false);

            await loadLeaveRequests();
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function afterCancel() {
        setShowModal(false);
    }

    let modal = null;
    if (showModal && selectedLeaveRequest) {
        modal =
            <LeaveRequestModal
                leaveRequest={selectedLeaveRequest}
                afterSave={() => afterSave()}
                afterCancel={() => afterCancel()}
            />
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row" title="Resterend verlof">
                                <div className="col col-lg-4">
                                    <strong>Verlof: </strong>
                                </div>
                                <div className="col">
                                    {leaveDays?.find(l => l.description === "V")?.count} / {leaveDays?.find(l => l.description === "V (totaal)")?.count}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-4">
                                    <strong>Afwezigheden: </strong>
                                </div>
                                <div className="col">
                                    {leaveDays?.filter(l => l.description === "Z" || l.description === "FV" || l.description === "KV" || l.description === "TA").reduce((n, { count }) => n + count, 0) ?? 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col">
                                    <Button color="primary"
                                        onClick={() => {
                                            setShowModal(true); setSelectedLeaveRequest({
                                                status: "Nieuw",
                                                personId: currentUser?.id,
                                                startDate: format(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z",
                                                endDate: format(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z"
                                            });
                                        }}
                                    >Nieuwe verlofaanvraag</Button>
                                </div>
                            </div>
                            {leaveRequests.map(leaverequest =>

                                <div className="row" key={leaverequest.id}>
                                    <div className="col-12 col-xl-6">
                                        <strong>{leaverequest.startDate !== undefined ? format(parseJSON(leaverequest.startDate), "dd/MM/yyyy") : ""} - {leaverequest.endDate !== undefined ? format(parseJSON(leaverequest.endDate), "dd/MM/yyyy") : ""}</strong>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        {leaverequest.status}
                                    </div>
                                </div>

                            )}
                        </div>
                    </div>
                </div>
            </div>


            {modal}
        </div>
    );

}

export default (Leave);