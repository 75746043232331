import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parseJSON } from 'date-fns';
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import PeopleService from '../Services/PeopleService';
import PersonModel from '../Models/PersonModel';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import TaskModel from '../Models/TaskModel';
import TasksService from '../Services/TasksService';
import PlanningService from '../Services/PlanningService';
import ProjectModel from '../Models/ProjectModel';


interface TaskModalProps {
    task: TaskModel;
    afterSave: Function;
    afterCancel: Function;
}

const TaskModal = (props: TaskModalProps) => {

    const { instance } = useMsal();

    const [visible, setVisible] = useState<boolean>(true);
    const [task, setTask] = useState<TaskModel>(props.task);
    const [people, setPeople] = useState<PersonModel[]>([]);
    const [projects, setProjects] = useState<ProjectModel[]>([]);

    const taskService = useMemo(() => new TasksService(instance), [instance]);
    const peopleService = useMemo(() => new PeopleService(instance), [instance]);
    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    registerLocale('nlBE', nlBE);

    useEffect(() => {

        const fetchData = async () => {
            const projects = await planningService.getProjects();

            let people = await peopleService.getAllFuncUsers();
            people = people.filter(person => person.showInSchedule === true);
            people = people.sort((r1, r2) => {
                return (r1.name ?? "").localeCompare(r2.name ?? "");
            });

            setPeople(people);
            setProjects(projects);
        }

        fetchData();
    }, [props, peopleService, planningService])

    const modalToggle = () => {
        setVisible((visible) => { return !visible })

        props.afterCancel();
    }

    const modalSave = async () => {
        try {
            await taskService.upsert(task);

            setVisible(false);
            props.afterSave();
        } catch (error) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    if (projects.length === 0 || people.length === 0)
        return null;

    return (<>
        <Modal isOpen={visible} toggle={modalToggle} >
            <ModalHeader toggle={modalToggle}>Task</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <label className="my-1 mr-2">Datum</label>

                        <DatePicker locale="nlBE"
                            selected={task.date === undefined ? new Date() : parseJSON(task.date)}
                            onChange={(date) => setTask({ ...task, date: date === null ? undefined : format(date, "yyyy-MM-dd") + "T00:00:00.000Z" })}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label className="my-1 mr-2">Taak</label>
                        <textarea className="form-control my-1 mr-sm-2" title="Taak" style={{ "height": "200px" }}
                            onChange={(event) => setTask({ ...task, description: event.target.value })}
                            value={task?.description} ></textarea>
                    </div>
                    <div className="form-group">
                        <label className="" htmlFor="personSelect">Project</label>
                        <select className="form-control" id="projectSelect" defaultValue={task.projectCode ?? ""}
                            onChange={(event) => setTask({ ...task, projectCode: event.target.value })} >
                            {
                                projects.map((project) => {
                                    return (
                                        <option value={project.code} key={project.code}>{project.code} {project.name}</option>
                                    );
                                })
                            }
                        </select>

                    </div>
                    <div className="form-group">
                        <label className="" htmlFor="personSelect">Persoon</label>
                        <select className="form-control" id="personSelect" defaultValue={task.personId ?? ""}
                            onChange={(event) => setTask({ ...task, personId: event.target.value })} >
                            {
                                people.map((person) => {
                                    return (
                                        <option value={person.id} key={person.id}>{person.name}</option>
                                    );
                                })
                            }
                        </select>

                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal >
    </>
    );

}

export default TaskModal;