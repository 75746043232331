
import React, { useEffect, useMemo, useState } from 'react';
import SuccessToast from '../Components/SuccessToast';
import { PlanningItemTypeModel } from '../Models/PlanningItemTypeModel';
import PlanningItemTypesService from '../Services/PlanningItemTypesService';
import { ColorResult, CompactPicker } from 'react-color';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const PlanningItemTypesConfiguration = () => {

    const { instance } = useMsal();

    const planningItemTypesService = useMemo(() => new PlanningItemTypesService(instance), [instance]);

    const [planningItemTypes, setPlanningItemTypes] = useState<PlanningItemTypeModel[]>([]);
    const [formTouchedOrDirty, setFormTouchedOrDirty] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    useEffect(() => {
        async function loadData() {
            try {
                let planningItemTypes = await planningItemTypesService.getAllPlanningItemTypes();
                setNewSate(planningItemTypes, false);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [planningItemTypesService]);


    function setNewSate(planningItemTypes: PlanningItemTypeModel[], showSuccessMessage: boolean) {
        const emptyPlanningItemType: PlanningItemTypeModel = {};
        const newPlanningItemTypes = [...planningItemTypes];
        newPlanningItemTypes.push(emptyPlanningItemType);
        setPlanningItemTypes(newPlanningItemTypes);
        if (showSuccessMessage) {
            setShowSuccessMessage(true);
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        let updatedPlanningItemTypes = [...planningItemTypes];
        updatedPlanningItemTypes.forEach((planningItemType: PlanningItemTypeModel) => {
            let target = event.target;
            let id = Number(target.getAttribute("data-id"));
            if (planningItemType.id === id || (!id && !planningItemType.id)) {
                switch (target.name) {
                    case "code": planningItemType.code = target.value; break;
                    case "description": planningItemType.description = target.value; break;
                    case "showInLeaves": planningItemType.showInLeaves = target.checked; break;
                    case "textColorcode": planningItemType.textColorCode = target.value; break
                    case "backgroundColorcode": planningItemType.backgroundColorCode = target.value; break;
                    case "wageCode": planningItemType.wageCode = target.value; break;
                    case "sendMailToPerson": planningItemType.sendMailToPerson = target.checked; break;
                }
            }
        })
        setPlanningItemTypes(updatedPlanningItemTypes);
        setFormTouchedOrDirty(true);
    }

    function handleBgColorChange(color: ColorResult, id?: number) {

        let updatedPlanningItemTypes = [...planningItemTypes];
        updatedPlanningItemTypes.forEach((planningItemType: PlanningItemTypeModel) => {
            if (planningItemType.id === id || (!id && !planningItemType.id)) {
                planningItemType.backgroundColorCode = color.hex;
            }
        })
        setPlanningItemTypes(updatedPlanningItemTypes);
        setFormTouchedOrDirty(true);
    }

    function handleTextColorChange(color: ColorResult, id?: number) {

        let updatedPlanningItemTypes = planningItemTypes;
        updatedPlanningItemTypes.forEach((planningItemType: PlanningItemTypeModel) => {
            if (planningItemType.id === id || (!id && !planningItemType.id)) {
                planningItemType.textColorCode = color.hex;
            }
        })
        setPlanningItemTypes(updatedPlanningItemTypes);
        setFormTouchedOrDirty(true);
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            if (formTouchedOrDirty) {
                let updatedPlanningItemTypes = await planningItemTypesService.updatePlanningItemTypes(planningItemTypes);
                setNewSate(updatedPlanningItemTypes, true);
            }
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    function toggleSuccessMessage(shown: boolean) {
        setShowSuccessMessage(shown);
    }


    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                    <button className="btn btn-primary" type="submit">Bewaar wijzigingen</button>
                </div>
                <table className="table table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th className="text-center">Code</th>
                            <th className="text-center">Omschrijving</th>
                            <th className="text-center">Tonen in verloftellers</th>
                            <th className="text-center">Kleurcode tekst</th>
                            <th className="text-center">Kleurcode achtergrond</th>
                            <th className="text-center">Looncode</th>
                            <th className="text-center">Verzend mail naar<br />personeel bij inplannen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            planningItemTypes.map((record: PlanningItemTypeModel) => {
                                return (
                                    <tr key={record.id} data-id={record.id}>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" placeholder='Code' className="form-control form-control-sm"
                                                style={{ border: 'none', backgroundColor: record.backgroundColorCode, color: record.textColorCode, fontWeight: 'bold', textAlign: 'center' }}
                                                data-id={record.id} name="code" value={record.code ?? ""}></input>
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-id={record.id} placeholder='Omschrijving' name="description"
                                                value={record.description ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={record.id + "showInLeaves"}
                                                    onChange={handleChange} data-id={record.id} name="showInLeaves" checked={record.showInLeaves} />
                                                <label className="form-check-label" htmlFor={record.id + "showInLeaves"}></label>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            <CompactPicker color={record.textColorCode ?? "#000000"} onChangeComplete={(color) => handleTextColorChange(color, record.id)} />
                                        </td>
                                        <td className="text-center">
                                            <CompactPicker color={record.backgroundColorCode ?? "#ffffff"} onChangeComplete={(color) => handleBgColorChange(color, record.id)} />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-id={record.id} placeholder='Looncode'
                                                name="wageCode" value={record.wageCode ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={record.id + "sendMailToPerson"}
                                                    onChange={handleChange} data-id={record.id} name="sendMailToPerson" checked={record.sendMailToPerson} />
                                                <label className="form-check-label" htmlFor={record.id + "sendMailToPerson"}></label>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <div className="row m-4">
                    &nbsp;
                </div>
            </form>
            <SuccessToast show={showSuccessMessage} toggle={toggleSuccessMessage} />
        </div>
    )

}
export default (PlanningItemTypesConfiguration);