import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReminderService from '../Services/ReminderService';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parseJSON } from 'date-fns';
import ReminderModel from '../Models/ReminderModel';
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import GraphService from '../Services/GraphService';
import PeopleService from '../Services/PeopleService';
import PersonModel from '../Models/PersonModel';
import { useMsal } from '@azure/msal-react';
import { User } from '@microsoft/microsoft-graph-types';
import { toast } from 'react-toastify';


interface RemindersModalProps {
    code: string;
    afterClose: Function;
}

const RemindersModal = (props: RemindersModalProps) => {

    const { instance } = useMsal();

    const [visible, setVisible] = useState<boolean>(true);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [reminders, setReminders] = useState<ReminderModel[]>([]);
    const [reminder, setReminder] = useState<ReminderModel>({});
    const [currentUser, setCurrentUser] = useState<User>();
    const [people, setPeople] = useState<PersonModel[]>([]);

    const reminderService = useMemo(() => new ReminderService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const peopleService = useMemo(() => new PeopleService(instance), [instance]);

    registerLocale('nlBE', nlBE);

    useEffect(() => {

        const fetchData = async () => {
            const reminders = await reminderService.get(props.code);
            setReminders(reminders);

            const user = await graphService.getUserDetails();
            setCurrentUser(user);

            let people = await peopleService.getOwners();

            people = people.sort((r1, r2) => {
                return (r1.name ?? "").localeCompare(r2.name ?? "");
            });

            setPeople(people);
        }

        fetchData();
    }, [props, props.code, reminderService, graphService, peopleService])

    const modalToggle = () => {
        setVisible((visible) => { return !visible })

        props.afterClose();
    }

    const saveReminder = async () => {
        try {
            await reminderService.upsert(reminder);
            const reminders = await reminderService.get(props.code);
            setReminders(reminders);
        } catch (error) {
            toast.error('Fout bij het ophalen van de data.');
        }

        setShowDetail(false);
    }

    const renderDetailModal = () => {
        return <>
            <Modal isOpen={showDetail} toggle={() => setShowDetail(false)} >
                <ModalHeader toggle={() => setShowDetail(false)}>Reminder</ModalHeader>
                <ModalBody>
                    <form >
                        <div className="form-group">
                            <label className="my-1 mr-2">Datum</label>

                            <DatePicker locale="nlBE"
                                selected={reminder.date === undefined ? new Date() : parseJSON(reminder.date)}
                                onChange={(date) => setReminder({ ...reminder, date: date === null ? undefined : format(date, "yyyy-MM-dd") + "T00:00:00.000Z" })}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                    />
                                }
                            />
                        </div>
                        <div className="form-group">
                            <label className="my-1 mr-2">Reminder</label>
                            <textarea className="form-control my-1 mr-sm-2" title="Opmerkingen" style={{ "height": "200px" }}
                                onChange={(event) => setReminder({ ...reminder, description: event.target.value })}
                                value={reminder?.description} ></textarea>
                        </div>
                        <div className="form-group">
                            <label className="" htmlFor="personSelect">Persoon</label>
                            <select className="form-control" id="personSelect" defaultValue={reminder.personId ?? ""}
                                onChange={(event) => setReminder({ ...reminder, personId: event.target.value })} >
                                {
                                    people.map((person) => {
                                        return (
                                            <option value={person.id} key={person.id}>{person.name}</option>
                                        );
                                    })
                                }
                            </select>

                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => saveReminder()}>Bewaren</Button>{' '}
                    <Button color="secondary" onClick={() => setShowDetail(false)}>Sluiten</Button>
                </ModalFooter>
            </Modal >
        </>;
    }

    return (<>
        <Modal isOpen={visible} toggle={() => modalToggle()} className="mw-100 w-75">
            <ModalHeader toggle={() => modalToggle()}>{props.code} - Reminders</ModalHeader>
            <ModalBody>
                <table className="table table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Reminder</th>
                            <th>Persoon</th>
                            <th>Verwerkt</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reminders?.map((record, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{record.date === undefined ? null : format(parseJSON(record.date), "dd/MM/yyyy")}</td>
                                        <td>{record.description}</td>
                                        <td>{record.personName}</td>
                                        <td>{record.completed}</td>
                                        <td>
                                            <button type='button' className="btn btn-link p-0" onClick={() => {
                                                setReminder(record);
                                                setShowDetail(true);
                                            }}>
                                                <i className="fas fa-pen"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    setReminder({
                        projectCode: props.code,
                        completed: false,
                        personId: currentUser?.id,
                        date: format(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z"
                    }); setShowDetail(true);
                }}>Nieuwe reminder</Button>{' '}
                <Button color="secondary" onClick={() => modalToggle()}>Sluiten</Button>
            </ModalFooter>
        </Modal>
        {showDetail && renderDetailModal()}
    </>
    );

}

export default RemindersModal;