import { PersonLeaveDaysModel } from "../Models/PersonLeaveDaysModel";
import { PlanningItemModel } from "../Models/PlanningItemModel";
import { PlanningResourceModel } from "../Models/PlanningResourceModel";
import ProjectModel from "../Models/ProjectModel";
import PersonLeaveModel from "../Models/PersonLeaveModel";
import { format } from "date-fns";
import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import CollectivePlanningItemModel from "../Models/CollectivePlanningItemModel";

export default class PlanningService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getResources(): Promise<PlanningResourceModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let resources = await fetch(baseUrl + '/api/PlanningResources', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return resources;
    }

    public async upsertItem(item: PlanningItemModel, planningSLO: boolean): Promise<PlanningItemModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let items = await fetch(baseUrl + `/api/PlanningItem?planningSLO=${planningSLO}`, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            }),
            body: JSON.stringify(item)
        }).then((response) => {
            return response.json();
        });

        if (items.error) {
            throw new Error(items.error);
        }

        return items;
    }

    public async getItems(startStr: string, endStr: string, selectedProjectPlanningItemType: string | null): Promise<PlanningItemModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let url = baseUrl + '/api/PlanningItem?start=' + encodeURIComponent(startStr) + "&end=" + encodeURIComponent(endStr);
        if (selectedProjectPlanningItemType !== null) {
            url += "&filter=" + encodeURIComponent(selectedProjectPlanningItemType);
        }

        let items = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return items;
    }

    public async deleteItem(id: string, planningSLO: boolean) {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + `/api/PlanningItem?id=${id}&planningSLO=${planningSLO}`, {
            method: 'delete',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async deleteItems(idList: number[], planningSLO: boolean) {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + `/api/PlanningItem?id=${idList.toString()}&planningSLO=${planningSLO}`, {
            method: 'delete',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async getProjects(): Promise<ProjectModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let projects = await fetch(baseUrl + '/api/ProjectsForPlanning', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return projects;
    }

    public async getPeopleLeaveDays(year: number): Promise<PersonLeaveDaysModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let items = await fetch(baseUrl + '/api/PeopleLeaveCounters?year=' + year, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return items;
    }

    public async getPeopleLeaveDaysDetails(year: number, personId: string | null, typeid: number | null): Promise<PersonLeaveModel[]> {

        if (personId === null || typeid === null)
            return [];

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let items = await fetch(baseUrl + '/api/PeopleLeaveDays?year=' + year + "&person=" + personId + "&type=" + typeid, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return items;
    }

    public async getLeaveDays(year: number, personId: string): Promise<PersonLeaveDaysModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let items = await fetch(baseUrl + '/api/LeaveOverview?year=' + year + "&personid=" + personId, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return items;
    }

    public async deletePlanningItemsForProject(startStr: string, projectCode: string) {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + '/api/DeletePlanningItemsForProject?start=' + encodeURIComponent(startStr) + "&project=" + projectCode, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    }

    public async getOverview(from: Date, until: Date): Promise<string> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        const data = await fetch(baseUrl + '/api/PlanningOverview?from=' + format(from, "yyyy-MM-dd") + "&until=" + format(until, "yyyy-MM-dd"), {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return data;
    }

    public async getOverviewSuppliers(): Promise<string> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        const data = await fetch(baseUrl + '/api/GetFuturePlanningForSuppliers', {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then((response) => {
            return response.json();
        });

        return data;
    }

    public async mailSuppliers(cc: string): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let success = await fetch(baseUrl + `/api/SendFuturePlanningToSuppliers?cc=${cc}`, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return success;
    }

    public async getAllCollectivePlanningItems(): Promise<CollectivePlanningItemModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        let success = await fetch(baseUrl + `/api/CollectivePlanningItem`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        }).then(function (response) {
            return response.json();
        });

        return success;
    }

    public async upsertCollectivePlanningItem(data: CollectivePlanningItemModel) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(baseUrl + "/api/CollectivePlanningItem", {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify(data)
        });

    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}