import CheckInAsWorkModel from "../Models/CheckInAsWorkModel";
import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";

export default class CheckInAtWorkService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async get(date: Date): Promise<CheckInAsWorkModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        const data = await fetch(baseUrl + '/api/checkinatwork?date=' + encodeURIComponent(JSON.stringify(date)), {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    public async updateManualCheckInAtWork(date: string, projectCode: string, personId: string, checked: boolean) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(`${baseUrl}/api/ManualCheckInAtWork`, {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify({ date: date, projectCode: projectCode, personId: personId, checked: checked })
        });
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}