import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Checkbox from "../Components/Checkbox";
import ProjectsService from '../Services/ProjectsService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

interface ProjectPreparedModalProps {
    code: string;
    prepared: boolean;
    afterSave: Function;
    afterCancel: Function;
}

const ProjectPreparedModal = (props: ProjectPreparedModalProps) => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [prepared, setPrepared] = useState<boolean>(props.prepared);
    const [visible, setVisible] = useState<boolean>(true);

    function handleProjectPreparedChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.checked;
        setPrepared(value);
    }

    function projectPreparedModalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function projectPreparedModalSave() {
        try {
            if (props.code !== null) {
                await projectsService.updatePrepared(props.code, prepared);

                setVisible(false);

                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }


    return (
        <Modal isOpen={visible} toggle={projectPreparedModalToggle} className="mw-100 w-75">
            <ModalHeader toggle={projectPreparedModalToggle}>{props.code} - Voorbereid</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">

                        <Checkbox className="my-1 mr-sm-2" label="Voorbereid" value="true" checked={prepared}
                            onChange={handleProjectPreparedChange} />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={projectPreparedModalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={projectPreparedModalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ProjectPreparedModal;