import { useEffect, useMemo, useState } from 'react';
import { nextMonday, addDays } from 'date-fns';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import PlanningService from '../Services/PlanningService';
import nlBE from 'date-fns/locale/nl-BE';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'reactstrap';
import { useMsal } from '@azure/msal-react';

const PlanningOverview = () => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    const [planningOverview, setPlanningOverview] = useState<string>("");
    const [selectedFromDate, setselectedFromDate] = useState<Date>(nextMonday(new Date()));
    const [selectedUntilDate, setselectedUntilDate] = useState<Date>(addDays(nextMonday(new Date()), 6));

    registerLocale('nlBE', nlBE);

    useEffect(() => {
        async function loadData() {
            try {
                const data = (await planningService.getOverview(selectedFromDate, selectedUntilDate));
                setPlanningOverview(data);

            }
            catch (error) {
                toast.error("Fout bij het ophalen van de data.");
            }
        }

        loadData();
    }, [planningService, selectedFromDate, selectedUntilDate]);


    async function handleFromDateFilter(date: Date) {
        setselectedFromDate(date);
    }

    async function handleUntilDateFilter(date: Date) {
        setselectedUntilDate(date);
    }

    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-1 col-form-label" htmlFor="personSelect">Van</label>
                        <div className="col-2">
                            <ReactDatePicker locale="nlBE" selected={selectedFromDate} onChange={handleFromDateFilter}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                        title="Datum"
                                    />
                                }
                            />
                        </div>
                        <label className="col-1 col-form-label" htmlFor="personSelect">Tot</label>
                        <div className="col-2">
                            <ReactDatePicker locale="nlBE" selected={selectedUntilDate} onChange={handleUntilDateFilter}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                        title="Datum"
                                    />
                                }
                            />
                        </div>
                        <div className="offset-4 col-2">
                            <Button color="primary" onClick={() => navigator.clipboard.writeText(planningOverview)}>Kopieren naar klembord</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>;



    return (
        <div className="container-fluid">
            {filters}
            <div className="card mb-3">
                <div className="card-body">
                    {planningOverview.split('\n').map((str, index) => <span key={index}>{str}<br /></span>)}
                </div>
            </div>
        </div >
    );


}

export default (PlanningOverview);