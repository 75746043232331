import { useEffect, useMemo, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import PlanningService from '../Services/PlanningService';
import nlBE from 'date-fns/locale/nl-BE';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'reactstrap';
import { useMsal } from '@azure/msal-react';
import GraphService from '../Services/GraphService';


const PlanningOverviewSuppliers = () => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);

    const [planningOverview, setPlanningOverview] = useState<string>("");

    registerLocale('nlBE', nlBE);

    useEffect(() => {
        async function loadData() {
            try {
                const data = (await planningService.getOverviewSuppliers());
                setPlanningOverview(data);

            }
            catch (error) {
                toast.error("Fout bij het ophalen van de data.");
            }
        }

        loadData();
    }, [planningService]);

    async function mailSuppliers() {
        try {
            const user = await graphService.getUserDetails();
            await planningService.mailSuppliers(user.mail ?? user.userPrincipalName ?? "");

            toast.success("Mails verzonden.");
        }
        catch (error) {
            toast.error("Fout bij het versturen van de email.");
        }
    }

    let actions =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className="row">
                        <div className="col-2">
                            <Button color="primary" onClick={mailSuppliers}>Mail toekomstige planning naar OA</Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    return (
        <div className="container-fluid">
            {actions}
            <div className="card mb-3">
                <div className="card-body">
                    {planningOverview.split('\n').map((str, index) => <span key={index}>{str}<br /></span>)}
                </div>
            </div>
        </div >
    );

}

export default (PlanningOverviewSuppliers);