import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const config = {
    appId: '0e58e4e6-bb24-45cb-b6e5-ce154828b789',
    redirectUri: '' + process.env.REACT_APP_REDIRECTURI,
    authority: 'https://login.microsoftonline.com/cf65132a-cacc-43a0-ac70-a8109af73477',
    scopes: [
        'user.read',
        'mailboxsettings.read',
        'User.ReadBasic.All',
        '' + process.env.REACT_APP_API_SCOPE
    ],
    graphScopes: [
        'user.read',
        'mailboxsettings.read',
        'User.ReadBasic.All'
    ],
    apiScopes: [
        '' + process.env.REACT_APP_API_SCOPE
    ]
};

export const msalConfig: Configuration = {
    auth: {
        clientId: "0e58e4e6-bb24-45cb-b6e5-ce154828b789",
        authority: 'https://login.microsoftonline.com/cf65132a-cacc-43a0-ac70-a8109af73477',
        redirectUri: '' + process.env.REACT_APP_REDIRECTURI,
        postLogoutRedirectUri: '' + process.env.REACT_APP_REDIRECTURI
    }
};

export const loginRequest: RedirectRequest = {
    scopes: ["User.Read",
        '' + process.env.REACT_APP_API_SCOPE]
};

export const graphLoginRequest: RedirectRequest = {
    scopes: [
        'user.read',
        'mailboxsettings.read',
        'User.ReadBasic.All'
    ]
};

export const apiLoginRequest: RedirectRequest = {
    scopes: ['' + process.env.REACT_APP_API_SCOPE]
};