import React, { useEffect, useMemo, useState } from 'react';
import SuccessToast from '../Components/SuccessToast';
import ProjectTypesService from '../Services/ProjectTypesService';
import ProjectTypeModel from '../Models/ProjectTypeModel';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const ProjectTypes = () => {

    const { instance } = useMsal();

    const projectTypesService = useMemo(() => new ProjectTypesService(instance), [instance]);

    const [projectTypes, setProjectTypes] = useState<ProjectTypeModel[]>([]);
    const [formTouchedOrDirty, setFormTouchedOrDirty] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    useEffect(() => {
        async function loadData() {
            try {
                const data = await projectTypesService.getAll();
                setNewSate(data, false);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [projectTypesService]);



    function setNewSate(projectTypes: ProjectTypeModel[], showSuccessMessage: boolean) {

        let newProjectTypes = [...projectTypes];
        newProjectTypes.forEach((projectType) => {
            projectType.budgetPerDayAsString = projectType.budgetPerDay?.toString();
            if (projectType.budgetPerDay === null) {
                let budget = projectType.description;
                if (budget?.endsWith('€)')) {
                    budget = budget.substring(budget.lastIndexOf('(') + 1, budget.lastIndexOf('€'));
                    projectType.budgetPerDayAsString = budget;
                }
            }
        });

        setProjectTypes(newProjectTypes);

        if (showSuccessMessage) {
            setShowSuccessMessage(true);
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
        let newProjectTypes = [...projectTypes];
        newProjectTypes.forEach((projectType) => {
            let target = event.target;
            let code = target.getAttribute("data-code");
            if (projectType.code === code) {
                switch (target.name) {
                    case "budgetPerDay":
                        if (target.value === '' || floatRegExp.test(target.value))
                            projectType.budgetPerDayAsString = target.value;
                        break;
                }
            }
        })
        setProjectTypes(newProjectTypes);
        setFormTouchedOrDirty(true);
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {
            if (formTouchedOrDirty) {

                let data = projectTypes;

                data.forEach((projectType) => {
                    projectType.budgetPerDay = parseFloat(projectType.budgetPerDayAsString ?? "");
                });

                data = await projectTypesService.upsert(data);
                setNewSate(data, true);
            }
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    function toggleSuccessMessage(shown: boolean) {
        setShowSuccessMessage(shown);
    }


    return (
        <div className="container-fluid">
            <form onSubmit={handleSubmit}>
                <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                    <button className="btn btn-primary" type="submit">Bewaar wijzigingen</button>
                </div>
                <table className="table table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th className="text-center">Code</th>
                            <th className="text-center">Omschrijving</th>
                            <th className="text-center">Rendement</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            projectTypes.map((record) => {
                                return (
                                    <tr key={record.code} data-code={record.code}>
                                        <td className="text-center">
                                            {record.code}
                                        </td>
                                        <td className="text-center">
                                            {record.description}
                                        </td>
                                        <td className="text-center">
                                            <input onChange={handleChange} type="text" data-code={record.code} id={record.code + "budgetPerDay"}
                                                name="budgetPerDay" value={record.budgetPerDayAsString ?? ""} className="form-control form-control-sm"
                                                title="Rendement" />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                <div className="row m-4">
                    &nbsp;
                </div>
            </form>
            <SuccessToast show={showSuccessMessage} toggle={toggleSuccessMessage} />
        </div>
    )

}
export default ProjectTypes;