import { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parseJSON } from 'date-fns';
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import LeaveRequestModel from '../Models/LeaveRequestModel';
import LeaveRequestService from '../Services/LeaveRequestService';


interface LeaveRequestModalProps {
    leaveRequest: LeaveRequestModel;
    afterSave: Function;
    afterCancel: Function;
}

const LeaveRequestModal = (props: LeaveRequestModalProps) => {

    const { instance } = useMsal();

    const [visible, setVisible] = useState<boolean>(true);
    const [leaveRequest, setLeaveRequest] = useState<LeaveRequestModel>(props.leaveRequest);

    const leaveRequesService = useMemo(() => new LeaveRequestService(instance), [instance]);

    registerLocale('nlBE', nlBE);

    const modalToggle = () => {
        setVisible((visible) => { return !visible })

        props.afterCancel();
    }

    const modalSave = async () => {
        try {
            await leaveRequesService.upsert(leaveRequest);

            setVisible(false);
            props.afterSave();
        } catch (error) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    return (<>
        <Modal isOpen={visible} toggle={modalToggle} >
            <ModalHeader toggle={modalToggle}>Verlof aanvraag</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <label className="my-1">Van</label>
                        <br />
                        <DatePicker locale="nlBE"
                            selected={leaveRequest.startDate === undefined ? new Date() : parseJSON(leaveRequest.startDate)}
                            onChange={(date) => setLeaveRequest({ ...leaveRequest, startDate: date === null ? undefined : format(date, "yyyy-MM-dd") + "T00:00:00.000Z" })}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                        <br />
                        <label className="my-1">tot en met</label>
                        <br />
                        <DatePicker locale="nlBE"
                            selected={leaveRequest.endDate === undefined ? new Date() : parseJSON(leaveRequest.endDate)}
                            onChange={(date) => setLeaveRequest({ ...leaveRequest, endDate: date === null ? undefined : format(date, "yyyy-MM-dd") + "T00:00:00.000Z" })}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal >
    </>
    );

}

export default LeaveRequestModal;