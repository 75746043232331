import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { usePDF, Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import ProjectModel from '../Models/ProjectModel';

interface InfoDocumentModalProps {
    project: ProjectModel;
    afterClose: Function;
}

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 18,
        margin: 12,
        textAlign: 'center',
        fontFamily: 'Helvetica'
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: 'Helvetica'
    },
    text: {
        margin: 5,
        fontSize: 12,
        textAlign: 'justify',
        fontFamily: 'Helvetica'
    },
    col1: {
        width: 140
    },
    col2: {
        width: 270
    }
});

const toDateString = (date?: any) => {
    if (date !== undefined && date !== null) {

        return (new Date(date)).toLocaleString('nl-BE', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        });
    }
    return "";
}


const InfoDocumentModal = (props: InfoDocumentModalProps) => {

    const [visible, setVisible] = useState<boolean>(true);

    const MyDoc = (
        <Document>
            <Page size="A4" style={styles.body} >
                <Text style={styles.title}>Infofiche Project - {props.project.code}</Text>
                <Text style={styles.text}>Naam opdrachtgever: {props.project.customerName}</Text>
                <Text style={styles.text}>Werfadres: {props.project.address}</Text>
                <Text style={styles.text}>Projectleider/contactpersoon: {props.project.ownerName}</Text>
                <Text style={styles.text}>{props.project.typeCode} - {props.project.typeDescription}</Text>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Gepland</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >{toDateString(props.project.actualStartDate)} door ... </Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Klant gemaild</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >{toDateString(props.project.customerNotifiedOn)} door ... </Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Klant gebeld</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >..../..../........ door ... </Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Project verschoven van</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >..../..../........ naar ..../..../........</Text>
                    </View>
                </View>
                <Text style={styles.text}> </Text>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >BL aangemaakt?</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >{(props.project?.totalOrderedSubcontractors ?? 0) > 0
                            || (props.project?.totalOrderedSubcontractors ?? 0) < 0
                            || (props.project?.totalExtraCosts ?? 0) > 0
                            || (props.project?.totalExtraCosts ?? 0) < 0 ? "JA" : "NEE"}</Text>
                    </View>
                </View>
                <Text style={styles.text}> </Text>
                <Text style={styles.text}>Vocht: .....% (nieuwe vochtmeting pas binnen ..... weken/maanden) </Text>
                <Text style={styles.text}>Met welke producten moet de werf uitgevoerd worden:</Text>
                <Text style={styles.text}> </Text>
                <Text style={styles.text}> </Text>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col2}>
                        <Text style={styles.text} >Moet er een kleuradvies uitgevoerd worden?</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col2}>
                        <Text style={styles.text} >Behang</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col2}>
                        <Text style={styles.text} >Zijn er foto's beschikbaar van uit te voeren werf? (b! bij buitenwerken en traphal)</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE</Text>
                    </View>
                </View>
                <Text style={styles.text}> </Text>
                <Text style={styles.text}>Benodigde zaken te voorzien voor de werf </Text>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Hoogtewerker</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE - welke hoogte</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Kamerstelling</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE - welke nodig</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Loopplank</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Steekladder</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE</Text>
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={styles.col1}>
                        <Text style={styles.text} >Gevelstelling huren</Text>
                    </View>
                    <View style={{ flexGrow: 1 }}>
                        <Text style={styles.text} >JA / NEE</Text>
                    </View>
                </View>
                <Text style={styles.text}> </Text>
                <Text style={styles.text}>Opmerkingen: </Text>
            </Page>
        </Document>
    );

    const [usePDFInstance] = usePDF({ document: MyDoc });

    function modalToggle() {
        setVisible(!visible);
        props.afterClose();
    }

    let body = <></>;

    if (usePDFInstance.loading) {
        body = <div>Loading ...</div>;
    } else if (usePDFInstance.error) {
        body = <div>Something went wrong: {usePDFInstance.error}</div>;
    }
    else {
        body = <a href={usePDFInstance.url ?? ""} download={props.project.code + ".pdf"}>
            Download
        </a>;
    }

    return (
        <Modal isOpen={visible} toggle={modalToggle}>
            <ModalHeader toggle={modalToggle}>{props.project.code} - Infofiche</ModalHeader>
            <ModalBody>
                {body}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );
}

export default InfoDocumentModal;