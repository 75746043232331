import React, { useEffect, useMemo, useState } from 'react';
import SuccessToast from '../Components/SuccessToast';
import GraphService from '../Services/GraphService';
import PersonModel from '../Models/PersonModel';
import PeopleService from '../Services/PeopleService';
import Checkbox from '../Components/Checkbox';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const People = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const peopleService = useMemo(() => new PeopleService(instance), [instance]);

    const [people, setPeople] = useState<PersonModel[]>([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [onlyShowInSchedule, setOnlyShowInSchedule] = useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

    useEffect(() => {

        async function loadData() {
            try {
                var peopleGraph = await graphService.getAllGraphUsers();

                peopleGraph = peopleGraph.filter((user: any) => !(user.userPrincipalName.indexOf('#EXT#') !== -1));

                peopleGraph = peopleGraph.sort((r1, r2) => (r1.displayName ?? "").localeCompare((r2.displayName ?? "")));

                var peopleFunc: PersonModel[] = (await peopleService.getAllFuncUsers());

                var allUsers: PersonModel[] = [];

                peopleGraph.forEach((graphUser) => {
                    var viewModelUser: PersonModel =
                    {
                        id: graphUser.id ?? "",
                        displayName: graphUser.displayName ?? "",
                        name: graphUser.displayName ?? "",
                        email: graphUser.mail ?? ""
                    }
                    var funcUser = peopleFunc.find(e => e.id === graphUser.id);
                    if (funcUser) {
                        viewModelUser.showInSchedule = funcUser.showInSchedule;
                        viewModelUser.hasProjectsPlannedInFuture = funcUser.hasProjectsPlannedInFuture;
                        viewModelUser.teamName = funcUser.teamName;
                        viewModelUser.groupName = funcUser.groupName;
                        viewModelUser.isForeman = funcUser.isForeman;
                        viewModelUser.costPerHourAsString = funcUser.costPerHour?.toString();
                        viewModelUser.worksOnSaturday = funcUser.worksOnSaturday;
                        viewModelUser.leaveDaysAsString = funcUser.leaveDays?.toString();
                        viewModelUser.holidaysAsString = funcUser.holidays?.toString();
                        viewModelUser.weekdayHoursAsString = (funcUser.weekdayHours ?? 8).toString();
                        viewModelUser.saturdayHoursAsString = (funcUser.saturdayHours ?? 8).toString();
                        if (funcUser.email !== undefined && funcUser.email !== "")
                            viewModelUser.email = funcUser.email;
                    }

                    allUsers.push(viewModelUser);
                })

                setPeople(allUsers);

            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [graphService, peopleService]);

    async function handleSubmit(event: any) {
        event.preventDefault();
        try {

            people.forEach((person) => {
                person.costPerHour = parseFloat(person.costPerHourAsString ?? "");
                person.leaveDays = parseFloat(person.leaveDaysAsString ?? "");
                person.holidays = parseFloat(person.holidaysAsString ?? "");
                person.weekdayHours = parseFloat(person.weekdayHoursAsString ?? "");
                person.saturdayHours = parseFloat(person.saturdayHoursAsString ?? "");
            });

            await peopleService.updatePeople(people);

            setShowSuccessMessage(true);

        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    function handleOnlyShowInScheduleFilter(event: any) {
        setOnlyShowInSchedule(event.target.checked);

    }

    function handleGroupFilter(event: any) {
        if (event.target.checked) {
            setSelectedGroup(event.target.value);
        }
        else {
            setSelectedGroup(null);
        }
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const updatedPeople = [...people];

        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

        updatedPeople.forEach((person: PersonModel) => {
            let target = event.target;
            let id = target.getAttribute("data-id");
            if (person.id === id) {
                switch (target.name) {
                    case "showInSchedule": {
                        if (person.hasProjectsPlannedInFuture === true) {
                            toast.warning("Opgelet! Deze persoon heeft nog projecten gepland.");
                            break;
                        }
                        person.showInSchedule = target.checked;
                        break;
                    }
                    case "teamName": person.teamName = target.value; break;
                    case "groupName": person.groupName = target.value; break;
                    case "isForeman": person.isForeman = target.checked; break;
                    case "leaveDays":
                        if (target.value === '' || floatRegExp.test(target.value))
                            person.leaveDaysAsString = target.value;
                        break;
                    case "holidays":
                        if (target.value === '' || floatRegExp.test(target.value))
                            person.holidaysAsString = target.value;
                        break;
                    case "costPerHour":
                        if (target.value === '' || floatRegExp.test(target.value))
                            person.costPerHourAsString = target.value;
                        break;
                    case "worksOnSaturday": person.worksOnSaturday = target.checked; break;
                    case "weekdayHours":
                        if (target.value === '' || floatRegExp.test(target.value))
                            person.weekdayHoursAsString = target.value;
                        break;
                    case "saturdayHours":
                        if (target.value === '' || floatRegExp.test(target.value))
                            person.saturdayHoursAsString = target.value;
                        break;
                    case "email": person.email = target.value; break;
                }
            }
            updatedPeople.concat(person);
        })
        setPeople(updatedPeople);
    }

    function toggleSuccessMessage(shown: boolean) {
        setShowSuccessMessage(shown);
    }

    let groups = people.map(r => r.groupName ?? "")
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((value) => value !== undefined && value !== null && value !== "")
        .sort((r1, r2) => r1.localeCompare(r2));

    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-2 col-form-label">Enkel Tonen in planning</label>
                        <div className="col-10 form-inline">
                            <Checkbox className="mr-4" label="" value="true" checked={onlyShowInSchedule} onChange={handleOnlyShowInScheduleFilter} />
                        </div>
                    </div>
                    <div className=" row">
                        <label className="col-2 col-form-label">Groepen</label>
                        <div className="col-10 form-inline">
                            {
                                groups.map((group) => {
                                    return (
                                        <Checkbox className="mr-4" label={group} value={group} key={group}
                                            checked={group === selectedGroup}
                                            onChange={handleGroupFilter} />
                                    );
                                })
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    let filteredPeople = people;
    if (onlyShowInSchedule) {
        filteredPeople = filteredPeople.filter(r => r.showInSchedule);
    }
    if (selectedGroup !== null) {
        filteredPeople = filteredPeople.filter(r => r.groupName === selectedGroup);
    }

    return (
        <div className="container-fluid">
            {filters}
            <form onSubmit={handleSubmit}>
                <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                    <button className="btn btn-primary" type="submit" >Bewaar wijzigingen</button>
                </div>
                <table className="table table-sm text-nowrap">
                    <thead>
                        <tr>
                            <th>Naam</th>
                            <th className="text-center">Tonen<br />in<br />planning</th>
                            <th>Groep</th>
                            <th>Ploeg</th>
                            <th className="text-center">Ploegleider</th>
                            <th className="text-center">Werkt<br />op<br />zaterdag</th>
                            <th>Gemiddelde<br />kostprijs<br />per uur (&euro;)</th>
                            <th>Verlofdagen</th>
                            <th>Feestdagen</th>
                            <th># uren<br />weekdag</th>
                            <th># uren<br />zaterdag</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredPeople.map((record: PersonModel) => {
                                return (
                                    <tr key={record.id}>
                                        <td>{record.displayName}</td>
                                        <td className="text-center" >
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={record.id + "showInSchedule"}
                                                    onChange={handleChange} data-id={record.id} name="showInSchedule" checked={record.showInSchedule} />
                                                <label className="form-check-label" htmlFor={record.id + "showInSchedule"}></label>
                                            </div>
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "groupName"}
                                                name="groupName" value={record.groupName ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "teamName"}
                                                name="teamName" value={record.teamName ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td className="text-center">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={record.id + "isForeman"}
                                                    onChange={handleChange} data-id={record.id} name="isForeman" checked={record.isForeman} />
                                                <label className="form-check-label" htmlFor={record.id + "isForeman"}></label>
                                            </div>
                                        </td>
                                        <td className="text-center" >
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id={record.id + "worksOnSaturday"}
                                                    onChange={handleChange} data-id={record.id} name="worksOnSaturday" checked={record.worksOnSaturday} />
                                                <label className="form-check-label" htmlFor={record.id + "worksOnSaturday"}></label>
                                            </div>
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "costPerHour"}
                                                name="costPerHour" value={record.costPerHourAsString ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "leaveDays"}
                                                name="leaveDays" value={record.leaveDaysAsString ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "holidays"}
                                                name="holidays" value={record.holidaysAsString ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "weekdayHours"}
                                                name="weekdayHours" value={record.weekdayHoursAsString ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "saturdayHours"}
                                                name="saturdayHours" value={record.saturdayHoursAsString ?? ""} className="form-control form-control-sm" />
                                        </td>
                                        <td>
                                            <input onChange={handleChange} type="text" data-id={record.id} id={record.id + "email"}
                                                name="email" value={record.email ?? ""} className="form-control form-control-sm" />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="row m-4">
                    &nbsp;
                </div>
            </form>
            <SuccessToast show={showSuccessMessage} toggle={toggleSuccessMessage} />
        </div>
    );


}

export default People;