import { useEffect, useMemo, useState } from 'react';
import { format, parseJSON } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import Checkbox from '../Components/Checkbox';
import LeaveRequestModel from '../Models/LeaveRequestModel';
import LeaveRequestService from '../Services/LeaveRequestService';

const LeaveRequests = () => {

    const { instance } = useMsal();

    const leaveRequestService = useMemo(() => new LeaveRequestService(instance), [instance]);

    const [leaveRequests, setLeaveRequests] = useState<LeaveRequestModel[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);

    useEffect(() => {
        async function loadData() {
            try {
                const year = (new Date()).getFullYear();
                const data = await leaveRequestService.get(year, null, showAll === false ? "Nieuw" : null);

                setLeaveRequests(data);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [leaveRequestService, showAll]);

    async function approve(leaveRequest: LeaveRequestModel) {
        try {
            leaveRequest.status = "Goedgekeurd";
            await leaveRequestService.upsert(leaveRequest);

            const year = (new Date()).getFullYear();
            const data = await leaveRequestService.get(year, null, showAll === false ? "Nieuw" : null);

            setLeaveRequests(data);
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    async function decline(leaveRequest: LeaveRequestModel) {
        try {
            leaveRequest.status = "Afgekeurd";
            await leaveRequestService.upsert(leaveRequest);

            const year = (new Date()).getFullYear();
            const data = await leaveRequestService.get(year, null, showAll === false ? "Nieuw" : null);

            setLeaveRequests(data);
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    return (
        <div className="container-fluid">
            <div className="card mb-3">
                <div className="card-body">
                    <form>
                        <div className=" row">
                            <div className="col-2 form-inline">
                                <Checkbox className="mr-4" label="Alle" value="Alle" checked={showAll} onChange={() => setShowAll(!showAll)} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Van</th>
                        <th>Tot en met</th>
                        <th>Voor</th>
                        <th>Status</th>
                        <th>Verwerken</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        leaveRequests.map((record, index) => {
                            return (
                                <tr key={record.id}>
                                    <td>{record.startDate === undefined ? null : format(parseJSON(record.startDate), "dd/MM/yyyy")}</td>
                                    <td>{record.endDate === undefined ? null : format(parseJSON(record.endDate), "dd/MM/yyyy")}</td>
                                    <td>{record.personName}</td>
                                    <td>{record.status}</td>
                                    <td>
                                        {record.status === "Nieuw" ?
                                            <>
                                                <button type='button' title='Goedkeuren' className="btn btn-link p-0 text-success mr-3" onClick={() => approve(record)}>
                                                    <i className="fas fa-check"></i>
                                                </button>
                                                <button type='button' title='Afkeuren' className="btn btn-link p-0 text-danger" onClick={() => decline(record)}>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </>
                                            : null
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </div>
    )

}
export default LeaveRequests;