import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Checkbox from "../Components/Checkbox";
import ProjectsService from '../Services/ProjectsService';
import DatePicker, { registerLocale } from "react-datepicker";
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import PersonModel from '../Models/PersonModel';

interface ProjectCiawRequestedModalProps {
    code: string;
    requested: boolean;
    requestedOn: Date | null;
    requestedBy: string | null;
    requestedMethod: string | null;
    owners: PersonModel[];
    afterSave: Function;
    afterCancel: Function;
}

const ProjectCiawRequestedModal = (props: ProjectCiawRequestedModalProps) => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [requested, setRequested] = useState<boolean>(props.requested);
    const [requestedOn, setRequestedOn] = useState<Date | null>(props.requestedOn);
    const [requestedBy, setRequestedBy] = useState<string | null>(props.requestedBy);
    const [requestedMethod, setRequestedMethod] = useState<string | null>(props.requestedMethod);
    const [visible, setVisible] = useState<boolean>(true);

    registerLocale('nlBE', nlBE);

    function handleProjectCiawRequestedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setRequested(event.target.checked);
    }

    function handleProjectCiawRequestedOnChange(date: Date) {
        setRequestedOn(date);
    }

    function toggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function projectCustomerrequestedModalSave() {
        try {
            if (props.code !== null) {

                await projectsService.updateCiawRequested(props.code, requested, requestedOn, requestedBy, requestedMethod);

                setVisible(false);

                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }


    return (
        <Modal isOpen={visible} toggle={toggle} className="mw-100 w-75">
            <ModalHeader toggle={toggle}>{props.code} - CIAW nummer gevraagd?</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <Checkbox className="my-1 mr-sm-2" label="CIAW nummer gevraagd?" value="true" checked={requested}
                            onChange={handleProjectCiawRequestedChange} />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">CIAW nummer gevraagd op</label>
                        <DatePicker locale="nlBE" selected={requestedOn} onChange={handleProjectCiawRequestedOnChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">CIAW nummer gevraagd door</label>
                        <select className="form-control my-1 mr-sm-2" onChange={(e) => setRequestedBy(e.target.value)}
                            defaultValue={requestedBy ?? ""} >
                            <option value="null">- Geen opvolger -</option>
                            {
                                props.owners.map((owner) => {
                                    return (
                                        <option value={owner.id} key={owner.id}>{owner.name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">CIAW nummer gevraagd met</label>
                        <select className="form-control my-1 mr-sm-2" onChange={(e) => setRequestedMethod(e.target.value)}
                            defaultValue={requestedMethod ?? ""} >
                            <option value="null">- Geen waarde -</option>
                            <option value="Email">Email</option>
                            <option value="Telefoon">Telefoon</option>
                        </select>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={projectCustomerrequestedModalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={toggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ProjectCiawRequestedModal;