import { IPublicClientApplication } from "@azure/msal-browser";
import ReminderModel from "../Models/ReminderModel";
import { apiLoginRequest } from "../Config";

export default class RemindersService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async get(projectCode: string): Promise<ReminderModel[]> {

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const data = await fetch(`${baseUrl}/api/Reminders?projectcode=${projectCode}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    public async upsert(data: ReminderModel) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(`${baseUrl}/api/Reminders`, {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify(data)
        });
    }

    public async getOpen(personid: string, showFuture: boolean): Promise<ReminderModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const data = await fetch(`${baseUrl}/api/Reminders?personid=${personid}&all=${showFuture}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}