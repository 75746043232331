import { PlanningItemTypeModel } from "../Models/PlanningItemTypeModel";
import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";

export default class PlanningItemTypesService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }
    public async getAllPlanningItemTypes(): Promise<PlanningItemTypeModel[]> {

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        const planningItemTypes = await fetch(baseUrl + '/api/PlanningItemTypes', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return planningItemTypes;
    }

    public async updatePlanningItemTypes(planningItemTypes: PlanningItemTypeModel[]) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        const updatePlanningItemTypes = await fetch(baseUrl + "/api/PlanningItemTypes", {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify(planningItemTypes)
        }
        ).then(function (response) {
            return response.json();
        })
        return updatePlanningItemTypes;
    } private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}