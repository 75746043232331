import { User } from '@microsoft/microsoft-graph-types';
import { graphLoginRequest } from "../Config";
import { PageCollection, PageIteratorCallback, PageIterator } from "@microsoft/microsoft-graph-client";
import { IPublicClientApplication } from "@azure/msal-browser";

var graph = require('@microsoft/microsoft-graph-client');

export default class GraphService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    getAuthenticatedClient = async () => {
        const accessToken = await this.acquireAccessToken();
        const client = graph.Client.init({
            authProvider: (done: any) => {
                done(null, accessToken);
            }
        });

        return client;
    }

    acquireAccessToken = async () => {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };

    public async hasRole(role: string): Promise<boolean> {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        const authResult = await this.msalInstance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account
        });

        return (authResult.idTokenClaims as any).roles.includes(role);
    }

    public async getRoles(): Promise<string[]> {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        const authResult = await this.msalInstance.acquireTokenSilent({
            ...graphLoginRequest,
            account: account
        });

        return (authResult.idTokenClaims as any).roles ?? [];
    }


    public async getUserDetails(): Promise<User> {
        const client = await this.getAuthenticatedClient();

        const user = await client
            .api('/me')
            .select('displayName,mail,userPrincipalName,id')
            .get();

        return user;
    }

    public async getAllGraphUsers(): Promise<User[]> {
        const client = await this.getAuthenticatedClient();

        let returnValue: User[] = [];

        let response: PageCollection = await client
            .api("/users")
            .select('displayName,userPrincipalName,id,mail')
            .get();

        let callback: PageIteratorCallback = (data) => {
            returnValue.push(data);
            return true;
        };
        // Creating a new page iterator instance with client a graph client instance, page collection response from request and callback
        let pageIterator = new PageIterator(client, response, callback);
        // This iterates the collection until the nextLink is drained out.
        await pageIterator.iterate();

        return returnValue;
    }
}