import { IPublicClientApplication } from "@azure/msal-browser";
import MandatoryWorkingWeekModel from "../Models/MandatoryWorkingWeekModel";
import { apiLoginRequest } from "../Config";

export default class MandatoryWorkingWeekService {
    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }
    public async getAllMandatoryWorkingWeeks(): Promise<MandatoryWorkingWeekModel[]> {

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const data = await fetch(baseUrl + '/api/MandatoryWorkingWeek', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    public async upsertMandatoryWorkingWeek(data: MandatoryWorkingWeekModel) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(baseUrl + "/api/MandatoryWorkingWeek", {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify(data)
        });

    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}