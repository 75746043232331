import React, { useState } from "react";
import UUID from "node-uuid";

interface CheckboxProps {
    label: string;
    value: string;
    checked: boolean;
    onChange?: any;
    className: string;
    readonly?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
    const [id] = useState(UUID.v4());
    return (
        <div className={(props.className ?? "") + " form-check"}>
            <input className="form-check-input" type="checkbox" id={id} value={props.value ?? "null"} checked={props.checked}
                onChange={props.onChange} readOnly={props.readonly} />
            <label className="form-check-label" htmlFor={id}>
                {props.label ?? "<geen waarde>"}
            </label>
        </div>
    )
};

export default Checkbox;