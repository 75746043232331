import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface ConfirmationModalProps {
    text: string;
    afterOk: Function;
    afterCancel: Function;
}

interface ConfirmationModalState {
    visible: boolean;
}

export default class ConfirmationModal extends React.Component<ConfirmationModalProps, ConfirmationModalState> {

    constructor(props: ConfirmationModalProps) {
        super(props);

        this.state = {
            visible: true
        };

        this.modalToggle = this.modalToggle.bind(this)
        this.modalOk = this.modalOk.bind(this);
    }

    modalToggle() {
        this.setState({ visible: !this.state.visible });
        this.props.afterCancel();
    }

    async modalOk() {
        this.setState({ visible: false });
        this.props.afterOk();
    }

    render() {
        return (
            <Modal isOpen={this.state.visible} toggle={this.modalToggle}>
                <ModalHeader toggle={this.modalToggle}>Bevestiging</ModalHeader>
                <ModalBody>
                    <form className="form-inline">
                        <div className="form-group">
                            <label className="my-1 mr-2">{this.props.text}</label>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.modalOk}>Bevestigen</Button>{' '}
                    <Button color="secondary" onClick={this.modalToggle}>Annuleren</Button>
                </ModalFooter>
            </Modal>
        );
    }
}