import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, parseJSON } from 'date-fns';
import nlBE from 'date-fns/locale/nl-BE';
import MandatoryWorkingWeekModel from '../Models/MandatoryWorkingWeekModel';
import MandatoryWorkingWeeksService from '../Services/MandatoryWorkingWeeksService';
import PersonModel from '../Models/PersonModel';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';


interface MandatoryWorkingWeekModalProps {
    mandatoryWorkingWeek: MandatoryWorkingWeekModel | null;
    people: PersonModel[];
    afterSave: Function;
    afterCancel: Function;
}

const MandatoryWorkingWeekModal = (props: MandatoryWorkingWeekModalProps) => {

    const { instance } = useMsal();

    const mandatoryWorkingWeeksService = useMemo(() => new MandatoryWorkingWeeksService(instance), [instance]);

    const [start, setStart] = useState<Date>(props.mandatoryWorkingWeek?.start !== undefined ? parseJSON(props.mandatoryWorkingWeek?.start ?? "") : new Date());
    const [end, setEnd] = useState<Date>(props.mandatoryWorkingWeek?.end !== undefined ? parseJSON(props.mandatoryWorkingWeek?.end ?? "") : new Date());
    const [visible, setVisible] = useState<boolean>(true);
    const [selectedPersonId, setSelectedPersonId] = useState<string | null>(props.mandatoryWorkingWeek?.personId ?? null);

    registerLocale('nlBE', nlBE);

    function handleStartChange(date: Date) {
        setStart(date);
    }

    function handleEndChange(date: Date) {
        setEnd(date);
    }

    function handlePersonChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setSelectedPersonId(event.target.value);

    }

    function modalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function modalSave() {
        try {
            await mandatoryWorkingWeeksService.upsertMandatoryWorkingWeek({
                id: props.mandatoryWorkingWeek?.id,
                start: format(start, "yyyy-MM-dd"),
                end: format(end, "yyyy-MM-dd"),
                personId: selectedPersonId ?? ""
            });

            setVisible(false);
            props.afterSave();
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }


    return (
        <Modal isOpen={visible} toggle={modalToggle}>
            <ModalHeader toggle={modalToggle}>Verplichte werkweek</ModalHeader>
            <ModalBody>
                <form>
                    <div className="form-group">
                        <label className="" htmlFor="personSelect">Persoon</label>
                        <select className="form-control" id="personSelect" defaultValue={selectedPersonId ?? ""} onChange={handlePersonChange}>
                            {
                                props.people.map((person) => {
                                    return (
                                        <option value={person.id}>{person.name}</option>
                                    );
                                })
                            }
                        </select>

                    </div>
                    <div className="form-group">
                        <label className="my-1 mr-2">Van</label>
                        <DatePicker locale="nlBE" selected={start} onChange={handleStartChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                    title="Van"
                                />
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label className="my-1 mr-2">Tot en met</label>
                        <DatePicker locale="nlBE" selected={end} onChange={handleEndChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                    title="Tot en met"
                                />
                            }
                        />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal >
    );

}

export default MandatoryWorkingWeekModal;