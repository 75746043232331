import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

interface PlanningItemDescriptionModalProps {
    description: string;
    afterSave: Function;
    afterCancel: Function;
}

const PlanningItemDescriptionModal = (props: PlanningItemDescriptionModalProps) => {

    const [visible, setVisible] = useState<boolean>(true);
    const [description, setDescription] = useState<string>(props.description);

    function modalToggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function modalSave() {
        try {
            setVisible(false);
            props.afterSave(description);
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    return (
        <Modal isOpen={visible} toggle={modalToggle} >
            <ModalHeader toggle={modalToggle}>Omschrijving</ModalHeader>
            <ModalBody>
                <form>
                    <div className="form-group">
                        <input type="text" className="form-control" id="columnValueInput"
                            onChange={(event) => setDescription(event.target.value)}
                            value={description} />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default PlanningItemDescriptionModal;