import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ProjectsService from '../Services/ProjectsService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import GraphService from '../Services/GraphService';
import SupplierService from '../Services/SuppliersService';
import { SupplierModel } from '../Models/SupplierModel';
import Checkbox from './Checkbox';
import { SubcontractorModel } from '../Models/SubcontractorModel';

interface ReportSubcontractorsModalProps {
    code: string;
    emails: string;
    afterSave: Function;
    afterCancel: Function;
}

const ReportSubcontractorsModal = (props: ReportSubcontractorsModalProps) => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);
    const supplierService = useMemo(() => new SupplierService(instance), [instance]);

    const [emails, setEmails] = useState<string>(props.emails);
    const [visible, setVisible] = useState<boolean>(true);
    const [suppliers, setSuppliers] = useState<SupplierModel[]>([]);
    const [selectedCodes, setSelectedCodes] = useState<string[]>([]);

    useEffect(() => {
        async function loadSuppliers() {
            try {
                const suppliersData = await supplierService.getAll();

                setSuppliers(suppliersData);

            }
            catch (error) {
                console.log(error);
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadSuppliers();
    }, [supplierService]);

    function toggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    function onSelectSupplier(event: React.ChangeEvent<HTMLInputElement>, code: string) {
        if (event.target.checked) {
            setSelectedCodes([...selectedCodes, code]);
        }
        else {
            setSelectedCodes(selectedCodes.filter(item => item !== code));
        }
    }

    async function save() {
        try {
            if (props.code !== null) {
                const user = await graphService.getUserDetails();

                let subContractors: SubcontractorModel[] = [{
                    name: "M-Painting B.V.B.A.",
                    address: "Winkelstraat 4, 3720 Kortessem",
                    vatNr: "BE0831 004 344",
                    subcontractors: []
                }];

                suppliers.filter(r => (r.vatNr ?? "").length > 0 && r.parentSupplierCode === null && selectedCodes.find(c => c === r.code) !== undefined)
                    .map(selectedSub => {
                        const sub1: SubcontractorModel = {
                            name: selectedSub.name ?? "",
                            address: selectedSub.address ?? "",
                            vatNr: selectedSub.vatNr ?? "",
                            subcontractors: []
                        };

                        suppliers.filter(r => (r.vatNr ?? "").length > 0 && r.parentSupplierCode === selectedSub.code && selectedCodes.find(c => c === r.code) !== undefined)
                            .map(selectedSub1 => {
                                const sub2: SubcontractorModel = {
                                    name: selectedSub1.name ?? "",
                                    address: selectedSub1.address ?? "",
                                    vatNr: selectedSub1.vatNr ?? ""
                                };
                                sub1.subcontractors?.push(sub2);
                                return null;
                            });

                        subContractors[0].subcontractors?.push(sub1);
                        return null;
                    });

                await projectsService.reportSubcontractorsFunction(props.code, emails, user.id ?? "", subContractors);

                setVisible(false);

                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    return (
        <Modal isOpen={visible} toggle={toggle} className="mw-100 w-75">
            <ModalHeader toggle={toggle}>{props.code} - OA melden</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <label className="my-1 mr-2" title='; gescheiden lijst van email adressen'>Email verzenden naar</label>
                        <input type="text" className="form-control " onChange={(e) => setEmails(e.target.value)} value={emails}></input>
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <Checkbox className="mr-4" label="" value="true" checked={true} readonly={true} />
                        </div>
                        <div className="col-4">
                            M-Painting B.V.B.A.
                        </div>
                        <div className="col-5">
                            Winkelstraat 4, 3720 Kortessem
                        </div>
                        <div className="col">
                            BE0831 004 344
                        </div>
                    </div>
                    {suppliers.filter(r => (r.vatNr ?? "").length > 0 && r.parentSupplierCode === null).map(supplier =>
                        <>
                            <div className="row">
                                <div className="col-1"></div>
                                <div className="col-1">
                                    <Checkbox className="mr-4" label="" value="true"
                                        checked={selectedCodes.find(r => r === supplier.code) !== undefined}
                                        onChange={(e: any) => onSelectSupplier(e, supplier.code ?? "")} />
                                </div>
                                <div className="col-3">
                                    {supplier.name}
                                </div>
                                <div className="col-5">
                                    {supplier.address}
                                </div>
                                <div className="col">
                                    {supplier.vatNr}
                                </div>
                            </div>
                            {selectedCodes.find(r => r === supplier.code) !== undefined && suppliers.filter(r => (r.vatNr ?? "").length > 0 && r.parentSupplierCode === supplier.code).map(sub1 =>
                                <>
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="col-1"></div>
                                        <div className="col-1">
                                            <Checkbox className="mr-4" label="" value="true" checked={selectedCodes.find(r => r === sub1.code) !== undefined}
                                                onChange={(e: any) => onSelectSupplier(e, sub1.code ?? "")} />
                                        </div>
                                        <div className="col-2">
                                            {sub1.name}
                                        </div>
                                        <div className="col-5">
                                            {sub1.address}
                                        </div>
                                        <div className="col">
                                            {sub1.vatNr}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Verzenden</Button>{' '}
                <Button color="secondary" onClick={toggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ReportSubcontractorsModal;