import { IPublicClientApplication } from "@azure/msal-browser";
import PersonModel from "../Models/PersonModel";
import { apiLoginRequest } from "../Config";

export default class PeopleService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }


    public async updatePeople(people: PersonModel[]) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();
        await fetch(baseUrl + "/api/People", {
            method: "post",
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            }),
            body: JSON.stringify(people)
        });
    }

    public async getAllFuncUsers(): Promise<PersonModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const users = await fetch(baseUrl + '/api/People', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return users;
    }

    public async getForemen(): Promise<PersonModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const users = await fetch(baseUrl + '/api/PeopleForemen', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return users;
    }

    public async getOwners(): Promise<PersonModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const users = await fetch(baseUrl + '/api/PeopleOwners', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return users;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}