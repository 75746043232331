import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Home from './Pages/Home';
import Projects from './Pages/Projects';
import People from './Pages/People';
import ProjectColors from './Pages/ProjectColors';
import PlanningItemTypesConfiguration from './Pages/PlanningItemTypesConfiguration';
import PeopleLeaveDays from './Pages/PeopleLeaveDays';
import PeopleLeaveDaysOverview from './Pages/PeopleLeaveDaysOverview';
import Planning from './Pages/Planning';
import Status from './Pages/Status';
import Suppliers from './Pages/Suppliers';
import 'bootstrap/dist/css/bootstrap.css';
import MandatoryWorkingWeeks from './Pages/MandatoryWorkingWeeks';
import ProjectTypes from './Pages/ProjectTypes';
import CheckInAtWork from './Pages/CheckInAtWork';
import Reminders from './Pages/Reminders';
import Tasks from './Pages/Tasks';
import PlanningOverview from './Pages/PlanningOverview';
import { AuthenticationResult, EventMessage, EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { loginRequest, msalConfig } from './Config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wiu from './Pages/Wiu';
import PlanningOverviewSuppliers from './Pages/PlanningOverviewSuppliers';
import CollectivePlanningItems from './Pages/CollectivePlanningItems';
import Leave from './Pages/Leave';
import LeaveRequests from './Pages/LeaveRequests';

const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const App = () => {

    return (
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                    <NavBar />
                    <div className="pl-2 pr-2 flex-grow-1 d-flex">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/planning" element={<Planning />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/people" element={<People />} />
                            <Route path="/planningItemTypesConfiguration" element={<PlanningItemTypesConfiguration />} />
                            <Route path="/peopleLeaveDays" element={<PeopleLeaveDays />} />
                            <Route path="/peopleLeaveDaysOverview" element={<PeopleLeaveDaysOverview />} />
                            <Route path="/status" element={<Status />} />
                            <Route path="/suppliers" element={<Suppliers />} />
                            <Route path="/projectcolors" element={<ProjectColors />} />
                            <Route path="/mandatoryworkingweeks" element={<MandatoryWorkingWeeks />} />
                            <Route path="/projecttypes" element={<ProjectTypes />} />
                            <Route path="/checkinatwork" element={<CheckInAtWork />} />
                            <Route path="/reminders" element={<Reminders />} />
                            <Route path="/tasks" element={<Tasks />} />
                            <Route path="/planningoverview" element={<PlanningOverview />} />
                            <Route path="/planningoverviewoa" element={<PlanningOverviewSuppliers />} />
                            <Route path="/collectiveplanningitems" element={<CollectivePlanningItems />} />
                            <Route path="/wiu" element={<Wiu />} />
                            <Route path="/leave" element={<Leave />} />
                            <Route path="/leaverequests" element={<LeaveRequests />} />
                        </Routes>
                    </div>
                </MsalAuthenticationTemplate>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </BrowserRouter>
        </MsalProvider>
    );

}

export default App;