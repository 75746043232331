import React, { useEffect, useMemo, useState } from 'react';
import CheckInAsWorkModel from '../Models/CheckInAsWorkModel';
import CheckInAtWorkService from '../Services/CheckInAtWorkService';
import { format, parseJSON } from 'date-fns';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import nlBE from 'date-fns/locale/nl-BE';

import "react-datepicker/dist/react-datepicker.css";
import Checkbox from '../Components/Checkbox';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';


const CheckInAtWork = () => {

    const { instance } = useMsal();

    const checkInAtWorkService = useMemo(() => new CheckInAtWorkService(instance), [instance]);

    const [checkInAtWorkData, setCheckInAtWorkData] = useState<CheckInAsWorkModel[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

    registerLocale('nlBE', nlBE);

    useEffect(() => {
        async function loadData() {
            try {

                const data = await checkInAtWorkService.get(selectedDate);
                setCheckInAtWorkData(data);

            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [checkInAtWorkService, selectedDate]);

    async function handleDateFilter(date: Date) {
        setSelectedDate(date);
    }

    function handleGroupFilter(event: any) {
        if (event.target.checked) {
            setSelectedGroup(event.target.value);
        }
        else {
            setSelectedGroup(null);
        }
    }

    function handleManualCheckInAtWork(event: any, date: string, projectCode: string, personId: string) {
        async function updateManualCheckInAtWork() {
            try {

                await checkInAtWorkService.updateManualCheckInAtWork(date, projectCode, personId, event.target.checked);
                const data = await checkInAtWorkService.get(selectedDate);
                setCheckInAtWorkData(data);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        updateManualCheckInAtWork();
    }

    let groups = checkInAtWorkData.map(r => r.personGroupName ?? "")
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((value) => value !== undefined && value !== null && value !== "")
        .sort((r1, r2) => r1.localeCompare(r2));

    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-1 col-form-label" htmlFor="personSelect">Datum</label>
                        <div className="col-2">
                            <ReactDatePicker locale="nlBE" selected={selectedDate} onChange={handleDateFilter}
                                dateFormat="dd/MM/yyyy"
                                customInput={
                                    <input
                                        type="text"
                                        className="form-control my-1 mr-sm-2"
                                        title="Datum"
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className=" row">
                        <label className="col-2 col-form-label">Groepen</label>
                        <div className="col-10 form-inline">
                            {
                                groups.map((group) => {
                                    return (
                                        <Checkbox className="mr-4" label={group} value={group} key={group}
                                            checked={group === selectedGroup}
                                            onChange={handleGroupFilter} />
                                    );
                                })
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    let filteredCheckInAtWorkData = checkInAtWorkData;
    if (selectedGroup !== null) {
        filteredCheckInAtWorkData = filteredCheckInAtWorkData.filter(r => r.personGroupName === selectedGroup);
    }

    return (
        <div className="container-fluid">
            {filters}
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Persoon</th>
                        <th>Groep</th>
                        <th>Projectnr</th>
                        <th>Project omschrijving</th>
                        <th>Man. CIAW</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredCheckInAtWorkData.map((record) => {
                            return (
                                <tr key={record.date + record.personName + record.projectCode}>
                                    <td>{format(parseJSON(record.date), "dd/MM/yyyy")}</td>
                                    <td>{record.personName}</td>
                                    <td>{record.personGroupName}</td>
                                    <td>{record.projectCode}</td>
                                    <td>{record.projectName}</td>
                                    <td><Checkbox className="mr-4" label="" value="manualCIAW" key={record.date + record.personName + record.projectCode}
                                        checked={record.manualCheckInAtWork ?? false}
                                        onChange={(e: any) => handleManualCheckInAtWork(e, record.date, record.projectCode, record.personId)} /></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );


}

export default CheckInAtWork;