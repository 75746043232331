import { useEffect, useMemo, useState } from 'react';
import { format, parseJSON } from 'date-fns';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import PlanningService from '../Services/PlanningService';
import CollectivePlanningItemModel from '../Models/CollectivePlanningItemModel';
import { PlanningItemTypeModel } from '../Models/PlanningItemTypeModel';
import PlanningItemTypesService from '../Services/PlanningItemTypesService';
import CollectivePlanningItemModal from '../Components/CollectivePlanningItemModal';

const CollectivePlanningItems = () => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);
    const planningItemTypesService = useMemo(() => new PlanningItemTypesService(instance), [instance]);

    const [collectivePlanningItems, setCollectivePlanningItems] = useState<CollectivePlanningItemModel[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedCollectivePlanningItem, setSelectedCollectivePlanningItem] = useState<CollectivePlanningItemModel | null>(null);
    const [planningItemTypes, setPlanningItemTypes] = useState<PlanningItemTypeModel[]>([]);

    useEffect(() => {
        async function loadData() {
            try {
                const data = await planningService.getAllCollectivePlanningItems();
                const planningItemTypes = await planningItemTypesService.getAllPlanningItemTypes();

                setPlanningItemTypes(planningItemTypes);
                setCollectivePlanningItems(data);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [planningService, planningItemTypesService]);

    async function collectivePlanningItemModalAfterSave() {
        try {
            setShowModal(false);

            const data = await planningService.getAllCollectivePlanningItems();
            setCollectivePlanningItems(data);
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function collectivePlanningItemModalAfterClose() {
        setShowModal(false);
    }

    let modal = null;
    if (showModal) {
        modal =
            <CollectivePlanningItemModal
                collectivePlanningItem={selectedCollectivePlanningItem}
                planningItemTypes={planningItemTypes}
                afterSave={() => collectivePlanningItemModalAfterSave()}
                afterCancel={() => collectivePlanningItemModalAfterClose()}
            />
    }

    return (
        <div className="container-fluid">
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        collectivePlanningItems.map((record) => {
                            return (
                                <tr key={record.id}>
                                    <td>{format(parseJSON(record.date), "dd/MM/yyyy")}</td>
                                    <td>{record.planningItemTypeCode}</td>
                                    <td><button type='button' className="btn btn-link p-0" title="Aanpassen"
                                        onClick={() => { setShowModal(true); setSelectedCollectivePlanningItem(record); }}>
                                        <i className="fas fa-pen"></i>
                                    </button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="row m-4">
                &nbsp;
            </div>
            <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                <button className="btn btn-primary" type="button"
                    onClick={() => { setShowModal(true); setSelectedCollectivePlanningItem(null); }}>Toevoegen</button>
            </div>
            {modal}
        </div >
    );


}

export default CollectivePlanningItems;