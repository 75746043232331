import { IPublicClientApplication } from "@azure/msal-browser";
import TaskModel from "../Models/TaskModel";
import { apiLoginRequest } from "../Config";

export default class TasksService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async get(projectCode: string): Promise<TaskModel[]> {

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const data = await fetch(`${baseUrl}/api/Tasks?projectcode=${projectCode}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    public async upsert(data: TaskModel) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(`${baseUrl}/api/Tasks`, {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify(data)
        });
    }

    public async getOpen(personid: string): Promise<TaskModel[]> {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const data = await fetch(`${baseUrl}/api/Tasks?personid=${personid}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}