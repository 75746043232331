import {
    ColumnDef,
    useReactTable,
    getCoreRowModel,
    flexRender,
    getSortedRowModel,
    SortingState,
    getFilteredRowModel,
} from '@tanstack/react-table';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Button } from "reactstrap";
import { useState } from 'react';

interface DataTableProps {
    data: unknown[];
    columns: ColumnDef<unknown, any>[];
    height?: string | undefined; //"65vh"
}

const DataTable = (props: DataTableProps) => {

    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        //getColumnCanGlobalFilter
        state: {
            sorting,
            globalFilter
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
    });

    const exportToExcel = () => {
        const data = table.getRowModel().rows.map((row) => {

            const headerValueData = row.getVisibleCells().map((cell) => {
                if (cell.column.columnDef.header !== undefined) {
                    console.log(cell.column.columnDef.header);
                    if (typeof (cell.column.columnDef.header) === "string") {
                        return {
                            key: cell.column.columnDef.header as string ?? "",
                            value: cell.getValue() as any
                        };
                    }
                    else {
                        return {
                            key: cell.column.columnDef.id ?? "",
                            value: cell.getValue() as any
                        };
                    }
                }
            });

            let dataRow: any = {};
            for (let entry of headerValueData.filter(r => r !== undefined)) {
                if (entry !== undefined)
                    dataRow[entry.key] = entry.value;
            }

            return dataRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        saveAs(blob, "data.xlsx");
    };

    return (
        <div className="card">
            <div className="card-body p-0">
                <form className="m-3">
                    <div className="row">
                        <label className="col-1 col-form-label" htmlFor="yearInput">Zoeken</label>
                        <div className="col-2">
                            <input type="text" className="form-control " onChange={(e) => setGlobalFilter(e.target.value)} value={globalFilter}></input>
                        </div>
                        <Button color="secondary" onClick={exportToExcel}>Excel</Button>
                    </div>
                </form>
                <div className="table-responsive" style={{
                    height: props.height,
                }}>
                    <table className="table table-hover table-sm" style={{ borderCollapse: "separate", borderSpacing: 0 }}>
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} style={{
                                    position: "sticky",
                                    top: 0,
                                    backgroundColor: "#ffffff"
                                }}>
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id} style={{
                                            width: header.getSize() !== 150 ? `${header.getSize()}px` : 'auto',
                                            whiteSpace: "nowrap"
                                        }}>
                                            {header.isPlaceholder
                                                ? null
                                                : (
                                                    <span
                                                        style={{ cursor: header.column.getCanSort() ? 'pointer' : '' }}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {{
                                                            asc: <span className="fa-stack">
                                                                <i className="fas fa-sort-up fa-stack-1x"></i>
                                                                <i className="fas fa-sort-down fa-stack-1x" style={{ color: "#e3e6ed" }} ></i>
                                                            </span>,
                                                            desc: <span className="fa-stack">
                                                                <i className="fas fa-sort-down fa-stack-1x"></i>
                                                                <i className="fas fa-sort-up fa-stack-1x" style={{ color: "#e3e6ed" }} ></i>
                                                            </span>,
                                                        }[header.column.getIsSorted() as string] ?? (header.column.getCanSort() ? <span className="fa-stack">
                                                            <i className="fas fa-sort-down fa-stack-1x" style={{ color: "#e3e6ed" }} ></i>
                                                            <i className="fas fa-sort-up fa-stack-1x" style={{ color: "#e3e6ed" }}></i>
                                                        </span> : null)
                                                        }
                                                    </span>
                                                )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} style={{
                                            whiteSpace: "nowrap"
                                        }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            {table.getFooterGroups().map(footerGroup => (
                                <tr key={footerGroup.id}>
                                    {footerGroup.headers.map(header => (
                                        <th key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.footer,
                                                    header.getContext()
                                                )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>
                    </table>
                </div>
                <div className="m-3">
                    {globalFilter === "" ? <></> : <>{table.getRowModel().rows.length} van </>}{table.options.data.length} resultaten
                </div>
            </div>
        </div>
    );
};

export const renderDateTime = (value: string) => {
    if (value === undefined || value === null || value === "")
        return "";
    return (new Date(value)).toLocaleString('nl-BE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
}

export const renderDate = (value: string) => {
    if (value === undefined || value === null || value === "")
        return "";
    return (new Date(value)).toLocaleString('nl-BE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

export const renderDays = (value: number) => {

    if (value !== undefined && value !== null) {
        value = value * 10;
        value = Math.round(value / 5) * 5;
        value = value / 10;
        let returnValue = value?.toLocaleString('nl-BE', { maximumFractionDigits: 1, minimumFractionDigits: 1 });

        if (returnValue === '-0,0' || returnValue === '0')
            returnValue = '0,0';
        return returnValue;
    }

    return value;
}

export const renderDecimal = (value: number) => {

    if (value !== undefined && value !== null) {
        return value?.toLocaleString('nl-BE', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    return value;
}

export const renderEllipsis = (value: string) => {
    let cutoff = 10;

    if (value === null)
        return "";

    if (value.length <= cutoff) {
        return value;
    }

    let shortened = value.substring(0, cutoff - 1);

    return <span className="ellipsis" title={escHtml(value)}>{shortened}&#8230;</span>;
}

function escHtml(text: string) {
    return text.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
}

export default DataTable;