import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ProjectsService from '../Services/ProjectsService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import GraphService from '../Services/GraphService';

interface RequestCiawModalProps {
    code: string;
    emails: string;
    afterSave: Function;
    afterCancel: Function;
}

const RequestCiawModal = (props: RequestCiawModalProps) => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [emails, setEmails] = useState<string>(props.emails);
    const [visible, setVisible] = useState<boolean>(true);

    function toggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function save() {
        try {
            if (props.code !== null) {
                const user = await graphService.getUserDetails();
                await projectsService.requestCiawFunction(props.code, emails, user.id ?? "");

                setVisible(false);

                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    return (
        <Modal isOpen={visible} toggle={toggle} className="mw-100 w-75">
            <ModalHeader toggle={toggle}>{props.code} - CIAW nr vragen</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <label className="my-1 mr-2" title='; gescheiden lijst van email adressen'>Email verzenden naar</label>
                        <input type="text" className="form-control " onChange={(e) => setEmails(e.target.value)} value={emails}></input>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>Verzenden</Button>{' '}
                <Button color="secondary" onClick={toggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default RequestCiawModal;