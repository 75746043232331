import React, { useEffect, useMemo, useState } from 'react';
import PlanningService from '../Services/PlanningService';
import ProjectModel from '../Models/ProjectModel';
import { PlanningItemTypeModel } from '../Models/PlanningItemTypeModel';
import PlanningItemTypesService from '../Services/PlanningItemTypesService';
import AliveService from '../Services/AliveService';
import PlanningCalendar from '../Components/PlanningCalendar';
import ConfirmationModal from '../Components/ConfirmationModal';
import './Planning.css';
import { PlanningItemModel } from '../Models/PlanningItemModel';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import GraphService from '../Services/GraphService';
import Select, { StylesConfig } from 'react-select'
import PlanningItemDescriptionModal from '../Components/PlanningItemDescriptionModal';
import { PlanningResourceModel } from '../Models/PlanningResourceModel';

const Planning = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const planningService = useMemo(() => new PlanningService(instance), [instance]);
    const planningItemTypesService = useMemo(() => new PlanningItemTypesService(instance), [instance]);
    const aliveService = useMemo(() => new AliveService(instance), [instance]);

    const projectsMpFilterActiveL = localStorage.getItem("PTPlanProjectsMpFilterActive");
    const projectsMpFilterActiveParsed = projectsMpFilterActiveL !== null ? JSON.parse(projectsMpFilterActiveL) : null;
    const projectsMkFilterActiveL = localStorage.getItem("PTPlanProjectsMkFilterActive");
    const projectsMkFilterActiveParsed = projectsMkFilterActiveL !== null ? JSON.parse(projectsMkFilterActiveL) : null;
    const projectsAllFilterActiveL = localStorage.getItem("PTPlanProjectsAllFilterActive");
    const projectsAllFilterActiveParsed = projectsAllFilterActiveL !== null ? JSON.parse(projectsAllFilterActiveL) : null;

    const selectedGroupsL = localStorage.getItem("PTPlanSelectedGroups");
    const selectedGroupsLParsed = selectedGroupsL !== null ? JSON.parse(selectedGroupsL) : [];

    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [projectsFilter, setProjectsFilter] = useState<ProjectModel[]>([]);
    const [planningItemTypes, setPlanningItemTypes] = useState<any[]>([]);
    const [planningItemTypesFilter, setPlanningItemTypesFilter] = useState<any[]>([]);
    const [projectsMpFilterActive, setProjectsMpFilterActive] = useState<boolean>(projectsMpFilterActiveParsed ?? true);
    const [projectsMkFilterActive, setProjectsMkFilterActive] = useState<boolean>(projectsMkFilterActiveParsed ?? true);
    const [projectsAllFilterActive, setProjectsAllFilterActive] = useState<boolean>(projectsAllFilterActiveParsed ?? true);
    const [projectsFilterText, setProjectsFilterText] = useState<string>("");
    const [selectedProjectPlanningItemType, setSelectedProjectPlanningItemType] = useState<string | null>(null);
    const [planningItemsToDelete, setPlanningItemsToDelete] = useState<number[]>([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);
    const [eventItem, setEventItem] = useState<PlanningItemModel | null>(null);
    const [planningSlo, setPlanningSlo] = useState<boolean>();
    const [groups, setGroups] = useState<string[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<string[]>(selectedGroupsLParsed);
    const [resources, setResources] = useState<PlanningResourceModel[]>([]);
    const [selectedResources, setSelectedResources] = useState<PlanningResourceModel[]>([]);

    const trashRef = React.createRef<HTMLDivElement>();
    const planningCalendarRef = React.createRef<PlanningCalendar>();

    useEffect(() => {
        async function loadData() {
            try {
                let planningSlo = await graphService.hasRole('planner.slo');

                setPlanningSlo(planningSlo);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [graphService]);

    useEffect(() => {
        async function loadData() {

            if (planningSlo === undefined)
                return;

            try {
                let planningItemTypes = await planningItemTypesService.getAllPlanningItemTypes();
                const planningResources = await planningService.getResources();

                const groups = planningResources.map(r => r.groupName ?? "")
                    .sort()
                    .filter((el, i, a) => { return i === a.indexOf(el) })
                    .map(r => r === "" ? "<leeg>" : r);

                if (planningSlo === true) {
                    planningItemTypes = planningItemTypes.filter(o => o.code === "SLO");

                    setProjects([]);
                    setProjectsFilter([]);
                    setPlanningItemTypes(planningItemTypes);
                    setPlanningItemTypesFilter(planningItemTypes);
                    setGroups(groups);
                    setResources(planningResources);
                }
                else {
                    let projects = await planningService.getProjects();
                    let filteredProject = projects.filter(o => o.finished === false);

                    setProjects(projects);
                    setProjectsFilter(filteredProject);
                    setPlanningItemTypes(planningItemTypes);
                    setPlanningItemTypesFilter(planningItemTypes);
                    setGroups(groups);
                    setResources(planningResources);
                }
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [planningService, planningItemTypesService, planningSlo]);

    const alive = React.useCallback(async () => {
        await aliveService.isAlive();
    }, [aliveService]);

    React.useEffect(() => {
        const interval = setInterval(async () => {
            alive();
        }, 1000 * 60 * 5);
        return () => clearInterval(interval);
    }, [alive]);

    function planningItemClick(id: number): boolean {

        let added = false;

        if (planningItemsToDelete.indexOf(id) < 0) {
            planningItemsToDelete.push(id);
            added = true;
        }
        else {
            planningItemsToDelete.splice(planningItemsToDelete.indexOf(id));
            added = false;
        }

        setPlanningItemsToDelete(planningItemsToDelete);

        return added;
    }

    function clearPlanningItemToDelete() {
        setPlanningItemsToDelete([]);
    }

    function bcEventDropped(item: PlanningItemModel) {
        setEventItem(item);
        setShowConfirmationModal(true);
    }

    function confirmationModalAfterClose() {
        setEventItem(null);
        setShowConfirmationModal(false);
    }

    async function confirmationModalAfterOk() {
        if (planningCalendarRef.current !== null && eventItem !== null) {
            await planningCalendarRef.current.addEvent(eventItem, true);
        }
        setEventItem(null);
        setShowConfirmationModal(false);
    }

    function descriptionEventDropped(item: PlanningItemModel) {
        setEventItem(item);
        setShowDescriptionModal(true);
    }

    function descriptionEventClicked(item: PlanningItemModel) {
        setEventItem(item);
        setShowDescriptionModal(true);
    }

    function descriptionModalAfterClose() {
        setEventItem(null);
        setShowDescriptionModal(false);
    }

    async function descriptionModalAfterSave(description: string) {
        if (planningCalendarRef.current !== null && eventItem !== null) {
            eventItem.description = description;
            await planningCalendarRef.current.addEvent(eventItem, false);
        }
        setEventItem(null);
        setShowDescriptionModal(false);
    }

    async function deletePlanningItems() {
        try {
            if (planningItemsToDelete.length > 0) {
                await planningService.deleteItems(planningItemsToDelete, planningSlo ?? false);

                if (planningCalendarRef.current !== null)
                    planningCalendarRef.current.removeEvents(planningItemsToDelete);

                planningItemsToDelete.splice(0, planningItemsToDelete.length);
            }
        }
        catch (err) {
            toast.error('Fout bij het verwijderen.');
        }
    }

    function handleSearchProjects(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "projectsMpFilterActive")
            searchProjects(event.target.checked, null, null, null);
        else if (event.target.value === "projectsMkFilterActive")
            searchProjects(null, event.target.checked, null, null);
        else if (event.target.value === "projectsAllFilterActive")
            searchProjects(null, null, event.target.checked, null);
        else
            searchProjects(null, null, null, event.target.value);
    }

    async function handleSelectedProjects(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            setSelectedProjectPlanningItemType(event.target.value);
        }
        else {
            setSelectedProjectPlanningItemType(null);
        }
    }

    function searchProjects(projectsMpFilterActiveParam: boolean | null, projectsMkFilterActiveParam: boolean | null,
        projectsAllFilterActiveParam: boolean | null, projectsFilterTextParam: string | null) {
        if (projectsMpFilterActiveParam === null)
            projectsMpFilterActiveParam = projectsMpFilterActive;

        if (projectsMkFilterActiveParam === null)
            projectsMkFilterActiveParam = projectsMkFilterActive;

        if (projectsAllFilterActiveParam === null)
            projectsAllFilterActiveParam = projectsAllFilterActive;

        if (projectsFilterTextParam === null)
            projectsFilterTextParam = projectsFilterText;

        let foundProjects = projects;

        let searchTerm = (projectsFilterText ?? "").toUpperCase();

        if (searchTerm.length > 0)
            foundProjects = foundProjects.filter(o => (o.tooltip?.toUpperCase().indexOf(searchTerm) !== -1) || (o.code?.toUpperCase().indexOf(searchTerm) !== -1));
        if (!projectsMpFilterActiveParam)
            foundProjects = foundProjects.filter(o => o.adsolutDossier !== "000072");
        if (!projectsMkFilterActiveParam)
            foundProjects = foundProjects.filter(o => o.adsolutDossier !== "000073");
        if (!projectsAllFilterActiveParam)
            foundProjects = foundProjects.filter(o => o.finished === false);

        localStorage.setItem("PTPlanProjectsMpFilterActive", JSON.stringify(projectsMpFilterActiveParam));
        localStorage.setItem("PTPlanProjectsMkFilterActive", JSON.stringify(projectsMkFilterActiveParam));
        localStorage.setItem("PTPlanProjectsAllFilterActive", JSON.stringify(projectsAllFilterActiveParam));

        setProjectsMpFilterActive(projectsMpFilterActiveParam ?? false);
        setProjectsMkFilterActive(projectsMkFilterActiveParam ?? false);
        setProjectsAllFilterActive(projectsAllFilterActiveParam ?? false);
        setProjectsFilterText(projectsFilterTextParam ?? "");
        setProjectsFilter(foundProjects);
    }

    function handleSearchPlanningItemTypes(event: React.ChangeEvent<HTMLInputElement>) {
        const searchTerm = event.target.value.toUpperCase();
        let foundPlanningItemTypes = planningItemTypes.filter(o => o.code?.toUpperCase().indexOf(searchTerm) !== -1);
        setPlanningItemTypesFilter(foundPlanningItemTypes);
    }

    let confirmationModal = null;

    if (showConfirmationModal) {
        confirmationModal = <ConfirmationModal
            text="Alle toekomstige planningitems voor deze persoon verwijderen?"
            afterCancel={confirmationModalAfterClose}
            afterOk={confirmationModalAfterOk} />
    }

    let descriptionModal = null;

    if (showDescriptionModal) {
        descriptionModal = <PlanningItemDescriptionModal
            description={eventItem?.description ?? "PJ"}
            afterCancel={descriptionModalAfterClose}
            afterSave={descriptionModalAfterSave} />
    }

    const selectStyles: StylesConfig = {
        control: (styles: any) => ({
            ...styles,
            width: '207px',

        }),
        valueContainer: (styles: any) => ({
            ...styles,
            maxHeight: '100px',
            overflowY: "auto"
        })
    };

    function OnGroupsChange(values: any, actionMeta: any) {

        const selectedGroupsValue = values.map((r: any) => r.value);

        localStorage.setItem("PTPlanSelectedGroups", JSON.stringify(selectedGroupsValue));

        setSelectedGroups(selectedGroupsValue);
    }

    function OnResoucesChange(values: any, actionMeta: any) {
        const selectedResourceIds = values.map((r: { value: string; label: string }) => r.value);

        const selectedResources = resources.filter(r => selectedResourceIds.indexOf(r.id) >= 0);

        setSelectedResources(selectedResources);
    }

    return (
        <div className="d-flex flex-grow-1">
            <div className="flex-grow-1">
                <PlanningCalendar ref={planningCalendarRef}
                    trashRef={trashRef}
                    selectedProjectPlanningItemType={selectedProjectPlanningItemType}
                    planningItemClick={planningItemClick}
                    clearPlanningItemToDelete={clearPlanningItemToDelete}
                    bcEventDropped={bcEventDropped}
                    descriptionEventDropped={descriptionEventDropped}
                    descriptionEventClicked={descriptionEventClicked}
                    groupsFilter={selectedGroups}
                    resourcesFilter={selectedResources.map(r => r.id)} />
            </div>
            <div className="w-10">
                <div className="card ml-2" ref={trashRef}
                    onClick={deletePlanningItems}
                    style={{ cursor: planningItemsToDelete.length > 0 ? "pointer" : "auto" }}
                    title="Sleep je planning items hier om ze te verwijderen van de kalender.">
                    <div className="card-body p-2 mx-auto">
                        <span style={{ fontSize: "1.1rem" }}>
                            <i className="fas fa-trash-alt fa-2x"></i>
                        </span>
                    </div>
                </div>
                <div className="card ml-2 mt-2">
                    <div className="card-body p-2">
                        <form>
                            <Select
                                options={groups.map((group) => { return { value: group, label: group }; })}
                                defaultValue={selectedGroups.map((group) => { return { value: group, label: group }; })}
                                isMulti
                                styles={selectStyles}
                                onChange={OnGroupsChange}
                                placeholder={<>Filter groepen...</>}
                            />
                            <Select
                                options={resources.map((res) => { return { value: res.id, label: res.name }; })}
                                defaultValue={selectedResources.map((res) => { return { value: res.id, label: res.name }; })}
                                isMulti
                                styles={selectStyles}
                                onChange={OnResoucesChange}
                                placeholder={<>Filter personen/OA...</>}
                            />
                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="projectsMpFilterActive" checked={projectsMpFilterActive}
                                            onChange={handleSearchProjects} id="projectsMpFilter" />
                                        <label className="form-check-label" htmlFor="projectsMpFilter">
                                            MP
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="projectsMkFilterActive" checked={projectsMkFilterActive}
                                            onChange={handleSearchProjects} id="projectsMkFilter" />
                                        <label className="form-check-label" htmlFor="projectsMkFilter">
                                            MK
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-check" title="Ook uitgevoerde projecten tonen">
                                        <input className="form-check-input" type="checkbox" value="projectsAllFilterActive" checked={projectsAllFilterActive}
                                            onChange={handleSearchProjects} id="projectsAllFilter" />
                                        <label className="form-check-label" htmlFor="projectsAllFilter">
                                            Alle
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <input type="text" onChange={handleSearchProjects} className="form-control" placeholder="Zoek project" value={projectsFilterText} />
                        </form>
                        <div id="external-events-projects" className="list-group">
                            {
                                projectsFilter.map((project: ProjectModel) => {
                                    return (
                                        <a href="#" className="list-group-item list-group-item-action p-1 fc-event" key={project.code}
                                            title={project.tooltip}
                                            data-backgroundcolor={project.backgroundColor}
                                            data-textcolor={project.color}>
                                            {project.code}
                                            <input type="checkbox" value={project.code} className="float-right mt-1"
                                                checked={selectedProjectPlanningItemType === project.code} onChange={handleSelectedProjects} />
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="card ml-2 mt-2">
                    <div className="card-body p-2">
                        <form>
                            <input type="text" onChange={handleSearchPlanningItemTypes} className="form-control" placeholder="Zoek verloftype" />
                        </form>
                        <div id="external-events-planningitemtypes" className="list-group">
                            {
                                planningItemTypesFilter.map((planningItemType: PlanningItemTypeModel) => {
                                    return (
                                        <a href="#" className="list-group-item list-group-item-action p-1 fc-event"
                                            key={planningItemType.id}
                                            title={planningItemType.description + " (" + planningItemType.code + ")"} data-id={planningItemType.id}
                                            data-backgroundcolor={planningItemType.backgroundColorCode}
                                            data-textcolor={planningItemType.textColorCode}
                                            data-code={planningItemType.code}  >
                                            {planningItemType.description} ({planningItemType.code})
                                            <input type="checkbox" value={planningItemType.id} className="float-right mt-1"
                                                checked={selectedProjectPlanningItemType === planningItemType.id?.toString()} onChange={handleSelectedProjects} />
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {confirmationModal}
            {descriptionModal}
        </div >

    );
}

export default (Planning);