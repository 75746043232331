import React, { useEffect, useMemo, useState } from "react";
import PlanningService from "../Services/PlanningService";
import Checkbox from "../Components/Checkbox";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import DataTable, { renderDays } from "../Components/DataTable";

interface LooseDataObject {
    displayName: string;
    groupName: string;
    [key: string]: any;
}

interface GroupNameFilterValueState {
    filterValue: string;
    checked: boolean;
}

const columnHelper = createColumnHelper<LooseDataObject>();

const columns = [
    columnHelper.accessor('displayName', {
        header: 'Naam',
        cell: props => props.getValue(),
        size: 200
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('groupName', {
        header: 'Groep',
        cell: props => props.getValue(),
        size: 100
    }) as ColumnDef<unknown, any>,
    columnHelper.display({
        id: "sep1",
        header: '',
        cell: props => <>|</>,
        size: 5
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('V (totaal)', {
        header: 'V (totaal)',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 130
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('V', {
        header: 'V',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.display({
        id: "sep2",
        header: '',
        cell: props => <>|</>,
        size: 5
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('RB', {
        header: 'RB',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 80
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('F (totaal)', {
        header: 'F (totaal)',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 130
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('F', {
        header: 'F',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.display({
        id: "sep3",
        header: '',
        cell: props => <>|</>,
        size: 5
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('RECUP', {
        header: 'RECUP',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 100
    }) as ColumnDef<unknown, any>,
    columnHelper.display({
        id: "sep4",
        header: '',
        cell: props => <>|</>,
        size: 5
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('Z', {
        header: 'Z',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('KV', {
        header: 'KV',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 80
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('FV', {
        header: 'FV',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('TA', {
        header: 'TA',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('ECO', {
        header: 'ECO',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 90
    }) as ColumnDef<unknown, any>,
    columnHelper.display({
        id: "sep5",
        header: '',
        cell: props => <>|</>,
        size: 5
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('BFI', {
        header: 'BFI',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 90
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('EXTRA', {
        header: 'EXTRA',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 110
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('ManCIAW', {
        header: 'Man. CIAW',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 110
    }) as ColumnDef<unknown, any>,
    columnHelper.display({
        id: "end",
        header: '',
        cell: props => '',
    }) as ColumnDef<unknown, any>,
];

const PeopleLeaveDaysOverview = () => {

    const { instance } = useMsal();

    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    const [peopleLeaveDays, setPeopleLeaveDays] = useState<LooseDataObject[]>([]);
    const [filteredPeopleLeaveDays, setFilteredPeopleLeaveDays] = useState<LooseDataObject[]>([]);
    const [groupNameFilterValues, setGroupNameFilterValues] = useState<GroupNameFilterValueState[]>([]);

    useEffect(() => {
        async function loadData(year: number) {
            try {

                let peopleLeaveDays = await planningService.getPeopleLeaveDays(year);

                let personIdList = peopleLeaveDays.map(r => r.personId)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .filter((value) => value !== undefined && value !== null && value !== "");

                let counterDescriptionList = peopleLeaveDays.map(r => r.description)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .filter((value) => value !== undefined && value !== null && value !== "");
                counterDescriptionList = counterDescriptionList.sort((r1, r2) => r1.localeCompare(r2));

                let data: LooseDataObject[] = [];

                personIdList.forEach(personId => {
                    let groupNameRec = peopleLeaveDays.find((r) => r.personId === personId);

                    let record: LooseDataObject = {
                        displayName: groupNameRec?.name ?? "<Geen naam>",
                        groupName: groupNameRec?.groupName ?? ""
                    };

                    columns.forEach((column: any) => {
                        if (column.accessorKey !== "displayName" && column.accessorKey !== "groupName") {
                            let counterValue = peopleLeaveDays.find((r) => r.personId === personId && r.description === column.accessorKey);
                            record[column.accessorKey] = counterValue?.count ?? 0;
                        }
                    });

                    data.push(record);
                });

                data = data.sort((r1, r2) => r1.displayName.localeCompare(r2.displayName));

                let groupNameList = peopleLeaveDays.map(r => r.groupName)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .filter((value) => value !== undefined && value !== null && value !== "");
                let groupNameFilterValues = groupNameList.map((value) => {
                    return {
                        filterValue: value,
                        checked: true
                    }
                });

                setPeopleLeaveDays(data);
                setFilteredPeopleLeaveDays(data);
                setGroupNameFilterValues(groupNameFilterValues);

            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData((new Date()).getFullYear());
    }, [planningService]);

    async function handleGroupNameFilter(event: React.ChangeEvent<HTMLInputElement>) {

        let newGroupNameFilterValues = [...groupNameFilterValues];
        newGroupNameFilterValues.forEach(groupNameFilterValue => {
            if ((groupNameFilterValue.filterValue ?? "null") === event.target.value)
                groupNameFilterValue.checked = event.target.checked;
        });

        let filteredPeopleLeaveDays = peopleLeaveDays.filter((r) =>
            newGroupNameFilterValues.find((f) => f.filterValue === r.groupName)?.checked
        );

        setGroupNameFilterValues(newGroupNameFilterValues);
        setFilteredPeopleLeaveDays(filteredPeopleLeaveDays);
    }

    if (peopleLeaveDays && columns && columns.length > 0) {
        let filters =
            <div className="card mb-3">
                <div className="card-body">
                    <form>
                        <div className=" row">
                            <label className="col-1 col-form-label">Groep</label>
                            <div className="col-11 form-inline">
                                {
                                    groupNameFilterValues.sort((r1, r2) => (r1.filterValue ?? "").localeCompare(r2.filterValue ?? "")).map((groupName) => {
                                        return (
                                            <Checkbox key={groupName.filterValue} className="mr-4"
                                                label={groupName.filterValue} value={groupName.filterValue}
                                                checked={groupName.checked} onChange={handleGroupNameFilter} />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>;

        return (
            <div className="container-fluid">
                {filters}
                <DataTable
                    data={filteredPeopleLeaveDays}
                    columns={columns}
                    height="65vh"
                />
            </div>
        );
    }

    return (
        <></>
    );

}

export default (PeopleLeaveDaysOverview);