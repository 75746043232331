import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'reactstrap';
import ProjectsService from '../Services/ProjectsService';
import ProjectModel from '../Models/ProjectModel';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import Checkbox from '../Components/Checkbox';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import DataTable, { renderDecimal, renderDays } from '../Components/DataTable';

interface StatusFilterValueState {
    filterValue: string;
    checked: boolean;
}

const columnHelper = createColumnHelper<ProjectModel>();

const columns = [
    columnHelper.accessor('code', {
        header: 'Projectnr',
        cell: props => props.getValue(),
        size: 140
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('customerName', {
        header: 'Klant',
        cell: props => props.getValue(),
        size: 250
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('name', {
        header: 'Omschrijving',
        cell: props => props.getValue(),
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('status', {
        header: 'Status',
        cell: props => props.getValue(),
        size: 130
        //searchable: false
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('totalDaysPlannedThisMonth', {
        header: 'Maand',
        cell: props => <div className='text-right pr-3'>{renderDays(props.getValue())}</div>,
        size: 110
        //searchable: false
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('totalOrderedWithExtraCostsThisMonth', {
        header: 'Maand order',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        size: 180
        //searchable: false
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('totalOrderedWithExtraCosts', {
        header: 'Tot. order',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        size: 160
        //searchable: false
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('totalOrderedSubcontractors', {
        header: 'Tot. best. OA',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        size: 160
        //searchable: false
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('totalExtraCosts', {
        header: 'Tot. best. OK',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        size: 160
        //searchable: false
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('totalInvoiced', {
        header: 'Tot. verkoop',
        cell: props => <div className='text-right pr-3'>{renderDecimal(props.getValue())}</div>,
        size: 160
        //searchable: false
    }) as ColumnDef<unknown, any>
];

const Wiu = () => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const showMkAdsolutProjectsL = localStorage.getItem("PTWiuAdsolutProjectMK");
    const showMkAdsolutProjectsParsed = showMkAdsolutProjectsL !== null ? JSON.parse(showMkAdsolutProjectsL) : null;

    const showMpAdsolutProjectsL = localStorage.getItem("PTPWiuAdsolutProjectMP");
    const showMpAdsolutProjectsParsed = showMpAdsolutProjectsL !== null ? JSON.parse(showMpAdsolutProjectsL) : null;

    const statusFilter = localStorage.getItem("PTWiuStatusFilter");
    const statusFilterParsed: StatusFilterValueState[] = statusFilter !== null ? JSON.parse(statusFilter) : null;

    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(true);

    const [year, setYear] = useState<string>(new Date().getFullYear().toString());
    const [month, setMonth] = useState<string>((new Date().getMonth() + 1).toString());

    const [showMpAdsolutProjects, setShowMpAdsolutProjects] = useState<boolean>(showMpAdsolutProjectsParsed ?? false);
    const [showMkAdsolutProjects, setShowMkAdsolutProjects] = useState<boolean>(showMkAdsolutProjectsParsed ?? false);
    const [statusFilterValues, setStatusFilterValues] = useState<StatusFilterValueState[]>(statusFilterParsed ?? []);

    useEffect(() => {

        async function loadProjects() {
            try {
                const statusFilterValuesL = await projectsService.getStatusFilterValues();

                const statusFilterValuesForState = statusFilterValuesL.map((value) => {
                    return {
                        filterValue: value,
                        checked: statusFilterParsed?.find(r => r.filterValue === value)?.checked ?? false
                    }
                });

                setStatusFilterValues(statusFilterValuesForState);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadProjects();
    }, [projectsService]);

    useEffect(() => {

        async function loadProjects() {
            try {
                let selectedFilterValues = statusFilterValues
                    .filter(statusFilterValue => statusFilterValue.checked)
                    .map(statusFilterValue => statusFilterValue.filterValue ?? "null");

                let projects = await projectsService.getWiuProjects(selectedFilterValues, showMkAdsolutProjects, showMpAdsolutProjects, year, month);

                setProjects(projects);
                setShowLoadingSpinner(false);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadProjects();
    }, [projectsService, showMkAdsolutProjects, showMpAdsolutProjects, statusFilterValues, year, month]);

    async function handleAdsolutProjectsFilter(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value === "MK") {
            localStorage.setItem("PTWiuAdsolutProjectMK", JSON.stringify(event.target.checked));

            setShowMkAdsolutProjects(event.target.checked);
            setShowLoadingSpinner(true);
        } else if (event.target.value === "MP") {
            localStorage.setItem("PTWiuAdsolutProjectMP", JSON.stringify(event.target.checked));

            setShowMpAdsolutProjects(event.target.checked);
            setShowLoadingSpinner(true);
        }
    }

    async function handleStatusFilter(event: React.ChangeEvent<HTMLInputElement>) {
        let newstatusFilterValues = [...statusFilterValues];

        newstatusFilterValues.forEach(statusFilterValue => {
            if ((statusFilterValue.filterValue ?? "null") === event.target.value)
                statusFilterValue.checked = event.target.checked;
        });

        localStorage.setItem("PTWiuStatusFilter", JSON.stringify(newstatusFilterValues));

        setStatusFilterValues(newstatusFilterValues);
        setShowLoadingSpinner(true);
    }

    if (projects) {
        let table = null;
        if (showLoadingSpinner) {
            table = <div className="d-flex justify-content-center"><Spinner type="grow" color="secondary" className="m-5" /></div>
        }
        else {
            table = <DataTable
                data={projects}
                columns={columns}
                height="60vh"
            />
        }

        let filters =
            <div className="card mb-3">
                <div className="card-body">
                    <form>
                        <div className=" row">
                            <label className="col-1 col-form-label">Adsolut dossier</label>
                            <div className="col-2 form-inline">
                                <Checkbox className="mr-4" label="MP" value="MP" checked={showMpAdsolutProjects} onChange={(event: any) => handleAdsolutProjectsFilter(event)} />
                                <Checkbox className="mr-4" label="MK" value="MK" checked={showMkAdsolutProjects} onChange={(event: any) => handleAdsolutProjectsFilter(event)} />
                            </div>
                            <label className="col-1 col-form-label" htmlFor="yearInput">Jaar</label>
                            <div className="col-2 form-inline">
                                <input type="text" className="form-control" id="yearInput"
                                    onChange={(event) => setYear(event.target.value)}
                                    value={year} />
                            </div>
                            <label className="col-1 col-form-label" htmlFor="monthInput">Maand</label>
                            <div className="col-2 form-inline">
                                <input type="text" className="form-control" id="monthInput"
                                    onChange={(event) => setMonth(event.target.value)}
                                    value={month} />
                            </div>
                        </div>
                        <div className=" row">
                            <label className="col-1 col-form-label">Status</label>
                            <div className="col-11 form-inline">
                                {
                                    statusFilterValues.sort((r1, r2) => (r1.filterValue ?? "").localeCompare(r2.filterValue ?? "")).map((status) => {
                                        return (
                                            <Checkbox className="mr-4" label={status.filterValue} value={status.filterValue} checked={status.checked} onChange={(event: any) => handleStatusFilter(event)} key={status.filterValue} />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>;

        // let editProjectColumnValueModal = null;
        // if (editProjectColumnValueModalVisible && selectedProjectCode !== null && selectedProjectColumn !== null) {
        //     editProjectColumnValueModal =
        //         <EditProjectColumnValueModal
        //             code={selectedProjectCode}
        //             columnName={selectedProjectColumn}
        //             columnValue={selectedProjectColumnValue ?? 0}
        //             afterSave={editProjectColumnValueModalAfterSave}
        //             afterCancel={() => setEditProjectColumnValueModalVisible(false)} />
        // }

        return (
            <div className="container-fluid">
                {filters}
                {table}
            </div>
        );
    }

    return (
        <div className="container-fluid">
        </div>
    );
}

export default Wiu;