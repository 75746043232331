import { useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseJSON } from 'date-fns';
import GraphService from '../Services/GraphService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import Checkbox from '../Components/Checkbox';
import TasksService from '../Services/TasksService';
import TaskModel from '../Models/TaskModel';
import TaskModal from '../Components/TaskModal';
import { User } from '@microsoft/microsoft-graph-types';

const Tasks = () => {

    const { instance } = useMsal();

    const tasksService = useMemo(() => new TasksService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);

    const [currentUser, setCurrentUser] = useState<User>();
    const [tasks, setTasks] = useState<TaskModel[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<TaskModel>();

    const loadTasks = useCallback(
        async () => {
            const user = await graphService.getUserDetails();

            if (user.id !== undefined) {
                if (showAll) {
                    const tasks = await tasksService.getOpen('');
                    setTasks(tasks);
                }
                else {
                    const tasks = await tasksService.getOpen(user.id);
                    setTasks(tasks);
                }
            }
        },
        [tasksService, graphService, showAll]
    );

    useEffect(() => {
        async function loadData() {
            try {
                const user = await graphService.getUserDetails();
                setCurrentUser(user);

                await loadTasks();
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [tasksService, graphService, showAll, loadTasks]);

    async function setCompleted(task: TaskModel) {
        try {
            task.completed = true;
            await tasksService.upsert(task);

            await loadTasks();
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    async function afterSave() {
        try {
            setShowModal(false);

            await loadTasks();
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function afterCancel() {
        setShowModal(false);
    }

    let modal = null;
    if (showModal && selectedTask) {
        modal =
            <TaskModal
                task={selectedTask}
                afterSave={() => afterSave()}
                afterCancel={() => afterCancel()}
            />
    }

    return (
        <div className="container-fluid">
            <div className="card mb-3">
                <div className="card-body">
                    <form>
                        <div className=" row">
                            <div className="col-2 form-inline">
                                <Checkbox className="mr-4" label="Alle" value="Alle" checked={showAll} onChange={() => setShowAll(!showAll)} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Project</th>
                        <th>Taak</th>
                        {showAll && <th>Voor</th>}
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks.map((record, index) => {
                            return (
                                <tr key={index}>
                                    <td>{record.date === undefined ? null : format(parseJSON(record.date), "dd/MM/yyyy")}</td>
                                    <td>{record.projectCode}</td>
                                    <td>{record.description}</td>
                                    {showAll && <td>{record.personName}</td>}
                                    <td>
                                        <button type='button' className="btn btn-link p-0" title="Aanpassen"
                                            onClick={() => { setShowModal(true); setSelectedTask(record); }}>
                                            <i className="fas fa-pen"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button type='button' className="btn btn-link p-0 text-success"
                                            title="Markeren als afgehandeld" onClick={() => setCompleted(record)}>
                                            <i className="fas fa-check"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className="row m-4">
                &nbsp;
            </div>
            <div className="container-fluid fixed-bottom pb-2 pt-2" style={{ backgroundColor: 'white' }}>
                <button className="btn btn-primary" type="button"
                    onClick={() => {
                        setShowModal(true); setSelectedTask({
                            completed: false,
                            personId: currentUser?.id,
                            date: format(new Date(), "yyyy-MM-dd") + "T00:00:00.000Z"
                        });
                    }}>Toevoegen</button>
            </div>
            {modal}
        </div>
    )

}
export default Tasks;