import React, { useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Checkbox from "../Components/Checkbox";
import ProjectsService from '../Services/ProjectsService';
import DatePicker, { registerLocale } from "react-datepicker";
import nlBE from 'date-fns/locale/nl-BE';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import PersonModel from '../Models/PersonModel';

interface ProjectSubcontractorsReportedModalProps {
    code: string;
    reported: boolean;
    reportedOn: Date | null;
    reportedBy: string | null;
    reportedMethod: string | null;
    owners: PersonModel[];
    afterSave: Function;
    afterCancel: Function;
}

const ProjectSubcontractorsReportedModal = (props: ProjectSubcontractorsReportedModalProps) => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [reported, setReported] = useState<boolean>(props.reported);
    const [reportedOn, setReportedOn] = useState<Date | null>(props.reportedOn);
    const [reportedBy, setReportedBy] = useState<string | null>(props.reportedBy);
    const [reportedMethod, setReportedMethod] = useState<string | null>(props.reportedMethod);
    const [visible, setVisible] = useState<boolean>(true);

    registerLocale('nlBE', nlBE);

    function handleReportedChange(event: React.ChangeEvent<HTMLInputElement>) {
        setReported(event.target.checked);
    }

    function handleReportedOnChange(date: Date) {
        setReportedOn(date);
    }

    function toggle() {
        setVisible(!visible);
        props.afterCancel();
    }

    async function modalSave() {
        try {
            if (props.code !== null) {

                await projectsService.updateSubcontractorsReported(props.code, reported, reportedOn, reportedBy, reportedMethod);

                setVisible(false);

                props.afterSave();
            }
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    return (
        <Modal isOpen={visible} toggle={toggle} className="mw-100 w-75">
            <ModalHeader toggle={toggle}>{props.code} - OA gemeld?</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <Checkbox className="my-1 mr-sm-2" label="OA gemeld?" value="true" checked={reported}
                            onChange={handleReportedChange} />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">OA gemeld op</label>
                        <DatePicker locale="nlBE" selected={reportedOn} onChange={handleReportedOnChange}
                            dateFormat="dd/MM/yyyy"
                            customInput={
                                <input
                                    type="text"
                                    className="form-control my-1 mr-sm-2"
                                />
                            }
                        />
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">OA gemeld door</label>
                        <select className="form-control my-1 mr-sm-2" onChange={(e) => setReportedBy(e.target.value)}
                            defaultValue={reportedBy ?? ""} >
                            <option value="null">- Geen opvolger -</option>
                            {
                                props.owners.map((owner) => {
                                    return (
                                        <option value={owner.id} key={owner.id}>{owner.name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>

                    <div className="form-group">
                        <label className="my-1 mr-2">OA gemeld met</label>
                        <select className="form-control my-1 mr-sm-2" onChange={(e) => setReportedMethod(e.target.value)}
                            defaultValue={reportedMethod ?? ""} >
                            <option value="null">- Geen waarde -</option>
                            <option value="Email">Email</option>
                            <option value="Telefoon">Telefoon</option>
                        </select>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={toggle}>Sluiten</Button>
            </ModalFooter>
        </Modal>
    );

}

export default ProjectSubcontractorsReportedModal;