import { useEffect, useMemo, useState } from 'react';
import StatusesService from '../Services/StatusesService';
import { AppStatusModel } from '../Models/AppStatusModel';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const Status = () => {

    const { instance } = useMsal();

    const statusesService = useMemo(() => new StatusesService(instance), [instance]);

    const [appStatuses, setAppStatuses] = useState<AppStatusModel[]>([]);

    useEffect(() => {
        async function loadData() {
            try {
                const statuses = await statusesService.getAppStatuses();
                setAppStatuses(statuses);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [statusesService]);





    if (appStatuses && appStatuses.length > 0) {
        return (
            <div className="container">
                {appStatuses.map((appStatus) => {
                    let lastModifiedDate = new Date(appStatus.lastModifiedDate);
                    let now = new Date();
                    let timeDiff = (now.getTime() - lastModifiedDate.getTime());
                    if (appStatus.status === "OK") {
                        if (timeDiff < 60 * 60 * 1000) {
                            return (
                                <div className="alert alert-success" role="alert">
                                    <strong>{appStatus.name}</strong> Laatste update: {lastModifiedDate.toLocaleString('nl-BE')}
                                </div>
                            );
                        } else {
                            return (
                                <div className="alert alert-warning" role="alert">
                                    <strong>{appStatus.name}</strong> Laatste update: {lastModifiedDate.toLocaleString('nl-BE')}
                                </div>
                            );
                        }
                    }
                    else {
                        return (
                            <div className="alert alert-danger" role="alert">
                                <strong>{appStatus.name}</strong> Laatste update: {lastModifiedDate.toLocaleString('nl-BE')}
                            </div>
                        );
                    }
                })
                }
            </div>
        );

    }
    return null;
}


export default Status;