import { useEffect, useMemo, useState } from 'react';
import RemindersService from '../Services/ReminderService';
import ReminderModel from '../Models/ReminderModel';
import { format, parseJSON } from 'date-fns';
import GraphService from '../Services/GraphService';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import Checkbox from '../Components/Checkbox';

const Reminders = () => {

    const { instance } = useMsal();

    const remindersService = useMemo(() => new RemindersService(instance), [instance]);
    const graphService = useMemo(() => new GraphService(instance), [instance]);

    const [reminders, setReminders] = useState<ReminderModel[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [showFuture, setShowFuture] = useState<boolean>(false);

    useEffect(() => {
        async function loadData() {
            try {
                const user = await graphService.getUserDetails();

                if (user.id !== undefined) {
                    const reminders = await remindersService.getOpen(showAll ? '' : user.id, showFuture);
                    setReminders(reminders);
                }
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [remindersService, graphService, showAll, showFuture]);

    async function setCompleted(reminder: ReminderModel) {
        try {
            reminder.completed = true;
            await remindersService.upsert(reminder);

            const user = await graphService.getUserDetails();

            if (user.id !== undefined) {
                const reminders = await remindersService.getOpen(showAll ? '' : user.id, showFuture);
                setReminders(reminders);
            }
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    return (
        <div className="container-fluid">
            <div className="card mb-3">
                <div className="card-body">
                    <form>
                        <div className=" row">
                            <div className="col-2 form-inline">
                                <Checkbox className="mr-4" label="Van iedereen" value="Alle" checked={showAll} onChange={() => setShowAll(!showAll)} />
                            </div>
                            <div className="col-2 form-inline">
                                <Checkbox className="mr-4" label="Ook toekomstige" value="Future" checked={showFuture} onChange={() => setShowFuture(!showFuture)} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Reminder</th>
                        <th>Project</th>
                        {showAll && <th>Voor</th>}
                        <th>Verwerken</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reminders.map((record, index) => {
                            return (
                                <tr key={index}>
                                    <td>{record.date === undefined || record.date === null ? null : format(parseJSON(record.date), "dd/MM/yyyy")}</td>
                                    <td>{record.description}</td>
                                    <td>{record.projectCode}</td>
                                    {showAll && <td>{record.personName}</td>}
                                    <td>
                                        <button type='button' className="btn btn-link p-0 text-success" onClick={() => setCompleted(record)}>
                                            <i className="fas fa-check"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

        </div>
    )

}
export default Reminders;