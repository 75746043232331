import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";

export default class AliveService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }
    public async isAlive() {

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(baseUrl + '/api/Alive', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken,
            })
        });
    } private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}