import React from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

interface SuccessToastProps {
    show: boolean;
    toggle: Function;
}

interface SuccessToastState {
    show: boolean;
}

export default class SuccessToast extends React.Component<SuccessToastProps, SuccessToastState> {
    constructor(props: SuccessToastProps) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            show: props.show
        };
    }

    static getDerivedStateFromProps(props: SuccessToastProps, state: SuccessToastState) {
        if (props.show !== state.show) {
            return {
                show: props.show
            };
        }

        return null;
    }

    componentDidUpdate() {
        if (this.state.show) {
            window.setTimeout(() => {
                this.props.toggle(false);
            }, 5000);
        }
    }

    toggle() {
        this.props.toggle(!this.state.show);
    }

    render() {
        return (
            <div aria-live="polite" aria-atomic="true" className="position-fixed m-3" style={{ zIndex: 9999, right: 0, bottom: 0, width: 350 }}>
                <Toast isOpen={this.state.show} className="bg-success">
                    <ToastHeader icon="success" toggle={this.toggle}>
                        SUCCES
                    </ToastHeader>
                    <ToastBody className="text-white">
                        Wijzigingen bewaard
                    </ToastBody>
                </Toast>
            </div>
        );
    }
}