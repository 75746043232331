import React, { useEffect, useMemo, useState } from 'react';
import ColorPickerModal from '../Components/ColorPickerModal';
import ProjectModel from '../Models/ProjectModel';
import ProjectsService from '../Services/ProjectsService';
import './ProjectColors.css';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

const ProjectColors = () => {

    const { instance } = useMsal();

    const projectsService = useMemo(() => new ProjectsService(instance), [instance]);

    const [projects, setProjects] = useState<ProjectModel[]>([]);
    const [filteredProjects, setFilteredProjects] = useState<ProjectModel[]>([]);
    const [projectsFilterText, setProjectsFilterText] = useState<string>("");
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
    const [selectedProjectCode, setSelectedProjectCode] = useState<string | null>(null);

    useEffect(() => {
        async function loadData() {
            try {
                const projects = (await projectsService.getProjectColors());
                setProjects(projects);
                setFilteredProjects(projects);
            }
            catch (error) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [projectsService]);

    function handleSearchProjects(event: React.ChangeEvent<HTMLInputElement>) {

        let foundProjects = projects;

        let searchTerm = event.target.value.toUpperCase();

        if (searchTerm.length > 0)
            foundProjects = foundProjects.filter(o => (o.code?.toUpperCase().indexOf(searchTerm) !== -1));

        setProjectsFilterText(event.target.value);
        setFilteredProjects(foundProjects);
    }

    async function colorPickerOnSave(color: string) {
        try {
            if (selectedProjectCode !== null) {
                let projectCode = selectedProjectCode;

                await projectsService.updateColor(projectCode, color);

                setSelectedProjectCode(null);
                setShowColorPicker(false);

                const projects = (await projectsService.getProjectColors());
                setProjects(projects);

                let searchTerm = projectsFilterText.toUpperCase();
                let foundProjects = projects;

                if (searchTerm.length > 0)
                    foundProjects = foundProjects.filter(o => (o.code?.toUpperCase().indexOf(searchTerm) !== -1));

                setFilteredProjects(foundProjects);

            }
        }
        catch (err) {
            toast.error('Fout bij het bewaren.');
        }
    }

    function colorPickerOnClose() {
        setShowColorPicker(false);

    }

    let filters =
        <div className="card mb-3">
            <div className="card-body">
                <form>
                    <div className=" row">
                        <label className="col-2 col-form-label">Project</label>
                        <div className="col-10 form-inline">
                            <input type="text" onChange={handleSearchProjects}
                                className="form-control" placeholder="Zoek project" value={projectsFilterText} />
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    let colorPicker = null;
    if (showColorPicker && selectedProjectCode !== null) {
        let color = projects.find(e => e.code === selectedProjectCode)?.backgroundColor;
        colorPicker =
            <ColorPickerModal
                color={color ?? ''}
                onSave={colorPickerOnSave}
                onClose={colorPickerOnClose} />
    }

    return (
        <div className="container-fluid">
            {filters}
            <table className="table table-sm text-nowrap">
                <thead>
                    <tr>
                        <th>Nummer</th>
                        <th>Kleur</th>
                        <th>Wijzig kleur</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredProjects.map((record) => {
                            let number = record.code.slice(-2);
                            let className = 'project-' + number;
                            return (
                                <tr key={record.code}>
                                    <td>{record.code}</td>
                                    <td className={className} style={{ backgroundColor: record.backgroundColor }}>
                                        <div className="fc-event-title" style={{ color: record.color }}>{record.code}</div>
                                    </td>
                                    <td>
                                        <button type='button' className="btn btn-link p-0" title="Kies kleur"
                                            onClick={() => { setShowColorPicker(true); setSelectedProjectCode(record.code); }}>
                                            <i className="fas fa-pen"></i>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            {colorPicker}
        </div>
    );


}

export default ProjectColors;