import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useMemo, useState } from 'react';
import {
    Jumbotron
} from 'reactstrap';
import GraphService from '../Services/GraphService';
import { User } from '@microsoft/microsoft-graph-types';

const HomeContent = () => {

    const [user, setUser] = useState<User>();

    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);

    useEffect(() => {
        async function getUser() {
            let user = await graphService.getUserDetails();
            setUser(user);
        }

        getUser();
    }, [graphService]);


    // If authenticated, greet the user
    if (
        isAuthenticated) {
        return (
            <h4>Welkom {user?.displayName}!</h4>
        );
    }

    return (
        <h4>Welkom, meld je aan om verder te gaan.</h4>
    );
}

const Home = () => {

    return (
        <div className="container">
            <Jumbotron>
                <HomeContent />
            </Jumbotron>
        </div>
    );

}

export default Home;